@import "https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap";
body[data-v-464ac711] {
  --red-color: #ff0000;
  --yellow-color: #ffff00;
  --green-color: #00ff00;
  --white-color: #fff;
  --site-color: #000;
  --green-bg: #145b12;
  --blue-color: #0000ff;
}
body[data-v-464ac711] {
  --bg-primary: #08c;
  --bg-primary65: #0088cca5;
  --bg-primary90: #0088cce6;
  --text-primary: #fff;
  --bg-secondary: #2c3e50;
  --bg-secondary70: #2c3e50b3;
  --bg-secondary85: #2c3e50d9;
  --bg-other: #2c3e50;
  --text-secondary: #fff;
  --bg-sidebar: #cccccc;
  --text-sidebar: #000000;
  --bg-body: #ffffff;
  --text-body: #000000;
  --input-border: #dbdbdb;
  --input-border-hover: #b5b5b5;
  --sidebar-menu-link-bg: #bbbbbb;
  --sidebar-menu-link-color: #000000;
  --sidebar-menu-link-border: #9e9e9e;
  --table-border: #dee2e6;
  --bg-table: #f7f7f7;
  --bg-table-row: #f2f2f2;
  --text-table: #333;
  --text-info: #097c93;
  --bg-info: #097c93;
  --text-danger: #bd1828;
  --bg-danger: #bd1828;
  --text-success: #086f3f;
  --bg-success: #086f3f;
  --bg-fancy: #fdcf13;
  --text-fancy: #fdcf13;
  --football: #03b37f;
  --football_75: #03b37fbf;
  --football_50: #03b37f80;
  --tennis: #f18521;
  --tennis_75: #f18521bf;
  --tennis_50: #f18521a3;
  --boxing: #e44e23;
  --boxing_75: #e44e23bf;
  --boxing_50: #e44e2380;
  --cricket: #20327b;
  --cricket_75: #20327bbf;
  --cricket_50: #20327b80;
  --golf: #015900;
  --golf_75: #015900bf;
  --golf_50: #01590080;
  --beach-volleyball: #ff9c63;
  --beach-volleyball_75: #ff9c63bf;
  --beach-volleyball_50: #ff9c6380;
  --table-tennis: #db2752;
  --table-tennis_75: #db2752bf;
  --table-tennis_50: #db275280;
  --futsal: #74c94b;
  --futsal_75: #74c94bbf;
  --futsal_50: #74c94b80;
  --horse-racing: #9e4025;
  --horse-racing_75: #9e4025bf;
  --horse-racing_50: #9e402580;
  --egames: #623cea;
  --egames_75: #623ceabf;
  --egames_50: #623cea80;
  --grey-hounds: #e89780;
  --grey-hounds_75: #e89780bf;
  --grey-hounds_50: #e8978080;
  --trotting: #996658;
  --trotting_75: #996658bf;
  --trotting_50: #99665880;
  --speedway: #c14347;
  --speedway_75: #c14347bf;
  --speedway_50: #c1434780;
  --basketball: #fbb03b;
  --basketball_75: #fbb03bbf;
  --basketball_50: #fbb03ba3;
  --moto-gp: #9fbe3c;
  --moto-gp_75: #9fbe3cbf;
  --moto-gp_50: #9fbe3c80;
  --chess: #0e345e;
  --chess_75: #0e345ebf;
  --chess_50: #0e345e80;
  --vollyball: #074a60;
  --vollyball_75: #074a60bf;
  --vollyball_50: #074a6080;
  --ice-hockey: #2f7ed5;
  --ice-hockey_75: #2f7ed5bf;
  --ice-hockey_50: #2f7ed580;
  --equine-sports: #e07a5f;
  --equine-sports_75: #e07a5fbf;
  --equine-sports_50: #e07a5f80;
  --australians-rules: #ff6457;
  --australians-rules_75: #ff6457bf;
  --australians-rules_50: #ff645780;
  --badminton: #3c7a57;
  --badminton_75: #3c7a57bf;
  --badminton_50: #3c7a5780;
  --formula-1: #bf6f7e;
  --formula-1_75: #bf6f7ebf;
  --formula-1_50: #bf6f7e80;
  --nascar: #cb2755;
  --nascar_75: #cb2755bf;
  --nascar_50: #cb275580;
  --hockey: #3d2b58;
  --hockey_75: #3d2b58bf;
  --hockey_50: #3d2b5880;
  --supercars: #772b59;
  --supercars_75: #772b59bf;
  --supercars_50: #772b5980;
  --netball: #279a95;
  --netball_75: #279a95bf;
  --netball_50: #279a9580;
  --surfing: #81a6ff;
  --surfing_75: #81a6ffbf;
  --surfing_50: #81a6ff80;
  --cycling: #60a5da;
  --cycling_75: #60a5dabf;
  --cycling_50: #60a5da80;
  --gaelick-sports: #07695f;
  --gaelick-sports_75: #07695fbf;
  --gaelick-sports_50: #07695f80;
  --biathlon: #2f158b;
  --biathlon_75: #2f158bbf;
  --biathlon_50: #2f158b80;
  --motorbikes: #bd6a58;
  --motorbikes_75: #bd6a58bf;
  --motorbikes_50: #bd6a5880;
  --athletics: #d96c0d;
  --athletics_75: #d96c0dbf;
  --athletics_50: #d96c0d80;
  --squash: #446ea6;
  --squash_75: #446ea6bf;
  --squash_50: #446ea680;
  --basketball-3-3: #e83d1a;
  --basketball-3-3_75: #e83d1abf;
  --basketball-3-3_50: #e83d1a80;
  --floorball: #04adbf;
  --floorball_75: #04adbfbf;
  --floorball_50: #04adbf80;
  --sumo: #d95b66;
  --sumo_75: #d95b66bf;
  --sumo_50: #d95b6680;
  --virtual-sports: #d592ff;
  --virtual-sports_75: #d592ffbf;
  --virtual-sports_50: #d592ff80;
  --handball: #69306d;
  --handball_75: #69306dbf;
  --handball_50: #69306d80;
  --politics: #b4845b;
  --politics_75: #b4845bbf;
  --politics_50: #b4845b80;
  --weather: #518cad;
  --weather_75: #518cadbf;
  --weather_50: #518cad80;
  --tv-games: #513896;
  --tv-games_75: #513896bf;
  --tv-games_50: #51389680;
  --lottery: #e9bc26;
  --lottery_75: #e9bc26bf;
  --lottery_50: #e9bc2680;
  --bowls: #590902;
  --bowls_75: #590902bf;
  --bowls_50: #59090280;
  --waterpolo: #21a3b8;
  --waterpolo_75: #21a3b8bf;
  --waterpolo_50: #21a3b880;
  --alpine-skiing: #509bff;
  --alpine-skiing_75: #509bffbf;
  --alpine-skiing_50: #509bff80;
  --sailing: #00b4d8;
  --sailing_75: #00b4d8bf;
  --sailing_50: #00b4d880;
  --hurling: #52b788;
  --hurling_75: #52b788bf;
  --hurling_50: #52b78880;
  --ski-jumping: #70c1b3;
  --ski-jumping_75: #70c1b3bf;
  --ski-jumping_50: #70c1b380;
  --bandy: #d99b84;
  --bandy_75: #d99b84bf;
  --bandy_50: #d99b8480;
  --motor-sports: #a175c8;
  --motor-sports_75: #a175c8bf;
  --motor-sports_50: #a175c880;
  --baseball: #c06c84;
  --baseball_75: #c06c84bf;
  --baseball_50: #c06c8480;
  --rugby-union: #8a101c;
  --rugby-union_75: #8a101cbf;
  --rugby-union_50: #8a101c80;
  --rugby-league: #ed5f62;
  --rugby-league_75: #ed5f62bf;
  --rugby-league_50: #ed5f6280;
  --curling: #5478bf;
  --curling_75: #5478bfbf;
  --curling_50: #5478bf80;
  --darts: #b21347;
  --darts_75: #b21347bf;
  --darts_50: #b2134780;
  --americal-footbal: #d3393d;
  --americal-footbal_75: #d3393dbf;
  --americal-footbal_50: #d3393d80;
  --snooker: #11b24b;
  --snooker_75: #11b24bbf;
  --snooker_50: #11b24b80;
  --lottery-specials: #a53860;
  --lottery-specials_75: #a53860bf;
  --lottery-specials_50: #a5386080;
  --special-bets: #e63946;
  --special-bets_75: #e63946bf;
  --special-bets_50: #e6394680;
  --lotto: #05668d;
  --lotto_75: #05668dbf;
  --lotto_50: #05668d80;
  --card-cricket: #581ab4;
  --card-cricket_75: #581ab4bf;
  --card-cricket_50: #581ab480;
  --kabaddi: #6e2e26;
  --kabaddi_75: #6e2e26bf;
  --kabaddi_50: #6e2e2680;
}
ul[data-v-464ac711],
ol[data-v-464ac711] {
  margin-bottom: 0;
  padding: 0;
}
ul li[data-v-464ac711],
ol li[data-v-464ac711] {
  list-style: none;
}
p[data-v-464ac711] {
  margin-bottom: 0;
  font-size: 16px;
}
small[data-v-464ac711] {
  font-size: 12px;
}
b[data-v-464ac711] {
  font-weight: 700;
}
h1[data-v-464ac711],
.h1[data-v-464ac711] {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0;
}
h2[data-v-464ac711],
.h2[data-v-464ac711] {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
h3[data-v-464ac711],
.h3[data-v-464ac711] {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}
h4[data-v-464ac711],
.h4[data-v-464ac711] {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}
h5[data-v-464ac711],
.h5[data-v-464ac711] {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
h6[data-v-464ac711],
.h6[data-v-464ac711] {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
a[data-v-464ac711] {
  text-decoration: none;
}
.no-border[data-v-464ac711] {
  border: 0 !important;
}
.pointer[data-v-464ac711] {
  cursor: pointer;
}
.t-underline[data-v-464ac711] {
  text-decoration: underline;
}
.container-fluid-5[data-v-464ac711] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.container-fluid-10[data-v-464ac711] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.row.row5[data-v-464ac711] {
  margin-left: -5px;
  margin-right: -5px;
}
.row.row5>[class*="col-"][data-v-464ac711],
.row.row5>[class*="col"][data-v-464ac711] {
  padding-left: 5px;
  padding-right: 5px;
}
.clock[data-v-464ac711] {
  font-size: x-large;
}
.row.row10[data-v-464ac711] {
  margin-left: -10px;
  margin-right: -10px;
}
.row.row10>[class*="col-"][data-v-464ac711],
.row.row10>[class*="col"][data-v-464ac711] {
  padding-left: 10px;
  padding-right: 10px;
}

/* @font-face {
    font-family: Card Characters;
    src: url(../fonts/card.ttf)
} */
@font-face {
  font-family: Casino;
}

/* @font-face {
    font-family: Numeric;
    src: url(src/assets/fonts/numeric.ttf)
} */

/* @font-face {
    font-family: casinonumfont;
    src: url(../fonts/casino3dmarquee-webfont.woff2) format("woff2"), url(../fonts/casino3dmarquee-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal
} */
.card-icon[data-v-464ac711] {
  font-family: Card Characters !important;
  display: inline-block;
}
.queen .casino-odd-box-container[data-v-464ac711] {
  width: calc(25% - 7.5px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.queen .casino-odd-box-container[data-v-464ac711]:last-child {
  margin-right: 0;
}
.queen .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.queen .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.queen .casino-nation-book[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.card-red[data-v-464ac711] {
  color: red !important;
}
.card-black[data-v-464ac711] {
  color: #000 !important;
}
.bg-success[data-v-464ac711] {
  background-color: var(--bg-success) !important;
}
.bg-danger[data-v-464ac711] {
  background-color: var(--bg-danger) !important;
}
.bg-info[data-v-464ac711] {
  background-color: var(--bg-info) !important;
}
.text-success[data-v-464ac711] {
  color: var(--text-success) !important;
}
.text-danger[data-v-464ac711] {
  color: var(--text-danger) !important;
}
.text-info[data-v-464ac711] {
  color: var(--text-info) !important;
}
.text-fancy[data-v-464ac711] {
  color: var(--text-fancy);
}
.sport1color[data-v-464ac711] {
  color: var(--football);
}
.sport2color[data-v-464ac711] {
  color: var(--tennis);
}
.sport3color[data-v-464ac711] {
  color: var(--boxing);
}
.sport4color[data-v-464ac711] {
  color: var(--cricket);
}
.sport5color[data-v-464ac711] {
  color: var(--golf);
}
.sport6color[data-v-464ac711] {
  color: var(--boxing);
}
.sport7color[data-v-464ac711] {
  color: var(--beach-volleyball);
}
.sport8color[data-v-464ac711] {
  color: var(--table-tennis);
}
.sport9color[data-v-464ac711] {
  color: var(--futsal);
}
.sport10color[data-v-464ac711] {
  color: var(--horse-racing);
}
.sport11color[data-v-464ac711] {
  color: var(--egames);
}
.sport12color[data-v-464ac711] {
  color: var(--grey-hounds);
}
.sport13color[data-v-464ac711] {
  color: var(--trotting);
}
.sport14color[data-v-464ac711] {
  color: var(--speedway);
}
.sport15color[data-v-464ac711] {
  color: var(--basketball);
}
.sport16color[data-v-464ac711] {
  color: var(--moto-gp);
}
.sport17color[data-v-464ac711] {
  color: var(--chess);
}
.sport18color[data-v-464ac711] {
  color: var(--vollyball);
}
.sport19color[data-v-464ac711] {
  color: var(--ice-hockey);
}
.sport20color[data-v-464ac711] {
  color: var(--equine-sports);
}
.sport21color[data-v-464ac711] {
  color: var(--australians-rules);
}
.sport22color[data-v-464ac711] {
  color: var(--badminton);
}
.sport23color[data-v-464ac711] {
  color: var(--formula-1);
}
.sport24color[data-v-464ac711] {
  color: var(--nascar);
}
.sport25color[data-v-464ac711] {
  color: var(--hockey);
}
.sport26color[data-v-464ac711] {
  color: var(--supercars);
}
.sport27color[data-v-464ac711] {
  color: var(--netball);
}
.sport28color[data-v-464ac711] {
  color: var(--surfing);
}
.sport29color[data-v-464ac711] {
  color: var(--cycling);
}
.sport30color[data-v-464ac711] {
  color: var(--gaelick-sports);
}
.sport31color[data-v-464ac711] {
  color: var(--biathlon);
}
.sport32color[data-v-464ac711] {
  color: var(--motorbikes);
}
.sport33color[data-v-464ac711] {
  color: var(--athletics);
}
.sport34color[data-v-464ac711] {
  color: var(--squash);
}
.sport35color[data-v-464ac711] {
  color: var(--basketball-3-3);
}
.sport36color[data-v-464ac711] {
  color: var(--floorball);
}
.sport37color[data-v-464ac711] {
  color: var(--sumo);
}
.sport38color[data-v-464ac711] {
  color: var(--virtual-sports);
}
.sport39color[data-v-464ac711] {
  color: var(--handball);
}
.sport40color[data-v-464ac711] {
  color: var(--politics);
}
.sport41color[data-v-464ac711] {
  color: var(--weather);
}
.sport42color[data-v-464ac711] {
  color: var(--tv-games);
}
.sport43color[data-v-464ac711] {
  color: var(--lottery);
}
.sport44color[data-v-464ac711] {
  color: var(--bowls);
}
.sport45color[data-v-464ac711] {
  color: var(--poker);
}
.sport46color[data-v-464ac711] {
  color: var(--waterpolo);
}
.sport47color[data-v-464ac711] {
  color: var(--alpine-skiing);
}
.sport48color[data-v-464ac711] {
  color: var(--sailing);
}
.sport49color[data-v-464ac711] {
  color: var(--hurling);
}
.sport50color[data-v-464ac711] {
  color: var(--ski-jumping);
}
.sport51color[data-v-464ac711] {
  color: var(--bandy);
}
.sport52color[data-v-464ac711] {
  color: var(--motor-sports);
}
.sport53color[data-v-464ac711] {
  color: var(--baseball);
}
.sport54color[data-v-464ac711] {
  color: var(--rugby-union);
}
.sport55color[data-v-464ac711] {
  color: var(--rugby-league);
}
.sport56color[data-v-464ac711] {
  color: var(--curling);
}
.sport57color[data-v-464ac711] {
  color: var(--darts);
}
.sport58color[data-v-464ac711] {
  color: var(--americal-footbal);
}
.sport59color[data-v-464ac711] {
  color: var(--snooker);
}
.sport60color[data-v-464ac711] {
  color: var(--gaelick-sports);
}
.sport61color[data-v-464ac711] {
  color: var(--lottery-specials);
}
.sport62color[data-v-464ac711] {
  color: var(--football);
}
.sport63color[data-v-464ac711] {
  color: var(--special-bets);
}
.sport64color[data-v-464ac711] {
  color: var(--egames);
}
.sport65color[data-v-464ac711] {
  color: var(--grey-hounds);
}
.sport999color[data-v-464ac711] {
  color: var(--card-cricket);
}
.sport1[data-v-464ac711],
.sport1[data-v-464ac711]:hover,
.sport1[data-v-464ac711]:focus,
.sport1[data-v-464ac711]:active {
  background-color: var(--football) !important;
}
.sport2[data-v-464ac711],
.sport2[data-v-464ac711]:hover,
.sport2[data-v-464ac711]:focus,
.sport2[data-v-464ac711]:active {
  background-color: var(--tennis) !important;
}
.sport3[data-v-464ac711],
.sport3[data-v-464ac711]:hover,
.sport3[data-v-464ac711]:focus,
.sport3[data-v-464ac711]:active {
  background-color: var(--boxing) !important;
}
.sport4[data-v-464ac711],
.sport4[data-v-464ac711]:hover,
.sport4[data-v-464ac711]:focus,
.sport4[data-v-464ac711]:active {
  background-color: var(--cricket) !important;
}
.sport5[data-v-464ac711],
.sport5[data-v-464ac711]:hover,
.sport5[data-v-464ac711]:focus,
.sport5[data-v-464ac711]:active {
  background-color: var(--golf) !important;
}
.sport6[data-v-464ac711],
.sport6[data-v-464ac711]:hover,
.sport6[data-v-464ac711]:focus,
.sport6[data-v-464ac711]:active {
  background-color: var(--boxing) !important;
}
.sport7[data-v-464ac711],
.sport7[data-v-464ac711]:hover,
.sport7[data-v-464ac711]:focus,
.sport7[data-v-464ac711]:active {
  background-color: var(--beach-volleyball) !important;
}
.sport8[data-v-464ac711],
.sport8[data-v-464ac711]:hover,
.sport8[data-v-464ac711]:focus,
.sport8[data-v-464ac711]:active {
  background-color: var(--table-tennis) !important;
}
.sport9[data-v-464ac711],
.sport9[data-v-464ac711]:hover,
.sport9[data-v-464ac711]:focus,
.sport9[data-v-464ac711]:active {
  background-color: var(--futsal) !important;
}
.sport10[data-v-464ac711],
.sport10[data-v-464ac711]:hover,
.sport10[data-v-464ac711]:focus,
.sport10[data-v-464ac711]:active {
  background-color: var(--horse-racing) !important;
}
.sport11[data-v-464ac711],
.sport11[data-v-464ac711]:hover,
.sport11[data-v-464ac711]:focus,
.sport11[data-v-464ac711]:active {
  background-color: var(--egames) !important;
}
.sport12[data-v-464ac711],
.sport12[data-v-464ac711]:hover,
.sport12[data-v-464ac711]:focus,
.sport12[data-v-464ac711]:active {
  background-color: var(--grey-hounds) !important;
}
.sport13[data-v-464ac711],
.sport13[data-v-464ac711]:hover,
.sport13[data-v-464ac711]:focus,
.sport13[data-v-464ac711]:active {
  background-color: var(--trotting) !important;
}
.sport14[data-v-464ac711],
.sport14[data-v-464ac711]:hover,
.sport14[data-v-464ac711]:focus,
.sport14[data-v-464ac711]:active {
  background-color: var(--speedway) !important;
}
.sport15[data-v-464ac711],
.sport15[data-v-464ac711]:hover,
.sport15[data-v-464ac711]:focus,
.sport15[data-v-464ac711]:active {
  background-color: var(--basketball) !important;
}
.sport16[data-v-464ac711],
.sport16[data-v-464ac711]:hover,
.sport16[data-v-464ac711]:focus,
.sport16[data-v-464ac711]:active {
  background-color: var(--moto-gp) !important;
}
.sport17[data-v-464ac711],
.sport17[data-v-464ac711]:hover,
.sport17[data-v-464ac711]:focus,
.sport17[data-v-464ac711]:active {
  background-color: var(--chess) !important;
}
.sport18[data-v-464ac711],
.sport18[data-v-464ac711]:hover,
.sport18[data-v-464ac711]:focus,
.sport18[data-v-464ac711]:active {
  background-color: var(--vollyball) !important;
}
.sport19[data-v-464ac711],
.sport19[data-v-464ac711]:hover,
.sport19[data-v-464ac711]:focus,
.sport19[data-v-464ac711]:active {
  background-color: var(--ice-hockey) !important;
}
.sport20[data-v-464ac711],
.sport20[data-v-464ac711]:hover,
.sport20[data-v-464ac711]:focus,
.sport20[data-v-464ac711]:active {
  background-color: var(--equine-sports) !important;
}
.sport21[data-v-464ac711],
.sport21[data-v-464ac711]:hover,
.sport21[data-v-464ac711]:focus,
.sport21[data-v-464ac711]:active {
  background-color: var(--australians-rules) !important;
}
.sport22[data-v-464ac711],
.sport22[data-v-464ac711]:hover,
.sport22[data-v-464ac711]:focus,
.sport22[data-v-464ac711]:active {
  background-color: var(--badminton) !important;
}
.sport23[data-v-464ac711],
.sport23[data-v-464ac711]:hover,
.sport23[data-v-464ac711]:focus,
.sport23[data-v-464ac711]:active {
  background-color: var(--formula-1) !important;
}
.sport24[data-v-464ac711],
.sport24[data-v-464ac711]:hover,
.sport24[data-v-464ac711]:focus,
.sport24[data-v-464ac711]:active {
  background-color: var(--nascar) !important;
}
.sport25[data-v-464ac711],
.sport25[data-v-464ac711]:hover,
.sport25[data-v-464ac711]:focus,
.sport25[data-v-464ac711]:active {
  background-color: var(--hockey) !important;
}
.sport26[data-v-464ac711],
.sport26[data-v-464ac711]:hover,
.sport26[data-v-464ac711]:focus,
.sport26[data-v-464ac711]:active {
  background-color: var(--supercars) !important;
}
.sport27[data-v-464ac711],
.sport27[data-v-464ac711]:hover,
.sport27[data-v-464ac711]:focus,
.sport27[data-v-464ac711]:active {
  background-color: var(--netball) !important;
}
.sport28[data-v-464ac711],
.sport28[data-v-464ac711]:hover,
.sport28[data-v-464ac711]:focus,
.sport28[data-v-464ac711]:active {
  background-color: var(--surfing) !important;
}
.sport29[data-v-464ac711],
.sport29[data-v-464ac711]:hover,
.sport29[data-v-464ac711]:focus,
.sport29[data-v-464ac711]:active {
  background-color: var(--cycling) !important;
}
.sport30[data-v-464ac711],
.sport30[data-v-464ac711]:hover,
.sport30[data-v-464ac711]:focus,
.sport30[data-v-464ac711]:active {
  background-color: var(--gaelick-sports) !important;
}
.sport31[data-v-464ac711],
.sport31[data-v-464ac711]:hover,
.sport31[data-v-464ac711]:focus,
.sport31[data-v-464ac711]:active {
  background-color: var(--biathlon) !important;
}
.sport32[data-v-464ac711],
.sport32[data-v-464ac711]:hover,
.sport32[data-v-464ac711]:focus,
.sport32[data-v-464ac711]:active {
  background-color: var(--motorbikes) !important;
}
.sport33[data-v-464ac711],
.sport33[data-v-464ac711]:hover,
.sport33[data-v-464ac711]:focus,
.sport33[data-v-464ac711]:active {
  background-color: var(--athletics) !important;
}
.sport34[data-v-464ac711],
.sport34[data-v-464ac711]:hover,
.sport34[data-v-464ac711]:focus,
.sport34[data-v-464ac711]:active {
  background-color: var(--squash) !important;
}
.sport35[data-v-464ac711],
.sport35[data-v-464ac711]:hover,
.sport35[data-v-464ac711]:focus,
.sport35[data-v-464ac711]:active {
  background-color: var(--basketball-3-3) !important;
}
.sport36[data-v-464ac711],
.sport36[data-v-464ac711]:hover,
.sport36[data-v-464ac711]:focus,
.sport36[data-v-464ac711]:active {
  background-color: var(--floorball) !important;
}
.sport37[data-v-464ac711],
.sport37[data-v-464ac711]:hover,
.sport37[data-v-464ac711]:focus,
.sport37[data-v-464ac711]:active {
  background-color: var(--sumo) !important;
}
.sport38[data-v-464ac711],
.sport38[data-v-464ac711]:hover,
.sport38[data-v-464ac711]:focus,
.sport38[data-v-464ac711]:active {
  background-color: var(--virtual-sports) !important;
}
.sport39[data-v-464ac711],
.sport39[data-v-464ac711]:hover,
.sport39[data-v-464ac711]:focus,
.sport39[data-v-464ac711]:active {
  background-color: var(--handball) !important;
}
.sport40[data-v-464ac711],
.sport40[data-v-464ac711]:hover,
.sport40[data-v-464ac711]:focus,
.sport40[data-v-464ac711]:active {
  background-color: var(--politics) !important;
}
.sport41[data-v-464ac711],
.sport41[data-v-464ac711]:hover,
.sport41[data-v-464ac711]:focus,
.sport41[data-v-464ac711]:active {
  background-color: var(--weather) !important;
}
.sport42[data-v-464ac711],
.sport42[data-v-464ac711]:hover,
.sport42[data-v-464ac711]:focus,
.sport42[data-v-464ac711]:active {
  background-color: var(--tv-games) !important;
}
.sport43[data-v-464ac711],
.sport43[data-v-464ac711]:hover,
.sport43[data-v-464ac711]:focus,
.sport43[data-v-464ac711]:active {
  background-color: var(--lottery) !important;
}
.sport44[data-v-464ac711],
.sport44[data-v-464ac711]:hover,
.sport44[data-v-464ac711]:focus,
.sport44[data-v-464ac711]:active {
  background-color: var(--bowls) !important;
}
.sport45[data-v-464ac711],
.sport45[data-v-464ac711]:hover,
.sport45[data-v-464ac711]:focus,
.sport45[data-v-464ac711]:active {
  background-color: var(--poker) !important;
}
.sport46[data-v-464ac711],
.sport46[data-v-464ac711]:hover,
.sport46[data-v-464ac711]:focus,
.sport46[data-v-464ac711]:active {
  background-color: var(--waterpolo) !important;
}
.sport47[data-v-464ac711],
.sport47[data-v-464ac711]:hover,
.sport47[data-v-464ac711]:focus,
.sport47[data-v-464ac711]:active {
  background-color: var(--alpine-skiing) !important;
}
.sport48[data-v-464ac711],
.sport48[data-v-464ac711]:hover,
.sport48[data-v-464ac711]:focus,
.sport48[data-v-464ac711]:active {
  background-color: var(--sailing) !important;
}
.sport49[data-v-464ac711],
.sport49[data-v-464ac711]:hover,
.sport49[data-v-464ac711]:focus,
.sport49[data-v-464ac711]:active {
  background-color: var(--hurling) !important;
}
.sport50[data-v-464ac711],
.sport50[data-v-464ac711]:hover,
.sport50[data-v-464ac711]:focus,
.sport50[data-v-464ac711]:active {
  background-color: var(--ski-jumping) !important;
}
.sport51[data-v-464ac711],
.sport51[data-v-464ac711]:hover,
.sport51[data-v-464ac711]:focus,
.sport51[data-v-464ac711]:active {
  background-color: var(--bandy) !important;
}
.sport52[data-v-464ac711],
.sport52[data-v-464ac711]:hover,
.sport52[data-v-464ac711]:focus,
.sport52[data-v-464ac711]:active {
  background-color: var(--motor-sports) !important;
}
.sport53[data-v-464ac711],
.sport53[data-v-464ac711]:hover,
.sport53[data-v-464ac711]:focus,
.sport53[data-v-464ac711]:active {
  background-color: var(--baseball) !important;
}
.sport54[data-v-464ac711],
.sport54[data-v-464ac711]:hover,
.sport54[data-v-464ac711]:focus,
.sport54[data-v-464ac711]:active {
  background-color: var(--rugby-union) !important;
}
.sport55[data-v-464ac711],
.sport55[data-v-464ac711]:hover,
.sport55[data-v-464ac711]:focus,
.sport55[data-v-464ac711]:active {
  background-color: var(--rugby-league) !important;
}
.sport56[data-v-464ac711],
.sport56[data-v-464ac711]:hover,
.sport56[data-v-464ac711]:focus,
.sport56[data-v-464ac711]:active {
  background-color: var(--curling) !important;
}
.sport57[data-v-464ac711],
.sport57[data-v-464ac711]:hover,
.sport57[data-v-464ac711]:focus,
.sport57[data-v-464ac711]:active {
  background-color: var(--darts) !important;
}
.sport58[data-v-464ac711],
.sport58[data-v-464ac711]:hover,
.sport58[data-v-464ac711]:focus,
.sport58[data-v-464ac711]:active {
  background-color: var(--americal-footbal) !important;
}
.sport59[data-v-464ac711],
.sport59[data-v-464ac711]:hover,
.sport59[data-v-464ac711]:focus,
.sport59[data-v-464ac711]:active {
  background-color: var(--snooker) !important;
}
.sport60[data-v-464ac711],
.sport60[data-v-464ac711]:hover,
.sport60[data-v-464ac711]:focus,
.sport60[data-v-464ac711]:active {
  background-color: var(--gaelick-sports) !important;
}
.sport61[data-v-464ac711],
.sport61[data-v-464ac711]:hover,
.sport61[data-v-464ac711]:focus,
.sport61[data-v-464ac711]:active {
  background-color: var(--lottery-specials) !important;
}
.sport62[data-v-464ac711],
.sport62[data-v-464ac711]:hover,
.sport62[data-v-464ac711]:focus,
.sport62[data-v-464ac711]:active {
  background-color: var(--football) !important;
}
.sport63[data-v-464ac711],
.sport63[data-v-464ac711]:hover,
.sport63[data-v-464ac711]:focus,
.sport63[data-v-464ac711]:active {
  background-color: var(--special-bets) !important;
}
.sport64[data-v-464ac711],
.sport64[data-v-464ac711]:hover,
.sport64[data-v-464ac711]:focus,
.sport64[data-v-464ac711]:active {
  background-color: var(--egames) !important;
}
.sport65[data-v-464ac711],
.sport65[data-v-464ac711]:hover,
.sport65[data-v-464ac711]:focus,
.sport65[data-v-464ac711]:active {
  background-color: var(--grey-hounds) !important;
}
.sport66[data-v-464ac711],
.sport66[data-v-464ac711]:hover,
.sport66[data-v-464ac711]:focus,
.sport66[data-v-464ac711]:active {
  background-color: var(--kabaddi) !important;
}
.sport999[data-v-464ac711],
.sport999[data-v-464ac711]:hover,
.sport999[data-v-464ac711]:focus,
.sport999[data-v-464ac711]:active {
  background-color: var(--card-cricket) !important;
}
.btn[data-v-464ac711] {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.btn[data-v-464ac711]:hover,
.btn[data-v-464ac711]:focus,
.btn[data-v-464ac711]:active {
  opacity: 0.85;
}
.btn-primary[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border-color: var(--bg-primary);
}
.btn-primary[data-v-464ac711]:hover,
.btn-primary[data-v-464ac711]:focus,
.btn-primary[data-v-464ac711]:active {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border-color: var(--bg-primary);
}
.btn-success[data-v-464ac711] {
  background-color: var(--bg-success);
  color: #fff;
  border-color: var(--bg-success);
}
.btn-danger[data-v-464ac711] {
  background-color: var(--bg-danger);
  color: #fff;
  border-color: var(--bg-danger);
}
.btn-info[data-v-464ac711] {
  background-color: var(--bg-info);
  color: #fff;
  border-color: var(--bg-info);
}
.btn-info[data-v-464ac711]:focus,
.btn-info[data-v-464ac711]:hover,
.btn-info[data-v-464ac711]:active {
  background-color: var(--bg-info);
  color: #fff;
  border-color: var(--bg-info);
}
.form-floating>label[data-v-464ac711] {
  z-index: 3;
}
.form-control[data-v-464ac711],
.form-select[data-v-464ac711] {
  background-color: var(--bg-body) !important;
  color: var(--text-body) !important;
  border-color: var(--input-border) !important;
  padding: 5px;
}
.form-control[data-v-464ac711]:focus,
.form-select[data-v-464ac711]:focus,
.form-control[data-v-464ac711]:hover,
.form-select[data-v-464ac711]:hover,
.form-control[data-v-464ac711]:active,
.form-select[data-v-464ac711]:active {
  box-shadow: none;
  border-color: var(--input-border-hover) !important;
}
.error-form[data-v-464ac711] {
  display: block;
  width: 100%;
  text-align: left;
  position: absolute;
  bottom: -20px;
  left: 5px;
  font-size: 12px;
  color: red;
  font-weight: 400;
}
.form-check-input[data-v-464ac711]:checked {
  background-color: var(--bg-secondary);
  border-color: var(--bg-secondary);
}
.custom-datepicker[data-v-464ac711] {
  position: relative;
}
.custom-datepicker i[data-v-464ac711] {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.react-datepicker__navigation-icon[data-v-464ac711]::before {
  height: 12px !important;
  top: 9px !important;
  width: 12px !important;
}
.dropdown-menu[data-v-464ac711] {
  background-color: var(--bg-body);
  z-index: 1001;
}
.dropdown.dropdown-menu.show[data-v-464ac711] {
  top: 100% !important;
}
.dropdown .dropdown-toggle[data-v-464ac711] {
  cursor: pointer;
  color: var(--text-primary);
}
.dropdown .dropdown-item[data-v-464ac711] {
  color: var(--text-body);
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  cursor: pointer;
}
.dropdown .dropdown-item[data-v-464ac711]:focus,
.dropdown .dropdown-item[data-v-464ac711]:hover {
  background-color: transparent;
  color: #666;
  text-decoration: underline;
}
.dropdown .dropdown-item a[data-v-464ac711] {
  color: var(--text-body);
}
.navbar[data-v-464ac711] {
  padding: 0;
}
.navbar-expand .navbar-nav .nav-link[data-v-464ac711] {
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 700;
}
.nav-pills .nav-link[data-v-464ac711] {
  background-color: var(--bg-sidebar);
  color: var(--text-sidebar);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-right: 1px solid var(--bg-secondary);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}
.nav-pills .nav-link span[data-v-464ac711] {
  line-height: 1;
}
.nav-pills .nav-item:last-child .nav-link[data-v-464ac711] {
  border-right: 0;
}
.nav-pills .nav-link.active[data-v-464ac711],
.nav-pills .show>.nav-link[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}
.nav-tabs .nav-item[data-v-464ac711] {
  padding: 10px 0;
  flex: auto;
}
.nav-tabs .nav-item:last-child .nav-link[data-v-464ac711] {
  border-right: 0;
}
.nav-tabs .nav-link[data-v-464ac711] {
  color: var(--text-primary);
  border: 0;
  border-right: 1px solid var(--text-primary);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 0 8px;
  font-weight: 700;
  text-transform: us;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.nav-tabs .nav-link.active[data-v-464ac711]::after {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: blue;
  top: -12px;
  content: "";
  left: 0;
}
.nav-tabs .nav-item.show .nav-link[data-v-464ac711],
.nav-tabs .nav-link.active[data-v-464ac711] {
  color: var(--text-primary);
  background-color: transparent;
}
.modal-dialog[data-v-464ac711] {
  margin: 1vh auto;
}
.modal-content[data-v-464ac711] {
  background-color: var(--bg-body);
  color: var(--text-body);
  border: 0;
  border-radius: 0;
}
.modal-body[data-v-464ac711] {
  max-height: calc(98vh - 50px);
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-header[data-v-464ac711] {
  padding: 10px;
  background: var(--bg-primary);
  color: var(--text-primary);
  border-radius: 0;
  border: 0;
}
.modal-title[data-v-464ac711] {
  font-weight: 700;
}
.modal-header .btn-close[data-v-464ac711] {
  filter: invert(1);
  -webkit-filter: invert(1);
  opacity: 1;
  box-shadow: none;
}
.table[data-v-464ac711] {
  background-color: var(--bg-table);
  color: var(--text-table);
  margin-bottom: 0;
}
tbody[data-v-464ac711],
td[data-v-464ac711],
tfoot[data-v-464ac711],
th[data-v-464ac711],
thead[data-v-464ac711],
tr[data-v-464ac711] {
  border-color: var(--table-border);
}
.table tbody tr[data-v-464ac711] {
  background-color: var(--bg-table-row);
}
.table tbody[data-v-464ac711] {
  border: 0 !important;
}
.table th[data-v-464ac711] {
  font-size: 14px;
}
.table td[data-v-464ac711] {
  font-size: 14px;
}
.table th[data-v-464ac711],
.table td[data-v-464ac711] {
  padding: 2px 8px;
  vertical-align: middle;
}
.table td[data-v-464ac711]:first-child,
.table th[data-v-464ac711]:first-child {
  padding-left: 10px;
}
.modal .table td[data-v-464ac711],
.modal .table td[data-v-464ac711],
.modal .table th[data-v-464ac711] {
  padding: 5px;
  font-size: 16px;
  white-space: inherit;
  vertical-align: middle;
}
.card[data-v-464ac711] {
  box-shadow: 0 0 5px #a4a4a4;
}
.card-body[data-v-464ac711] {
  padding: 10px;
}
.card-header[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}
.card-title[data-v-464ac711] {
  margin-bottom: 0;
}
.page-link[data-v-464ac711] {
  color: var(--text-body);
}
.page-item.active .page-link[data-v-464ac711] {
  background-color: var(--bg-secondary);
  border-color: var(--bg-secondary);
}
.page-details[data-v-464ac711] {
  color: var(--text-body);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 38px;
}
.custom-pagination[data-v-464ac711] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.custom-pagination>div[data-v-464ac711] {
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  position: relative;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 38px;
  line-height: 26px;
}
.custom-pagination>div[data-v-464ac711]:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.custom-pagination>div[data-v-464ac711]:nth-child(4n) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.custom-pagination>div[data-v-464ac711]:last-child {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-pagination>div[disabled][data-v-464ac711] {
  cursor: not-allowed;
}
.custom-pagination>div:last-child input[data-v-464ac711] {
  width: 100px;
}
.datepicker[data-v-464ac711] {
  padding: 20px;
}
.back[data-v-464ac711] {
  background-color: #72bbef !important;
}
.back1[data-v-464ac711] {
  background-color: rgba(114, 187, 239, 0.75) !important;
}
.back2[data-v-464ac711] {
  background-color: rgba(114, 187, 239, 0.5) !important;
}
.lay[data-v-464ac711] {
  background-color: #faa9ba !important;
}
.lay1[data-v-464ac711] {
  background-color: rgba(250, 169, 186, 0.75) !important;
}
.lay2[data-v-464ac711] {
  background-color: rgba(250, 169, 186, 0.5) !important;
}
.blink_me[data-v-464ac711] {
  animation: blinker-data-v-464ac711 1s linear infinite;
}
@keyframes blinker-data-v-464ac711 {
50% {
    opacity: 0;
}
}
.blink[data-v-464ac711] {
  animation: blinking-data-v-464ac711 300ms !important;
  -webkit-animation: blinking-data-v-464ac711 300ms !important;
  background-color: #ffc01a !important;
}
@keyframes blinking-data-v-464ac711 {
100% {
    background-color: #ffc01a !important;
}
}
.suspended-box[data-v-464ac711] {
  position: relative;
  pointer-events: none;
  cursor: none;
}
.suspended-box[data-v-464ac711]::before {
  background-image: url(./lock.png);
  background-size: 17px 17px;
  filter: invert(1);
  -webkit-filter: invert(1);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  pointer-events: none;
}
.suspended-box[data-v-464ac711]::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.suspended-row[data-v-464ac711] {
  position: relative;
}
.suspended-row[data-v-464ac711]::after {
  content: attr(data-title);
  background-color: #373636d6;
  color: red;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  position: absolute;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.suspended-table[data-v-464ac711] {
  position: relative;
}
.suspended-table[data-v-464ac711]::after {
  content: attr(data-title);
  background-color: #373636d6;
  color: red;
  font-weight: bolder;
  font-size: 16px;
  text-transform: uppercase;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none !important;
  font-family: Arial, Verdana, Helvetica, sans-serif;
}
#loader-full[data-v-464ac711] {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed !important;
  align-items: center;
  z-index: 9999;
  position: relative;
  background-image: linear-gradient(var(--bg-primary), var(--bg-secondary));
}
#loader-section[data-v-464ac711] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#load-inner[data-v-464ac711] {
  position: relative;
  text-align: center;
}
#load-inner img[data-v-464ac711] {
  display: block;
  height: 65px;
}
#load-inner i[data-v-464ac711] {
  font-size: 24px;
  color: var(--text-primary);
  margin-top: 40px;
}
#loader-section #load-inner i[data-v-464ac711] {
  color: #000;
}
.login-page[data-v-464ac711] {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(var(--bg-primary), var(--bg-other));
}
.login-box[data-v-464ac711] {
  width: 350px;
  max-width: 90%;
  text-align: center;
}
.login-page .logo-login[data-v-464ac711] {
  width: 350px;
  text-align: center;
  margin-top: 3%;
}
.login-page .logo-login img[data-v-464ac711] {
  max-height: 145px !important;
  max-width: 90%;
}
.login-form[data-v-464ac711] {
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 5px #fff;
  width: 315px;
  margin: 0 auto;
}
.login-form form[data-v-464ac711] {
  margin-top: 10px;
}
.login-form form .input-group>.form-control[data-v-464ac711],
.login-form form .input-group>.form-select[data-v-464ac711] {
  width: 100%;
  border-radius: 4px !important;
}
.login-form form span.icon-from[data-v-464ac711] {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 22;
}
.login-form form span.icon-from svg.icon[data-v-464ac711] {
  height: 16px;
  width: 16px;
  fill: #000;
}
.login-form .login-title[data-v-464ac711] {
  color: var(--bg-primary);
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form .login-title svg.icon[data-v-464ac711] {
  height: 22px;
  width: 18px;
  fill: var(--bg-primary);
  margin-left: 4px;
}
.login-form .input-group[data-v-464ac711] {
  margin-bottom: 20px;
}
.login-form .recaptchaTerms[data-v-464ac711] {
  width: 100%;
  text-align: left;
  display: block;
  line-height: 14px;
}
.login-form .btn-primary svg.icon[data-v-464ac711] {
  width: 16px;
  height: 16px;
  fill: var(--text-primary);
  position: relative;
  top: 4px;
}
.header-top[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  padding: 7px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 79px;
}
.header-top .logo-header img[data-v-464ac711] {
  max-height: 65px;
  max-width: 250px;
}
.header-top-menu[data-v-464ac711] {
  flex: 1 1;
  margin-left: 40px;
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #666 #333;
}
.header-top-menu[data-v-464ac711]::-webkit-scrollbar {
  height: 4px;
}
.header-top-menu[data-v-464ac711]::-webkit-scrollbar-track {
  background: #666;
}
.header-top-menu[data-v-464ac711]::-webkit-scrollbar-thumb {
  background-color: #333;
}
.header-top-menu .nav-item[data-v-464ac711] {
  margin: 0 1px;
}
.header-top-menu .nav-link[data-v-464ac711] {
  color: var(--text-secondary);
  background-color: var(--bg-secondary85);
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.header-top-menu .arrow-left[data-v-464ac711] {
  background-color: var(--bg-secondary70);
  color: var(--text-secondary);
  height: 31px;
  width: 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  margin-right: 1px;
}
.header-top-menu .arrow-right[data-v-464ac711] {
  background-color: var(--bg-secondary70);
  color: var(--text-secondary);
  height: 31px;
  width: 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  margin-left: 1px;
}
.header-top .dropdown .dropdown-item[data-v-464ac711] {
  font-size: 14px;
  padding: 4px 12px;
}
.header-top .user-balance[data-v-464ac711] {
  line-height: 15px;
}
.user-details[data-v-464ac711] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
}
.user-balance a[data-v-464ac711] {
  color: var(--text-primary);
}
.header-top .user-balance span svg.icon[data-v-464ac711] {
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
  fill: var(--text-primary);
}
.search-box-container[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.search-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.search-box input[data-v-464ac711] {
  width: 300px;
  padding: 0;
  border: 0;
  height: 38px;
  border: 0;
  border-radius: 0;
  box-shadow: 0 0 5px #000;
  transition: width 0.4s linear;
  padding: 0 10px;
}
.search-box.collapse_sreach input[data-v-464ac711] {
  width: 0;
  transition: width 0.4s linear;
  padding: 0;
}
.header_btn_menu[data-v-464ac711] {
  background-color: var(--bg-secondary);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
}
.header_btn_menu .btnhdr[data-v-464ac711] {
  display: inline-flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 7px;
  margin-top: 0;
}
.header_btn_menu .btnhdr.active[data-v-464ac711] {
  background-color: #bb1919;
}
.mobile-header-bottom .menu-tabs[data-v-464ac711] {
  background-color: var(--bg-primary);
  border-bottom: 0;
}
.mobile-header-bottom .sports-tab[data-v-464ac711] {
  background-color: var(--bg-secondary);
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
  margin-top: -1px;
}
.menu-tabs .nav-link[data-v-464ac711] {
  padding: 0 13px;
}
.mobile-header-bottom .sports-tab .nav-link img[data-v-464ac711] {
  display: block;
  text-align: center;
  height: 20px;
  margin: 0 auto;
  margin-bottom: 8px;
}
.mobile-header-bottom .sumneu-tabs[data-v-464ac711] {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: scroll;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
}
.dropdown .dropdown-toggle svg.icon[data-v-464ac711] {
  width: 16px;
  height: 16px;
  fill: var(--text-primary);
}
.search-box input.search-input-show[data-v-464ac711] {
  width: 270px;
  transition: width 0.4s linear;
  -webkit-transition: width 0.4s linear;
  -moz-transition: width 0.4s linear;
  -ms-transition: width 0.4s linear;
  -o-transition: width 0.4s linear;
  padding: 0.375rem 0.75rem;
  box-shadow: 0 0 5px #6f6f6f;
}
.search-box svg.icon[data-v-464ac711] {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: var(--text-primary);
  margin-left: 10px;
}
.search-box .search-list[data-v-464ac711] {
  position: absolute;
  top: 100%;
  min-width: 300px;
  background-color: var(--bg-table);
  color: var(--text-table);
  box-shadow: 0 0 5px var(--bg-table-row);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  z-index: 2;
  margin-top: 2px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.search-box .search-list a[data-v-464ac711] {
  color: var(--text-table);
  width: 100%;
}
.search-list-item[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.search-tournament-name[data-v-464ac711],
.search-game-name[data-v-464ac711],
.search-game-date[data-v-464ac711] {
  width: 100%;
}
.search-game-name[data-v-464ac711] {
  background: var(--bg-secondary85);
  color: var(--text-secondary);
  padding: 5px;
}
.header-rules a[data-v-464ac711] {
  color: var(--text-primary);
}
.news[data-v-464ac711] {
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 55%;
  font-size: 14px;
  font-style: italic;
}
.header-bottom[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 5px 15px;
}
.header-bottom .navbar-nav[data-v-464ac711] {
  flex-wrap: wrap;
  justify-content: center;
}
.header-bottom .navbar-expand .navbar-nav .nav-link[data-v-464ac711] {
  color: var(--text-secondary);
  text-transform: uppercase;
  transform: translateZ(0);
  position: relative;
}
.header-bottom .navbar-expand .navbar-nav .nav-item:first-child .nav-link[data-v-464ac711] {
  padding-left: 0;
}
.header-bottom .navbar-nav .nav-link[data-v-464ac711]:hover {
  text-decoration: none;
  background-color: transparent !important;
  color: inherit !important;
}
.header-bottom .navbar-nav .nav-link[data-v-464ac711]:hover::before,
.header-bottom .navbar-nav .nav-link[data-v-464ac711]:focus::before,
.header-bottom .navbar-nav .nav-link[data-v-464ac711]:active::before {
  left: 0;
  right: 0;
  height: 2px;
}
.header-bottom .navbar-nav .nav-link[data-v-464ac711]::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: var(--text-secondary);
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.footer[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  padding: 10px;
}
.main-container[data-v-464ac711] {
  min-height: calc(100vh - 169px);
  display: flex;
  flex-wrap: wrap;
  margin-top: 1px;
}
.left-sidebar[data-v-464ac711] {
  padding: 0;
  min-height: calc(100vh - 175px);
  width: 280px;
}
.sidebar-box[data-v-464ac711] {
  margin-bottom: 5px;
  position: relative;
}
.sidebar-title[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  position: relative;
  height: 30px;
}
.sidebar-title h4[data-v-464ac711] {
  padding: 5px;
  font-size: 16px;
}
.sidebar-title button[data-v-464ac711] {
  background-color: transparent !important;
  border: none;
  padding: 0;
  box-shadow: none !important;
  color: var(--text-primary) !important;
  padding: 5px;
  font-size: 18px;
}
.my-bets tr[data-v-464ac711] {
  background-color: #ccc;
}
.my-bets tr th[data-v-464ac711] {
  font-size: 12px;
  color: #303030;
}
.left-sidebar .sidebar-title button.accordion-button[data-v-464ac711]:not(.collapsed) {
  color: var(--text-primary) !important;
}
.left-sidebar .sidebar-title button[data-v-464ac711]:hover,
.left-sidebar .sidebar-title button[data-v-464ac711]:focus,
.left-sidebar .sidebar-title button[data-v-464ac711]:active {
  box-shadow: none !important;
}
.left-sidebar .accordion-button[data-v-464ac711]::after {
  font-family: "font awesome 5 free" !important;
  content: "\f107" !important;
  padding-right: 3px !important;
  font-weight: 700 !important;
  float: right !important;
  color: var(--text-primary) !important;
  background-image: none !important;
  display: none;
}
.left-sidebar .accordion-button[data-v-464ac711]:not(.collapsed)::after {
  font-family: "font awesome 5 free" !important;
  content: "\f106" !important;
  padding-right: 3px !important;
  font-weight: 700 !important;
  float: right !important;
  color: var(--text-primary) !important;
  background-image: none !important;
  transform: none;
  transform: initial;
  -webkit-transform: unset;
  -moz-transform: unset;
  -ms-transform: unset;
  -o-transform: unset;
  display: none;
}
.accordion-item:first-of-type .accordion-button svg.icon[data-v-464ac711] {
  width: 18px;
  height: 18px;
  fill: var(--text-primary);
  position: absolute;
  right: 5px;
}
.accordion-item:first-of-type .accordion-button[aria-expanded="true"] svg.arrowdown[data-v-464ac711] {
  display: none;
}
.accordion-item:first-of-type .accordion-button[aria-expanded="false"] svg.arrowup[data-v-464ac711] {
  display: none;
}
.left-sidebar .accordion-body[data-v-464ac711] {
  padding: 0;
}
.left-sidebar .accordion-item[data-v-464ac711] {
  border: 0;
  background-color: transparent;
}
.racing-sport .dropend .dropdown-toggle[data-v-464ac711]::after {
  display: none;
}
.racing-sport .dropdown-menu[data-v-464ac711] {
  top: 0 !important;
  left: auto !important;
  left: initial !important;
  right: 0 !important;
  transform: translateX(100%) !important;
}
.racing-sport .dropdown-menu h5[data-v-464ac711] {
  padding: 5px 20px;
  border-bottom: 1px solid var(--text-body);
  font-weight: 700;
  font-size: 20px;
}
.racing-sport .horse-list-box[data-v-464ac711] {
  max-height: calc(100vh - 210px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-sidebar) var(--bg-table-row);
}
.racing-sport .horse-list-box[data-v-464ac711]::-webkit-scrollbar {
  width: 8px;
}
.racing-sport .horse-list-box[data-v-464ac711]::-webkit-scrollbar-track {
  background: var(--bg-table-row);
}
.racing-sport .horse-list-box[data-v-464ac711]::-webkit-scrollbar-thumb {
  background-color: var(--bg-sidebar);
}
.sidebar .nav-item .nav-link[data-v-464ac711] {
  background: var(--sidebar-menu-link-bg);
  color: var(--sidebar-menu-link-color);
  border-bottom: 1px solid var(--sidebar-menu-link-border);
  font-weight: 400;
  font-size: 14px;
  padding: 2px 15px;
}
.sidebar .nav-item .nav-link span[data-v-464ac711] {
  white-space: normal;
}
.menu-box .dropdown-menu[data-v-464ac711] {
  position: relative !important;
  transform: none !important;
  transform: initial !important;
  background-color: transparent;
  border: 0;
  z-index: 10;
}
.menu-box .dropdown-menu.show[data-v-464ac711] {
  transform-origin: top center;
  padding: 0;
}
.dropdown-toggle[data-v-464ac711]::after {
  display: none;
}
@keyframes growDown-data-v-464ac711 {
0% {
    transform: scaleY(0);
}
80% {
    transform: scaleY(0.8);
}
100% {
    transform: scaleY(1);
}
}
.menu-box .nav-item .nav-link.dropdown-toggle[data-v-464ac711]::after {
  display: none;
}
.menu-box .dropdown-menu .nav-item .nav-link[data-v-464ac711] {
  padding-left: 25px;
}
.menu-box .dropdown-menu .dropdown-menu .nav-item .nav-link[data-v-464ac711] {
  padding-left: 35px;
}
.latest-event[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
  background-color: transparent;
  padding: 0;
  align-items: flex-start;
}
.latest-events-container[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.latest-event .arrow-left+.latest-events-container[data-v-464ac711] {
  width: calc(100% - 64px);
}
.latest-event .arrow-left[data-v-464ac711] {
  background-color: var(--bg-secondary70);
  color: var(--text-secondary);
  height: 31px;
  width: 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
}
.latest-event .arrow-right[data-v-464ac711] {
  background-color: var(--bg-secondary70);
  color: var(--text-secondary);
  height: 32px;
  width: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
}
.latest-event-item[data-v-464ac711] {
  flex: 1 1;
  max-width: 100%;
  min-width: calc(20% - 10px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: var(--bg-secondary);
  margin-right: 5px;
  border-radius: 4px;
  color: var(--text-secondary);
  margin-bottom: 5px;
}
.latest-event-item[data-v-464ac711]:last-child {
  margin-bottom: 0;
}
.latest-event-item a[data-v-464ac711] {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.latest-event-item a span[data-v-464ac711] {
  margin-left: 5px;
}
.center-main-container[data-v-464ac711] {
  width: calc(100% - 280px);
  padding: 0 6px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}
.center-main-container.error-404[data-v-464ac711] {
  height: calc(100vh - 159px);
}
.home-page .center-container[data-v-464ac711] {
  width: 100%;
}
.home-page .casino-list-item[data-v-464ac711] {
  width: calc(10% - 10px);
}
.center-container .nav.sports-tab[data-v-464ac711],
.center-container .nav.casino-tab[data-v-464ac711],
.center-container .nav.casino-sub-tab[data-v-464ac711] {
  overflow-x: hidden;
  overflow-y: hidden;
  flex-wrap: nowrap;
  background-color: var(--bg-secondary);
}
.home-page .center-container .nav.sports-tab[data-v-464ac711] {
  background-color: transparent;
  box-shadow: none;
}
.center-container .nav.sports-tab .nav-item[data-v-464ac711],
.center-container .nav.casino-tab .nav-item[data-v-464ac711],
.center-container .nav.casino-sub-tab .nav-item[data-v-464ac711] {
  flex: 0 0 auto;
}
.home-page .tab-content #loader-section[data-v-464ac711] {
  min-height: calc(100vh - 206px);
}
.bet-table[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  align-content: flex-start;
}
.bet-table-header[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 2px solid #dee2e6;
  border-top: 0;
  padding: 5px 0;
  padding-top: 0;
}
.bet-table-body[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.bet-table-row[data-v-464ac711] {
  width: 100%;
  border-bottom: 1px solid var(--table-border);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
}
.bet-nation-name[data-v-464ac711] {
  width: 64%;
  padding: 0 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.home-page .bet-nation-name[data-v-464ac711] {
  padding-left: 12px;
}
.bet-nation-name .bet-nation-game-name[data-v-464ac711] {
  max-width: calc(100% - 210px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-table);
}
.bet-nation-name .game-icons[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 210px;
}
.bet-nation-name .game-icons .game-icon[data-v-464ac711] {
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bet-nation-name .game-icons .game-icon.tvicon_status[data-v-464ac711] {
  /*     background-image: url(../images/icons/game-icon-d.jpg); */
  background-position: right -154px bottom 0;
  background-repeat: no-repeat;
  height: 18px;
}
.bet-nation-name .game-icons .game-icon.fancyicon_status[data-v-464ac711] {
  /* background-image: url(../images/icons/game-icon-d.jpg); */
  background-position: right -70px bottom 3px;
  background-repeat: no-repeat;
  height: 22px;
  background-size: 128px;
}
.bet-nation-name .game-icons .game-icon.BMicon_status[data-v-464ac711] {
  /*     background-image: url(../images/icons/game-icon-d.jpg); */
  background-position: right -31px bottom 4px;
  background-repeat: no-repeat;
  height: 22px;
  background-size: 110px;
}
.bet-nation-name .game-icons .game-icon.gameicon_status[data-v-464ac711] {
  /*  background-image: url(../images/icons/game-icon-d.jpg); */
  background-position: right 5px bottom 3px;
  background-repeat: no-repeat;
  height: 22px;
  background-size: 124px;
}
.bet-nation-name .game-icons .game-icon.tvicon_status-mob[data-v-464ac711] {
  /*   background-image: url(../images/icons/game-icon-m.jpg); */
  background-position: right -102px bottom 2px;
  background-repeat: no-repeat;
  height: 18px;
  background-size: 120px;
}
.bet-nation-name .game-icons .game-icon.fancyicon_status-mob[data-v-464ac711] {
  /*  background-image: url(../images/icons/game-icon-m.jpg); */
  background-position: right -58px bottom 4px;
  background-repeat: no-repeat;
  height: 18px;
  background-size: 93px;
}
.bet-nation-name .game-icons .game-icon.BMicon_status-mob[data-v-464ac711] {
  /*  background-image: url(../images/icons/game-icon-m.jpg); */
  background-position: right -17px bottom 7px;
  background-repeat: no-repeat;
  height: 18px;
  background-size: 62px;
}
.bet-nation-name .game-icons .game-icon.gameicon_status-mob[data-v-464ac711] {
  /* background-image: url(../images/icons/game-icon-m.jpg); */
  background-position: right 0 bottom 3px;
  background-repeat: no-repeat;
  height: 18px;
  background-size: 88px;
}
.logo-header a svg.icon[data-v-464ac711] {
  width: 21px;
  height: 21px;
  fill: var(--text-primary);
}
.bet-nation-name .game-icon .fa-tv[data-v-464ac711] {
  font-size: 11px;
}
.bet-nation-name .game-icons .game-icon .active[data-v-464ac711] {
  background-color: #28a745;
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.bet-nation-name .game-icons .game-icon img[data-v-464ac711] {
  height: 12px;
}
.bet-nation-name .game-icons .game-icon img.icon_game_s[data-v-464ac711] {
  height: 20px;
}
.bet-nation-odd[data-v-464ac711] {
  width: 12%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
}
.bet-table-header .bet-nation-odd[data-v-464ac711] {
  font-size: 12px;
  color: #303030;
}
.bet-table-header .bet-nation-name[data-v-464ac711] {
  font-size: 12px;
  color: #303030;
}
.bet-nation-odd .odd-box[data-v-464ac711] {
  width: 50%;
  padding: 2px 0;
}
.bet-nation-odd .odd-box .bet-odd[data-v-464ac711] {
  font-size: 14px;
  color: #273a47;
}
.rules-modal .rules-left-sidebar[data-v-464ac711] {
  background-color: var(--bg-sidebar);
  width: 20%;
  color: var(--text-sidebar);
  min-height: calc(98vh - 70px);
  overflow-x: hidden;
  overflow-y: auto;
}
.rules-modal .rules-left-sidebar .nav .nav-item[data-v-464ac711] {
  width: 100%;
}
.rules-modal .rules-left-sidebar .nav .nav-item a[data-v-464ac711] {
  color: var(--text-sidebar);
  border-bottom: 1px solid var(--text-sidebar);
  border-right: 0;
}
.rules-modal .rules-left-sidebar .nav .nav-item a.active[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}
.rules-content[data-v-464ac711] {
  width: 80%;
  padding: 0 5px;
}
.rules-content-title[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 700;
}
.rules-content-desc[data-v-464ac711] {
  padding: 10px;
}
.rules-langualge[data-v-464ac711] {
  width: 100%;
  margin-right: 20px;
  flex: 1 1;
  text-align: right;
}
.rules-langualge img[data-v-464ac711] {
  height: 20px;
  margin-right: 5px;
}
.rules-langualge .dropdown-toggle[data-v-464ac711] {
  background: var(--bg-sidebar);
  color: var(--text-sidebar);
}
.rules-langualge .dropdown-toggle[data-v-464ac711]::after {
  display: inline-block;
}
.modal.rules-modal .table td[data-v-464ac711],
.modal.rules-modal .table th[data-v-464ac711] {
  white-space: normal;
}
.rules-modal .modal-body[data-v-464ac711] {
  max-height: calc(98vh - 120px);
  display: flex;
  flex-wrap: wrap;
}
.rules-modal .modal-body img[data-v-464ac711] {
  max-height: calc(100vh - 120px);
  max-width: 100%;
  height: auto;
}
.rules-modal .modal-dialog[data-v-464ac711] {
  max-width: 420px;
}
.center-main-container.detail-page[data-v-464ac711] {
  padding: 0 5px 0 1px;
}
.detail-page .center-container[data-v-464ac711] {
  width: calc(100% - 487px);
  padding-bottom: 10px;
}
.detail-page-container[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.detail-page-container #loader-section[data-v-464ac711] {
  min-height: calc(100vh - 169px);
}
.desktop-inplay-design[data-v-464ac711] {
  width: 100%;
}
.scorecard[data-v-464ac711] {
  width: calc(100% - 8px);
  padding: 5px;
  /*   background-image: url(../images/scorecard-bg.jpg); */
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  z-index: 1;
  margin-left: 4px;
  margin-right: 4px;
}
.scorecard[data-v-464ac711]::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.55);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.ball-runs[data-v-464ac711] {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: var(--font-small);
  background-color: #08c;
  color: #fff;
  text-align: center;
  margin-right: 4px;
}
.ball-runs.four[data-v-464ac711] {
  background-color: #087f23;
}
.ball-runs.six[data-v-464ac711] {
  background-color: #883997;
}
.ball-runs.wicket[data-v-464ac711] {
  background-color: red;
}
.scorestats[data-v-464ac711] {
  height: 250px;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  width: calc(100% - 8px);
}
.scorestats iframe[data-v-464ac711] {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.game-header[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
}
.game-market[data-v-464ac711] {
  background: var(--bg-table);
  color: var(--text-table);
  margin-top: 8px;
}
.game-market a.rules_btn[data-v-464ac711] {
  font-size: 16px;
  color: #fff;
  margin-right: 4px;
}
.market-title[data-v-464ac711] {
  background-color: var(--bg-secondary85);
  color: var(--text-secondary);
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-transform: uppercase;
}
.market-title span[data-v-464ac711] {
  display: inline-block;
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.market-rules[data-v-464ac711] {
  cursor: pointer;
}
.market-header[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
}
.market-header[data-v-464ac711],
.market-row[data-v-464ac711] {
  border-bottom: 1px solid #fff;
}
.market-row[data-v-464ac711] {
  background-color: var(--bg-table-row);
  display: flex;
  flex-wrap: wrap;
}
.fancy-market[data-v-464ac711] {
  border-bottom: 1px solid var(--table-border);
}
.fancy-market .market-row[data-v-464ac711] {
  border-bottom: 0;
}
.market-nation-detail[data-v-464ac711] {
  width: calc(100% - 480px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 5px;
  font-size: 14px;
}
.market-nation-detail .market-nation-name[data-v-464ac711] {
  font-weight: 400;
  max-width: 100%;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
.market-nation-detail .market-nation-name a[data-v-464ac711] {
  color: var(--text-table);
}
.market-header .market-nation-detail .market-nation-name[data-v-464ac711] {
  font-size: 12px;
  color: #000;
  font-weight: 700;
}
.market-odd-box[data-v-464ac711] {
  width: 80px;
  padding: 2px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid #fff;
  cursor: pointer;
  min-height: 41px;
}
.Market-odd-row-box[data-v-464ac711] {
  display: flex;
}
.market-header .market-odd-box[data-v-464ac711] {
  min-height: 0;
  min-height: initial;
}
.fancy-min-max-box[data-v-464ac711] {
  width: 80px;
  padding: 0 5px;
  text-align: right;
}
.market-nation-book[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.market-nation-book .market-book[data-v-464ac711] {
  font-size: 14px;
  font-weight: 700;
}
.market-nation-book .market-live-book[data-v-464ac711] {
  font-size: 12px;
  font-weight: 400;
  flex: 1 1;
  text-align: right;
}
.market-odd-box .market-odd[data-v-464ac711] {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3px;
  line-height: 11px;
}
.market-odd-box .market-volume[data-v-464ac711] {
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
}
.market-remark[data-v-464ac711] {
  padding: 0 5px;
  color: #8b0000;
  font-weight: 700;
  font-size: 12px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
.fancy-min-max[data-v-464ac711] {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-info);
  word-break: break-all;
}
.market-1[data-v-464ac711] {
  min-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
}
.market-1 .market-row .market-1-item[data-v-464ac711] {
  padding: 2px 0 2px 16px;
  display: flex;
  display: -webkit-flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  min-width: 33.33%;
  max-width: 50%;
  margin-bottom: 5px;
}
.market-2[data-v-464ac711] {
  min-width: calc(33.33% - 8px);
  max-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
  width: calc(50% - 8px);
}
.market-2 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 160px);
}
.market-2 .suspended-row[data-v-464ac711]::after,
.market-2 .suspended-table[data-v-464ac711]::after {
  width: 160px;
}
.market-2.width30[data-v-464ac711] {
  min-width: calc(30% - 8px);
  max-width: calc(100% - 8px);
}
.market-2.width30 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 160px);
}
.market-2.width30 .suspended-row[data-v-464ac711]::after,
.market-2.width30 .suspended-table[data-v-464ac711]::after {
  width: 160px;
}
.market-2.width30 .market-nation-detail .market-nation-name[data-v-464ac711] {
  font-weight: 700;
}
.market-3[data-v-464ac711] {
  margin-left: 4px;
  margin-right: 4px;
  min-width: calc(50% - 8px);
  max-width: calc(100% - 8px);
  flex: 1 1;
}
.market-3 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 320px);
}
.market-3 .market-nation-detail .market-nation-name[data-v-464ac711] {
  font-weight: 700;
}
.market-3 .suspended-row[data-v-464ac711]::after,
.market-3 .suspended-table[data-v-464ac711]::after {
  width: 320px;
}
.market-4[data-v-464ac711] {
  min-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
  max-width: calc(100% - 8px);
}
.market-4 .market-nation-detail .market-nation-name[data-v-464ac711] {
  font-weight: 700;
}
.market-4.width70[data-v-464ac711] {
  min-width: calc(70% - 8px);
  max-width: calc(100% - 8px);
}
.bookmaker4 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 160px);
}
.market-4 .suspended-row[data-v-464ac711]:after,
.market-4 .suspended-table[data-v-464ac711]:after {
  width: 100%;
}
.market-5[data-v-464ac711] {
  margin-left: 4px;
  margin-right: 4px;
  min-width: calc(100% - 8px);
  flex: 1 1;
}
.market-5 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 320px);
}
.market-5 .suspended-row[data-v-464ac711]::after,
.market-5 .suspended-table[data-v-464ac711]::after {
  width: 160px;
  right: 160px;
}
.market-6[data-v-464ac711] {
  min-width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
}
.market-6 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 240px);
}
.market-6 .suspended-row[data-v-464ac711]::after,
.market-6 .suspended-table[data-v-464ac711]::after {
  width: 240px;
}
.market-6 .market-nation-detail .market-nation-name[data-v-464ac711] {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: calc(100% - 50px);
}
.market-9[data-v-464ac711] {
  min-width: calc(50% - 8px);
  max-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
}
.market-9 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 80px);
}
.market-9 .suspended-row[data-v-464ac711]::after,
.market-9 .suspended-table[data-v-464ac711]::after {
  width: 80px;
}
.market-9 .suspended-box[data-v-464ac711]::after {
  width: 80px;
  left: auto;
  left: initial;
  right: 0;
}
.market-9 .suspended-box[data-v-464ac711]::before {
  width: 80px;
  left: auto;
  left: initial;
  right: 0;
}
.market-10[data-v-464ac711] {
  min-width: calc(50% - 8px);
  max-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
}
.market-10 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 160px);
}
.market-10 .suspended-row[data-v-464ac711]::after,
.market-10 .suspended-table[data-v-464ac711]::after {
  width: 160px;
}
.market-11[data-v-464ac711] {
  min-width: calc(50% - 8px);
  max-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
  width: calc(50% - 8px);
}
.market-11 .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 160px);
}
.market-11 .suspended-row[data-v-464ac711]::after,
.market-11 .suspended-table[data-v-464ac711]::after {
  width: 160px;
}
.market-12[data-v-464ac711] {
  min-width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  flex: 1 1;
  max-width: calc(100% - 8px);
}
.market-12 .market-nation-detail .form-check[data-v-464ac711] {
  width: 100%;
}
.market-12 .market-nation-detail .form-check label[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.market-12 .market-nation-detail .form-check label>div[data-v-464ac711]:nth-child(2) {
  width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
}
.market-12 .jockey-detail[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
}
.market-12 .jockey-detail .jockey-detail-box[data-v-464ac711] {
  background-color: var(--bg-sidebar);
  color: var(--text-sidebar);
  padding: 0 3px;
  margin-right: 5px;
  font-size: 13px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.market-12 .market-nation-detail label img[data-v-464ac711] {
  height: 25px;
  margin-right: 5px;
}
.market-12 .market-nation-detail .market-nation-name[data-v-464ac711] {
  font-weight: 700;
  width: calc(100% - 50px);
}
.market-12 .suspended-row[data-v-464ac711]::after,
.market-12 .suspended-table[data-v-464ac711]::after {
  width: 480px;
}
.market-12 .suspended-row.removed[data-v-464ac711]::after {
  color: #fff;
}
.market-12 .market-odd-box[data-v-464ac711] {
  min-height: 48px;
}
.market-12 .market-book[data-v-464ac711] {
  width: 50px;
  text-align: right;
}
.market-12 .market-nation-detail label[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.market-12 .market-header .market-odd-box[data-v-464ac711] {
  min-height: 0;
  min-height: initial;
}
.horse-banner[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 0;
  position: relative;
}
.horse-banner img[data-v-464ac711] {
  height: 115px;
  background-position: center;
  background-size: cover;
  width: 100%;
}
.horse-banner-detail[data-v-464ac711] {
  position: absolute;
  max-width: 95%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  width: 100%;
  color: #fff;
}
.horse-banner .horse-banner-detail div[data-v-464ac711]:first-child {
  position: absolute;
  left: 0;
  font-size: 20px;
  text-transform: uppercase;
  color: #00b900 !important;
}
.right-sidebar[data-v-464ac711] {
  width: 485px;
  padding: 0;
  min-height: calc(100vh - 135px);
  max-height: calc(100vh - 135px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 2px;
  position: sticky;
  top: 0;
}
.right-sidebar.sticky[data-v-464ac711] {
  position: fixed;
  top: 0;
  right: 5px;
  width: 450px;
  max-height: calc(100vh - 50px);
}
.right-sidebar .sidebar-title[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 0;
}
.right-sidebar .sidebar-title span[data-v-464ac711] {
  font-size: 14px;
}
.right-sidebar .sidebar-title .tv-desktop svg.icon[data-v-464ac711] {
  width: 18px;
  height: 18px;
  display: inline;
  fill: var(--text-primary);
}
.right-sidebar.sidebar .place-bet-container .sidebar-title[data-v-464ac711] ::before,
.right-sidebar.sidebar .my-bet-container .sidebar-title[data-v-464ac711] ::before {
  display: none;
}
.live-tv[data-v-464ac711] {
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  padding-bottom: 56.25%;
}
.live-tv iframe[data-v-464ac711] {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.place-bet-box-header[data-v-464ac711] {
  background-color: var(--bg-sidebar);
  color: #303030;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4px 5px;
  font-size: 12px;
  font-weight: 600;
}
.place-bet-box-body[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 5px;
  font-size: 14px;
  border-bottom: 1px solid var(--table-border);
}
.place-bet-for[data-v-464ac711] {
  width: 35%;
  display: flex;
  align-items: center;
  line-height: normal;
  padding-left: 6px;
}
.lottery-sidebar .place-bet-for[data-v-464ac711] {
  width: calc(100% - 90px);
}
.place-bet-for span[data-v-464ac711] {
  max-width: 100%;
  font-weight: 700;
}
.place-bet-odds[data-v-464ac711] {
  width: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.place-bet-odds input[data-v-464ac711] {
  width: calc(100% - 20px);
}
.place-bet-stake[data-v-464ac711] {
  width: 70px;
  margin-left: 0;
}
.place-bet-stake input[data-v-464ac711] {
  width: 70px;
}
.place-bet-profit[data-v-464ac711] {
  width: 15%;
  text-align: right;
  font-weight: 700;
}
.place-bet-box-body i[data-v-464ac711] {
  font-size: 12px;
}
.place-bet-box-body input[data-v-464ac711] {
  height: 24px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  font-size: 12px;
  padding: 5px;
  border: 0;
}
.place-bet-box-body .btn-default[data-v-464ac711] {
  height: 12px;
  line-height: 12px;
  min-width: 20px;
  width: 20px;
  vertical-align: top;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: 0;
  background-color: var(--bg-sidebar);
  color: var(--text-sidebar);
  font-size: 10px;
  cursor: pointer;
}
.place-bet-buttons[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 10px 5px;
}
.place-bet-buttons .btn-place-bet[data-v-464ac711] {
  width: 19%;
  padding: 2px;
  background-color: var(--bg-sidebar);
  color: var(--text-sidebar);
  border: 0;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  margin-bottom: 5px;
  transition: none;
}
.place-bet-buttons .btn-place-bet[data-v-464ac711]:hover {
  color: #fff;
  transition: none;
}
.place-bet-action-buttons[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px 5px;
}
.place-bet-action-buttons .btn[data-v-464ac711] {
  min-width: 50px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.report-page .center-container[data-v-464ac711] {
  width: 100%;
}
.report-page #loader-section[data-v-464ac711] {
  min-height: calc(100vh - 350px);
}
.report-page .table-responsive[data-v-464ac711] {
  margin-bottom: 20px;
}
.report-page .live-unsattled[data-v-464ac711] {
  min-height: calc(100vh - 350px);
}
.report-page .live-unsattled .table-responsive[data-v-464ac711] {
  min-height: 0;
  min-height: initial;
}
.report-page .btn[data-v-464ac711],
.report-page .form-control[data-v-464ac711],
.report-page .form-select[data-v-464ac711] {
  height: 38px;
  font-size: 14px;
}
.report-page .btn[data-v-464ac711] {
  width: fit-content;
  padding: 4px 30px;
}
.report-page .card-header[data-v-464ac711] {
  padding: 4px 20px;
}
.report-page .react-datepicker-wrapper[data-v-464ac711] {
  width: max-content;
  min-width: 100%;
  width: 100%;
}
.report-page .card-title[data-v-464ac711] {
  font-size: 24px;
}
.custom-datepicker input.form-control[data-v-464ac711] {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.report-page .table td[data-v-464ac711],
.report-page .table th[data-v-464ac711] {
  padding: 6px 12px;
  font-size: 16px;
  border: 1px solid #dee2e6;
}
.report-page .table thead th[data-v-464ac711] {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.report-page .input-group span[data-v-464ac711] {
  display: flex;
  align-items: center;
}
.report-page .round-id[data-v-464ac711] {
  width: 150px;
}
.report-table-title[data-v-464ac711] {
  background: var(--bg-secondary70);
  color: var(--text-secondary);
  padding: 5px;
}
.report-page .custom-control-inline[data-v-464ac711] {
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
}
.report-page .custom-control-inline label.custom-control-label[data-v-464ac711] {
  margin-left: 6px;
}
.report-page .form-check-input[data-v-464ac711] {
  margin: 0;
  background-color: #dee2e6;
  border: none;
}
.report-page .form-check-input:checked[type="radio"][data-v-464ac711] {
  border: 4px solid #0d6efd;
  background-color: #fff;
}
.report-page .form-check-input[data-v-464ac711]:focus {
  box-shadow: none;
}
.report-page .change_btn_value label[data-v-464ac711] {
  font-weight: 700;
  margin-bottom: 0;
}
.report-page .change_btn_value .input-group[data-v-464ac711] {
  margin-bottom: 4px;
}
.report-page .change_btn_value .form-control[data-v-464ac711] {
  font-size: 16px;
  color: #242424;
  padding-left: 12px;
}
.report-page .change_btn_value .form-control[data-v-464ac711]::placeholder {
  opacity: 1 !important;
  color: #363636;
}
.report-date[data-v-464ac711] {
  width: 170px;
}
.report-sr[data-v-464ac711] {
  width: 70px;
}
.report-amount[data-v-464ac711] {
  width: 100px;
}
.report-sport[data-v-464ac711] {
  width: 170px;
}
.report-action[data-v-464ac711] {
  width: 50px;
}
.report-type[data-v-464ac711] {
  width: 150px;
}
.report-id[data-v-464ac711] {
  width: 170px;
}
.game-name[data-v-464ac711] {
  width: 200px;
}
.report-page .table[data-v-464ac711] {
  background-color: transparent;
}
.Verification_box h2[data-v-464ac711] {
  font-weight: 500;
}
.report-page .Verification_box h2 span[data-v-464ac711] {
  font-size: 16px;
  border-radius: 0;
  text-transform: uppercase;
  padding: 3px 10px;
  color: #fff;
  background-color: #dc3545;
  font-weight: 700;
}
.report-page .Verification_box .btn-group[data-v-464ac711] {
  margin-bottom: 10px;
}
.report-page .Verification_box button.btn.btn-primary[data-v-464ac711] {
  color: #28a745 !important;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  padding: 0.6rem 1rem;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  height: auto;
}
.report-page .Verification_box button.btn.btn-primary[data-v-464ac711]:focus {
  box-shadow: none;
}
.report-page .Verification_box button.btn.btn-primary.active[data-v-464ac711] {
  background-color: #28a745;
  color: #fff !important;
}
.report-page .Verification_box .verify-code[data-v-464ac711] {
  border: 1px solid #f2f2f2;
  width: auto;
  font-size: 45px;
  line-height: 1;
  color: #585858;
  background: #e4e4e4;
  padding: 10px;
}
.report-page .Verification_box h4[data-v-464ac711] {
  font-size: 21px;
}
.report-page .btn-download[data-v-464ac711] {
  background-color: #59a845;
  border: 2px solid #59a845;
  color: #fff;
  box-shadow: 2px 4px 4px #00000047;
  text-decoration: none !important;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  width: 204px;
  margin-bottom: 40px;
  height: auto;
}
.report-page .btn-download[data-v-464ac711]:hover {
  text-decoration: none;
  background-color: #fff;
  border: 2px solid #59a845;
  color: #59a845 !important;
}
.report-page .btn-download img[data-v-464ac711] {
  width: 42px;
}
.report-page .btn-download:hover img[data-v-464ac711] {
  filter: invert(56%) sepia(76%) saturate(379%) hue-rotate(62deg) brightness(88%) contrast(81%);
}
.report-page .btn-download h4[data-v-464ac711] {
  line-height: 1;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
}
.report-page .btn-download .dtext[data-v-464ac711] {
  font-size: 17px;
  line-height: 15px;
  text-decoration: none;
  font-weight: 500;
}
.change_password_design .form-group[data-v-464ac711] {
  margin-top: 12px;
  margin-bottom: 20px !important;
  display: block;
}
.center-main-container.list-page[data-v-464ac711] {
  padding: 0 1px;
}
.list-page .center-container[data-v-464ac711] {
  width: 100%;
}
.list-page #loader-section[data-v-464ac711] {
  min-height: calc(100vh - 240px);
}
.own-casino-page .casino-sub-tab[data-v-464ac711] {
  width: 100%;
}
.own-casino-page .show-close-btn .casino-sub-tab[data-v-464ac711] {
  width: calc(100% - 40px);
  margin-left: 0;
  margin-right: 0;
}
.own-casino-page .casino-sub-tab .nav-link[data-v-464ac711] {
  height: 34px;
  background-color: transparent;
  color: var(--text-secondary);
}
.own-casino-page .casino-sub-tab .nav-link.active[data-v-464ac711] {
  background-color: var(--bg-primary65);
  color: var(--text-primary);
}
.own-casino-page .casino-sub-tab[data-v-464ac711] {
  width: 100%;
}
.own-casino-page .tabs-arow.tabs-arow-left+.casino-sub-tab[data-v-464ac711] {
  width: calc(100% - 130px);
}
.own-casino-page .casino-sub-tab-list.show-close-btn .tabs-arow.tabs-arow-left+.casino-sub-tab[data-v-464ac711] {
  width: calc(100% - 170px);
}
.own-casino-page .casino-sub-tab-list[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0;
  padding: 0 5px;
  justify-content: flex-start;
}
.own-casino-page .casino-sub-tab-list .tabs-arow[data-v-464ac711] {
  background-image: linear-gradient(to right, #0088cc82, var(--bg-sidebar));
  color: var(--text-sidebar);
  width: 65px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.own-casino-page .casino-sub-tab-list .tabs-arow.tabs-arow-right[data-v-464ac711] {
  background-image: linear-gradient(to right, var(--bg-sidebar), #0088cc82);
}
.casino-list[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
}
.casino-list-item[data-v-464ac711] {
  width: calc(14.28% - 10px);
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 32px;
  cursor: pointer;
}
.casino-list-item.rect[data-v-464ac711] {
  width: calc(16.66% - 10px);
}
.casino-list-item .casino-list-item-banner[data-v-464ac711] {
  background-size: cover;
  background-position: center center;
}
.casino-list-item-banner[data-v-464ac711] {
  width: 130px;
  position: relative;
}
.casino_list_title[data-v-464ac711] {
  background-image: linear-gradient(var(--bg-primary), var(--bg-secondary));
  color: #fff;
  padding: 5px;
  position: absolute;
  width: 100%;
  bottom: -19px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 4px;
  font-size: 11px;
  left: 0;
  box-shadow: 0 11px 8px -8px #000;
}
.casino-list-item.rect .casino-list-item-banner[data-v-464ac711] {
  padding-top: 53.33%;
}
.casino-page-container .ball-runs[data-v-464ac711] {
  height: 30px;
  line-height: 30px;
  width: 30px;
  font-weight: 700;
}
.sidebar-box-rules .sidebar-title[data-v-464ac711] {
  height: auto;
  text-align: center;
}
.sidebar-box-rules .sidebar-title h4[data-v-464ac711] {
  width: 100%;
}
.casino-page .center-container[data-v-464ac711] {
  width: calc(100% - 490px);
  padding-bottom: 10px;
}
.casino-page .right-sidebar[data-v-464ac711] {
  margin-left: 5px;
}
.casino-page-container .nav-tabs .nav-item[data-v-464ac711] {
  flex: initial;
}
.casino-header[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.casino-header .casino-rid small[data-v-464ac711] {
  font-size: 16px;
}
.casino-header .casino-name[data-v-464ac711] {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.casino-header .casino-name a small[data-v-464ac711] {
  font-size: 14px;
}
.casino-header .casino-name a[data-v-464ac711] {
  color: var(--text-secondary);
  font-weight: 400;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
}
.casino-rid[data-v-464ac711] {
  font-size: 16px;
  font-weight: 400;
}
.casino-video[data-v-464ac711] {
  position: relative;
  background-color: #000;
  color: #fff;
}
.video-box-container[data-v-464ac711] {
  max-width: 100%;
  margin-left: auto;
}
.casino-video-box[data-v-464ac711] {}
.ng-tns-c3502172659-5[data-v-464ac711] {
  width: 100%;
}
.casino-video-box[data-v-464ac711],
iframe[data-v-464ac711] {
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
}
.casino-video-cards[data-v-464ac711] {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0 0 0/8%);
  height: auto;
  padding: 5px;
}
.vcasino.bollywood .flip-card-container[data-v-464ac711],
.vcasino.aaa .flip-card-container[data-v-464ac711] {
  height: 50px;
  width: 35px;
  margin: 0 auto;
}
.casino-video-cards h5[data-v-464ac711] {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
}
.casino-video-cards img[data-v-464ac711] {
  height: auto;
  width: 34px;
  border-radius: 3px;
}
.casino-video-cards img[data-v-464ac711]:last-child {
  margin-right: 0;
}
.clock[data-v-464ac711] {
  position: absolute !important;
  right: 10px;
  bottom: 10px;
  text-align: right !important;
  top: auto;
  top: initial;
  left: auto;
  left: initial;
  margin: 0 !important;
  width: auto !important;
}
.flip-card-container[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.flip-card[data-v-464ac711] {
  background-color: transparent;
  perspective: 1000px;
  height: 30px;
  width: 35px;
  margin-right: 5px;
}
.cards32a.cards32b .flip-card[data-v-464ac711],
.cards32a .flip-card[data-v-464ac711] {
  height: auto;
  width: auto;
}
.cards32a.cards32b .flip-card-container[data-v-464ac711] {
  display: inline-flex;
}
.clock .count-no[data-v-464ac711] {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #4e4b4b;
  padding: 5px 0;
  color: #fff;
  width: 35px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  border-radius: 3px;
  border: 1px solid #999;
}
.clock .count-no[data-v-464ac711] {
  padding: 0;
  width: 100px;
  border: 0;
  background-color: transparent;
}
.clock .count-no[data-v-464ac711] {
  margin-right: 31px;
  width: 45px;
}
.clock .content[data-v-464ac711] {
  font-family: Droid Sans Mono, monospace;
  height: 60px;
  display: inline-block;
  margin-left: 10px;
}
.clock .flip[data-v-464ac711] {
  position: relative;
  height: 60px;
  width: 40px;
}
.clock .down[data-v-464ac711],
.clock .up[data-v-464ac711] {
  text-align: center;
  height: 30px;
  overflow: hidden;
}
.clock .flip-card-front[data-v-464ac711],
.clock .up[data-v-464ac711] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25882352941176473);
}
.clock .down[data-v-464ac711],
.clock .flip-card-back[data-v-464ac711],
.clock .flip-card-front[data-v-464ac711],
.clock .up[data-v-464ac711] {
  width: 40px;
  background-color: #2e2e2e;
  color: #fff;
}
.clock .down[data-v-464ac711],
.clock .up[data-v-464ac711] {
  text-align: center;
  height: 30px;
  overflow: hidden;
}
.clock .down>div[data-v-464ac711],
.clock .up>div[data-v-464ac711] {
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
  align-self: center;
}
.clock .down[data-v-464ac711],
.clock .flip-card-back[data-v-464ac711],
.clock .flip-card-front[data-v-464ac711],
.clock .up[data-v-464ac711] {
  width: 40px;
  background-color: #2e2e2e;
  color: #fff;
}
.clock .down>div>div[data-v-464ac711] {
  margin-top: -30px;
}
.clock .flip-card-inner[data-v-464ac711] {
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
}
.clock .flip-card-front[data-v-464ac711],
.clock .flip-card-back[data-v-464ac711] {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}
.clock .flip-card-front[data-v-464ac711],
.clock .up[data-v-464ac711] {
  border-bottom: 1px solid #00000042;
  width: 40px;
  background-color: #2e2e2e;
  color: #fff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  height: 30px;
}
.clock .flip-card-back[data-v-464ac711],
.clock .down[data-v-464ac711] {
  background-color: #2e2e2e;
  width: 40px;
  color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 30px;
}
.clock .flip-card-back[data-v-464ac711] {
  transform: rotateX(180deg);
}
.casino-video-title[data-v-464ac711] {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 0;
  z-index: 10;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 210px;
}
.casino-video-title>div[data-v-464ac711]:first-child {
  background-color: rgba(0, 0, 0, 0.5);
}
.casino-video-title .casino-name[data-v-464ac711] {
  font-weight: 700;
  font-size: 18px;
  color: #fdcf13;
  line-height: 22px;
  padding: 0;
  background: 0 0;
  position: static;
  position: initial;
  width: auto;
}
.casino-video-title .casino-video-rid[data-v-464ac711] {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
}
.casino-video-title .rules-icon[data-v-464ac711] {
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}
.casino-table[data-v-464ac711] {
  background-color: var(--bg-table);
  color: var(--text-table);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}
.casino-table-box[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.casino-video-cards h4[data-v-464ac711] {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 2px;
  text-transform: uppercase;
}
.casino-table-full-box[data-v-464ac711] {
  width: 100%;
  border-left: 1px solid var(--table-border);
  border-right: 1px solid var(--table-border);
  border-top: 1px solid var(--table-border);
  background-color: #eee;
}
.casino-table-left-box[data-v-464ac711],
.casino-table-center-box[data-v-464ac711],
.casino-table-right-box[data-v-464ac711] {
  width: 49%;
  border-left: 1px solid var(--table-border);
  border-right: 1px solid var(--table-border);
  border-top: 1px solid var(--table-border);
  background-color: #efefef;
}
.casino-table-box-divider[data-v-464ac711] {
  background-color: var(--table-border);
  width: 2px;
}
.casino-table-header[data-v-464ac711],
.casino-table-row[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
}
.casino-nation-detail[data-v-464ac711] {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 5px;
  min-height: 40px;
}
.casino-nation-name p[data-v-464ac711] {
  line-height: 10px;
  font-size: 14px;
  font-weight: 500;
}
.casino-table-header .casino-nation-detail[data-v-464ac711] {
  font-weight: 700;
  min-height: 0;
  min-height: initial;
}
.casino-nation-name[data-v-464ac711] {
  font-size: 14px;
  font-weight: 700;
}
.casino-odds-box p[data-v-464ac711] {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 12px;
}
.casino-nation-book[data-v-464ac711] {
  font-size: 16px;
  font-weight: 500;
  min-height: 14px;
  z-index: 1;
  margin-top: 6px;
  line-height: 15px;
}
.casino-odds-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  font-weight: 700;
  border-left: 1px solid var(--table-border);
  cursor: pointer;
  min-height: 28px;
  padding: 4px 10px !important;
  cursor: pointer !important;
  font-size: 12px !important;
}
.min_max_collapse[data-v-464ac711] {
  position: relative;
}
.min_max_collapse a.minmax-btn[data-v-464ac711] {
  font-size: 16px;
  color: #000;
}
.min_max_collapse .range-collpase[data-v-464ac711] {
  background: #666 none repeat scroll 0% 0%;
  padding: 6px 14px;
  position: absolute;
  color: #fff;
  left: 0;
  z-index: 1000;
  font-size: 12px;
}
.casino-table-header .casino-odds-box[data-v-464ac711] {
  cursor: inherit;
  padding: 2px;
  min-height: 0;
  min-height: initial;
  height: auto !important;
  text-transform: uppercase;
}
.casino-odds[data-v-464ac711] {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}
.casino-odds img[data-v-464ac711] {
  height: 35px;
  margin-right: 5px;
}
.casino-volume[data-v-464ac711] {
  font-size: 12px;
  font-weight: 100;
  line-height: 1;
  margin-top: 5px;
}
.casino-remark[data-v-464ac711] {
  padding: 0 5px;
  color: var(--text-info);
  font-weight: 700;
  font-size: 12px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
.casino-remark marquee[data-v-464ac711] {
  width: 100%;
}
.casino-last-result-title[data-v-464ac711] {
  padding: 8px 10px;
  background-color: var(--bg-secondary85);
  color: var(--text-secondary);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}
.casino-last-result-title a[data-v-464ac711] {
  color: var(--text-secondary);
}
.casino-last-results[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
}
.casino-last-results .result[data-v-464ac711] {
  background: #355e3b;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  margin-left: 2px;
  cursor: pointer;
}
.casino-last-results .result.result-a[data-v-464ac711] {
  color: #ff4500;
  text-align: center;
}
.casino-last-results .result.playertie[data-v-464ac711] {
  background: #2c3e50;
  color: #fff;
}
.casino-last-results .result.result-b[data-v-464ac711] {
  text-align: center;
  color: #ff3;
}
.casino-last-results .result.result-c[data-v-464ac711] {
  color: #33c6ff;
  text-align: center;
}
.casino-last-results .result.result-tie[data-v-464ac711] {
  color: #5fff33;
  text-align: center;
}
.casino-result-round-id[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.casino-result-modal .row [class*="col-"][data-v-464ac711] {
  position: relative;
}
.casino-result-modal .row [class*="col-"][data-v-464ac711]:last-child {
  border-right: 0;
}
.casino-result-modal .casino-result-cards[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.casino-result-modal .casino-result-cards img[data-v-464ac711] {
  height: 50px;
  width: auto;
  margin-right: 5px;
}
.casino-result-modal .casino-result-cards img[data-v-464ac711]:last-child {
  margin-right: 0;
}
.casino-result-desc[data-v-464ac711] {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 6px;
  box-shadow: 0 0 4px -1px;
  margin-top: 10px;
}
.casino-result-desc-item[data-v-464ac711] {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.casino-result-desc-item div[data-v-464ac711]:first-child {
  margin-right: 2%;
  opacity: 0.6;
}
.casino-result-desc-item div[data-v-464ac711]:last-child {
  word-break: break-all;
}
.casino-winner-icon[data-v-464ac711] {
  margin: 0 10px;
}
.casino-winner-icon i[data-v-464ac711] {
  font-size: 26px;
  box-shadow: 0 0 0 var(--bg-secondary);
  animation: winnerbox-data-v-464ac711 1.5s infinite;
  border-radius: 50%;
  color: #169733;
}
@keyframes winnerbox-data-v-464ac711 {
0% {
    box-shadow: 0 0 0 0 rgba(29, 127, 30, 0.6);
}
70% {
    box-shadow: 0 0 0 10px transparent;
}
100% {
    box-shadow: 0 0 0 0 transparent;
}
}
.casino-table .suspended-row[data-v-464ac711]::before {
  background-image: url(./lock.png);
  background-size: 17px 17px;
  filter: invert(1);
  -webkit-filter: invert(1);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  pointer-events: none;
}
.casino-table .suspended-row[data-v-464ac711]::after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.card-odd-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  width: auto;
}
.card-odd-box .casino-odds[data-v-464ac711] {
  margin-bottom: 5px;
  font-size: 14px;
}
.card-odd-box img[data-v-464ac711] {
  height: 75px;
}
.rules-section .row.row5[data-v-464ac711] {
  margin-left: -5px;
  margin-right: -5px;
}
.rules-section .pl-2[data-v-464ac711] {
  padding-left: 0.5rem !important;
}
.rules-section .pr-2[data-v-464ac711] {
  padding-right: 0.5rem !important;
}
.rules-section .row.row5>[class*="col-"][data-v-464ac711],
.rules-section .row.row5>[class*="col"][data-v-464ac711] {
  padding-left: 5px;
  padding-right: 5px;
}
.rules-section[data-v-464ac711] {
  text-align: left;
  margin-bottom: 10px;
  padding: 0 20px;
}
.rules-section .table[data-v-464ac711] {
  color: var(--text-table) !important;
  border: 1px solid var(--table-border) !important;
  background-color: var(--bg-table-row) !important;
  font-size: 12px;
}
.rules-section .table td[data-v-464ac711],
.rules-section .table th[data-v-464ac711] {
  border-bottom: 1px solid var(--table-border) !important;
}
.rules-section ul li[data-v-464ac711],
.rules-section p[data-v-464ac711] {
  margin-bottom: 5px;
}
.rules-section[data-v-464ac711]::-webkit-scrollbar {
  width: 8px;
}
.rules-section[data-v-464ac711]::-webkit-scrollbar-track {
  background: #666;
}
.rules-section[data-v-464ac711]::-webkit-scrollbar-thumb {
  background-color: #333;
}
.rules-section .rules-highlight[data-v-464ac711] {
  color: var(--bg-primary) !important;
  font-weight: 700;
  font-size: 18px !important;
}
.rules-section .rules-sub-highlight[data-v-464ac711] {
  color: var(--bg-primary) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.rules-section .list-style[data-v-464ac711],
.rules-section .list-style li[data-v-464ac711] {
  list-style: disc;
}
.rules-section .rule-card[data-v-464ac711] {
  height: 20px;
  margin-left: 5px;
}
.rules-section .card-character[data-v-464ac711] {
  font-family: Card Characters;
}
.rules-section .red-card[data-v-464ac711] {
  color: red;
}
.rules-section .black-card[data-v-464ac711] {
  color: #000;
}
.rules-section .cards-box[data-v-464ac711] {
  background: #fff;
  padding: 6px;
  display: inline-block;
  color: #000;
  min-width: 150px;
}
.rules-section img[data-v-464ac711] {
  max-width: 300px;
}
.teenpatti1day .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.teenpatti1day .casino-odds-box[data-v-464ac711] {
  width: 20%;
  font-size: 16px !important;
}
.teenpatti1day .casino-table .suspended-row[data-v-464ac711]::before,
.teenpatti1day .casino-table .suspended-row[data-v-464ac711]::after {
  width: 40%;
}
.teenpatti1day .teenpatti1day-other-odds .casino-nation-detail[data-v-464ac711] {
  width: 22%;
}
.teenpatti1day .teenpatti1day-other-odds .casino-odds-box[data-v-464ac711] {
  width: 13%;
}
.teenpatti1day .teenpatti1day-other-odds .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.teenpatti20 .casino-odds-box[data-v-464ac711] {
  width: 25%;
}
.teenpatti20-other-oods[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.teenpatti20-other-oods .casino-table-left-box[data-v-464ac711],
.teenpatti20-other-oods .casino-table-right-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 0;
  padding-top: 10px;
}
.teenpatti20 .teenpatti20-other-oods .casino-odds-box[data-v-464ac711] {
  width: 49%;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
}
.teenpatti20 .teenpatti20-other-oods .casino-odds-box .casino-nation-book[data-v-464ac711] {
  position: relative;
}
.teenpatti20 .teenpatti20-other-oods .casino-odds-box .casino-nation-book[data-v-464ac711] {
  position: relative;
}
.teenpatti20 .teenpatti20-other-oods .casino-odds-box .casino-odds[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.teenpatti20 .teenpatti20-other-oods img[data-v-464ac711] {
  height: 35px;
}
.teenpatti20 .casino-table-left-box[data-v-464ac711] {
  border: none;
}
.teenpatti20 .casino-table-box .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.teenpatti20 .casino-nation-detail[data-v-464ac711] {
  width: 46%;
}
.teenpatti20 .casino_odds_row[data-v-464ac711] {
  width: 54% !important;
}
.teenpatti20 .casino-odds-box[data-v-464ac711] {
  border-color: #fff;
}
.teenpatti20 .casino_odds_row .casino-odds-box.back[data-v-464ac711] {
  width: 40% !important;
}
.teenpatti20 .casino_odds_row .casino-odds-box.back[data-v-464ac711]:last-child {
  width: 60% !important;
}
.teenpattitest .casino-nation-detail[data-v-464ac711] {
  width: 40%;
}
.teenpattitest .casino-table-full-box[data-v-464ac711] {
  border: none;
}
.teenpattitest .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.teenpattitest .casino-odds-box[data-v-464ac711] {
  border-color: #fff;
}
.teenpattitest .test-teenpatti-header-back .casino-odds-box[data-v-464ac711] {
  width: 60%;
}
.teenpattitest .casino-table-header .casino-odds-box[data-v-464ac711] {
  font-size: 15px !important;
}
.teenpattitest .casino_odds_row[data-v-464ac711] {
  width: 60% !important;
  display: flex;
  position: relative;
}
.teenpattitest .casino-table-row .casino-odds[data-v-464ac711] {
  font-size: 16px;
}
.teenpattitest .casino-table-row .casino-nation-name[data-v-464ac711] {
  font-size: 16px;
  font-weight: 700;
}
.teenpattiopen .casino-nation-detail[data-v-464ac711] {
  width: 40%;
}
.teenpattiopen .casino_odds_row[data-v-464ac711] {
  display: flex;
  width: 60%;
}
.teenpattiopen .casino_odds_row .casino-odds-box[data-v-464ac711] {
  width: 50%;
  text-align: center;
}
.teenpattiopen .casino-odds-box[data-v-464ac711] {
  width: 30%;
  text-align: center;
}
.teenpattiopen .patern-name .card-icon img[data-v-464ac711] {
  width: 12px;
  margin-left: 1px;
}
.teenpattiopen .casino-table-header .casino-odds-box[data-v-464ac711] {
  text-transform: capitalize;
}
.patern-name[data-v-464ac711] {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  padding: 0;
  margin-left: 5px;
}
.patern-name .card-icon[data-v-464ac711] {
  padding: 2px 1px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  line-height: 9px;
}
.patern-name .card-icon[data-v-464ac711] {
  padding: 4px 2px;
}
.teen-open-result-box[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.teenopenresult.casino-result-modal .row [class*="col-"][data-v-464ac711] {
  border-right: 0;
}
.teenopenresult .casino-winner-icon[data-v-464ac711] {
  right: 0;
}
.teenpatti2 .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.teenpatti2 .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.under-over-row .uo-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  width: 48%;
  margin-top: 10px;
  border: 1px solid var(--table-border);
}
.teenpatti2 .under-over-row .uo-box .casino-nation-detail[data-v-464ac711] {
  width: 70%;
}
.teenpatti2 .under-over-row .uo-box .casino-odds-box[data-v-464ac711] {
  width: 30%;
}
.teenpatti2 .teen2other[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 0;
}
.teenpatti2 .teen2other .casino-odds-box[data-v-464ac711] {
  width: 16%;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
}
.teenpatti2 .teen2other .casino-nation-book[data-v-464ac711] {
  position: relative;
}
.teenpatti2 .teen2other img[data-v-464ac711] {
  height: 35px;
}
.teenpatti2 .teen2other .casino-odds-box .casino-odds[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.teen2cards[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 0;
  padding: 10px 10px 0;
}
.teenpatti2cards .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.teenpatti2cards .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.teenpatti2cards .mini-baccarat .casino-odds-box[data-v-464ac711] {
  width: 40%;
}
.color-plus .casino-odds-box[data-v-464ac711] {
  width: 100%;
}
.teenpatti2cards .color-plus .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.teenpattimuflis .casino-odds-box[data-v-464ac711] {
  width: 33.33%;
}
.teenpattimuflis .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.teenpatti2024 .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.teenpatti2024 .casino-table-header .casino-nation-detail[data-v-464ac711] {
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
}
.teenpatti2024 .casino-table-header .casino-nation-detail>div[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
}
.teenpatti2024 .casino-nation-detail>div img[data-v-464ac711] {
  height: 30px;
  margin-right: 5px;
}
.teenpatti2024 .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.poker1day .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.poker1day .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.poker1day-other-odds[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.poker1day-other-odds .casino-table-left-box[data-v-464ac711],
.poker1day-other-odds .casino-table-right-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 0;
  padding-top: 10px;
}
.poker1day .poker1day-other-odds .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.poker1day .poker1day-other-odds .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.poker20-other-odds[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.poker20-other-odds .casino-table-left-box[data-v-464ac711],
.poker20-other-odds .casino-table-right-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 0;
  padding-top: 10px;
}
.poker20 .poker20-other-odds .casino-odds-box-container[data-v-464ac711] {
  width: 32%;
  margin-bottom: 10px;
}
.poker20 .poker20-other-odds .casino-odds-box[data-v-464ac711] {
  width: 100%;
}
.poker20 .poker20-other-odds .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.poker-20 .casino-odds-box[data-v-464ac711] {
  border-color: #fff;
}
.poker-20 .back[data-v-464ac711] {
  background-color: #72bbef !important;
}
.poker-20 .casino-nation-name[data-v-464ac711] {
  font-weight: 500;
}
.poker-20 .casino-video-cards[data-v-464ac711] {
  width: 220px;
}
.poker-20 .casino-video-cards div[data-v-464ac711]:last-child {
  margin-right: 0 !important;
}
.poker-20 .casino-video-cards img[data-v-464ac711] {
  width: 35px;
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-464ac711]:first-child {
  width: 40%;
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-464ac711]:last-child {
  width: 58%;
}
.Poker_oneday .casino-nation-detail[data-v-464ac711] {
  width: 50% !important;
}
.Poker_oneday .casino-odds-box[data-v-464ac711] {
  width: 25% !important;
}
.Poker_oneday .casino_odds_row[data-v-464ac711] {
  width: 50% !important;
}
.poker-6player .casino-table[data-v-464ac711] {
  flex-direction: column;
  background-color: transparent;
}
.poker-6player .tab-content[data-v-464ac711] {
  background-color: rgba(0, 0, 0, 0.05);
}
.poker-6player .casino-odds-box[data-v-464ac711] {
  border: none;
  padding: 0 !important;
}
.poker-6player .casino6poker-item[data-v-464ac711] {
  color: #fff !important;
  background-image: linear-gradient(to right,
      var(--bg-primary),
      var(--bg-secondary)) !important;
  width: 100% !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border: 0 !important;
  padding: 4px 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.poker-6player .casino-nation-name[data-v-464ac711] {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}
.poker-6player .patern-name .card-icon[data-v-464ac711] {
  font-size: 16px;
}
.poker-6player .patern-name img[data-v-464ac711] {
  height: 16px;
}
.poker-6player .poker6-odds[data-v-464ac711] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding-left: 5px;
}
.poker-6player .poker6-odds p[data-v-464ac711] {
  font-size: 17px;
}
.poker-6player .poker6-odds span[data-v-464ac711] {
  font-weight: 400;
}
.dt1day .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.dt1day .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.dt1day .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: 0;
}
.dt1day .dtpair[data-v-464ac711] {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: var(--bg-table-row);
  border-bottom: 1px solid var(--table-border);
}
.dt1day .dtpair .casino-odds-box[data-v-464ac711] {
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  min-height: 30px;
}
.casino-odds-box-theme[data-v-464ac711] {
  background-image: linear-gradient(to right, #08c, #2c3e50);
  color: var(--text-primary);
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border: 0;
}
.dt1day .dtredblack .casino-table-header .casino-odds-box[data-v-464ac711] {
  flex-direction: row;
}
.dt1day .dt1day-other-odds .casino-nation-detail[data-v-464ac711],
.dt1day .dt1day-other-odds .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.dt1day .card-icon img[data-v-464ac711] {
  height: 11px;
}
.dt1day .casino-nation-detail p[data-v-464ac711] {
  line-height: 12px;
}
.dt1day .dtpair p[data-v-464ac711] {
  line-height: 15px;
}
.dt1day .casino-odds-box-row[data-v-464ac711] {
  width: 40%;
  display: flex;
}
.dt1day .casino-odds-box-row .casino-odds-box[data-v-464ac711] {
  width: 50%;
}
.dt20 .casino-table-full-box[data-v-464ac711],
.dt20 .casino-table-left-box[data-v-464ac711],
.dt20 .casino-table-right-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border: 0;
}
.dt20 .dt20-odd-box[data-v-464ac711] {
  margin-right: 20px;
}
.dt20 .dt20dragon[data-v-464ac711],
.dt20 .dt20tiger[data-v-464ac711],
.dt20 .dt20pair[data-v-464ac711] {
  width: calc(29% - 15px);
}
.dt20 .dt20tie[data-v-464ac711] {
  width: calc(13% - 15px);
}
.dt20 .dt20pair[data-v-464ac711] {
  margin-right: 0;
  border-left: 5px solid var(--bg-secondary);
  padding-left: 20px;
}
.dt20 .casino-odds-box[data-v-464ac711] {
  margin: 5px 0;
  padding: 6px;
  border-radius: 5px !important;
  overflow: hidden;
  min-height: 32px;
}
.dt20 .dt20odds[data-v-464ac711] {
  width: calc(50% - 10px);
  margin-right: 10px;
  margin-bottom: 0;
}
.dt20 .dt20cards[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 0;
  padding: 10px 10px 0;
}
.dt20 .casino-table-box h4[data-v-464ac711] {
  font-weight: 400;
  font-size: 22px;
}
.dt20 .card-odd-box img[data-v-464ac711] {
  height: 56px;
}
.dt20 .casino-odds span.card-icon img[data-v-464ac711] {
  height: 14px;
  margin-right: 0;
}
.min-max-dt20 span[data-v-464ac711] {
  display: inline-flex;
  margin-left: 10px;
}
.min-max-dt20 span span[data-v-464ac711] {
  margin: 0;
}
.dtl20 .casino-nation-detail[data-v-464ac711] {
  width: 40%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.dtl20 .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.dtl20 .casino-nation-name img[data-v-464ac711] {
  height: 35px;
}
.dtl20 .casino-table .tab-content[data-v-464ac711] {
  width: 100%;
}
.dtl20 .card-icon img[data-v-464ac711] {
  height: 11px !important;
}
.dtl20 .casino-nation-name.casino-nation-card[data-v-464ac711] {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.casino-winner-icon[data-v-464ac711] {
  margin: 0 10px;
  position: absolute;
  right: 0;
}
.newcasino .back[data-v-464ac711] {
  background-color: transparent !important;
  border: 2px solid #72bbef !important;
}
.newcasino .lay[data-v-464ac711] {
  background-color: transparent !important;
  border: 2px solid #faa9ba !important;
}
.queen .casino-odd-box-container[data-v-464ac711] {
  width: calc(25% - 7.5px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.queen .casino-odd-box-container[data-v-464ac711]:last-child {
  margin-right: 0;
}
.queen .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.queen .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.queen .casino-nation-book[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.queen .casino-odds-box[data-v-464ac711] {
  border: 1px solid #72bbef;
  height: 40px;
  display: inline-flex;
  flex-direction: column;
}
.queen .casino-odds-box.lay[data-v-464ac711] {
  border-color: #f994ba;
}
.queen .casino-odds-box[data-v-464ac711]:hover {
  border-width: 2px !important;
}
.queen .casino_odd_row[data-v-464ac711] {
  justify-content: space-between;
  padding: 5px;
}
.queen .casino-video-cards .flip-card[data-v-464ac711] {
  height: 40px;
}
.queen .casino-video-cards[data-v-464ac711] {
  top: 26%;
  padding: 5px;
  transform: translateY(-50%);
}
.queen .casino-video-title[data-v-464ac711] {
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  z-index: 10;
  text-align: center;
  min-width: 220px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.queen .casino-video-title .casino-name[data-v-464ac711] {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 20px;
  color: #fdcf13;
  line-height: 22px;
  padding: 0;
  background: 0 0;
  position: static;
  position: initial;
  width: auto;
}
.queen .casino-video-rid[data-v-464ac711] {
  font-weight: 500;
  color: #ddd;
  margin-top: 3px;
  font-size: 16px;
}
.cards32a .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.cards32a .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.cards32b .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.cards32b .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.card32numbers .casino-odds-box[data-v-464ac711] {
  border-color: #fff !important;
}
.cards32b .cards32total .casino-odds-box[data-v-464ac711] {
  height: 63px;
  width: 100% !important;
}
.card32numbers-container[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
}
.cards32b .card32numbers-container .casino-odds-box[data-v-464ac711] {
  border-bottom: 1px solid var(--table-border);
}
.cards32b .card32numbers-container .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.card32numbers .casino-odds[data-v-464ac711] {
  font-family: casinonumfont;
  font-size: 57px;
  height: 57px;
  line-height: 57px;
  font-weight: 400;
}
.lucky7a .casino-table-full-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border: 0;
  justify-content: space-between;
  align-items: flex-start;
}
.lucky7a .lucky7low[data-v-464ac711],
.lucky7a .lucky7high[data-v-464ac711] {
  width: 45%;
}
.lucky7a .casino-table-left-box[data-v-464ac711],
.lucky7a .casino-table-right-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border: 0;
}
.lucky7a .lucky7odds[data-v-464ac711] {
  width: calc(50% - 10px);
  margin-right: 10px;
}
.card-odd-box-container[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.lucky7cards[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 24%;
  background-color: var(--bg-table-row);
  padding: 10px 0 0;
  height: 130px;
  align-content: flex-start;
}
.lucky7a .casino-odds[data-v-464ac711] {
  font-size: 16px !important;
  text-transform: uppercase;
  font-family: inherit;
}
.lucky7a .casino-odds-box-theme[data-v-464ac711] {
  overflow: hidden;
  min-height: 32px;
}
.baccarat .casino-table-full-box[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.baccarat .baccarat-graph[data-v-464ac711] {
  width: 18%;
}
.baccarat-odds-container[data-v-464ac711] {
  width: 75%;
}
.baccarat .baccarat-other-odds[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.baccarat .baccarat-other-odd-box-container[data-v-464ac711] {
  width: 24%;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  text-align: center;
  padding: 3px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.baccarat .baccarat-main-odds[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.player-pair-box-container[data-v-464ac711] {
  width: 16%;
  margin-right: 1%;
}
.player-pair-box[data-v-464ac711] {
  background-color: #086cb8;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  text-align: left;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  flex-direction: column;
  font-size: 14px;
  line-height: 17px;
}
.player-pair-box.suspended-box[data-v-464ac711]:after {
  border-radius: 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  -ms-border-radius: 30px 0 0 30px;
  -o-border-radius: 30px 0 0 30px;
}
.player-pair-box>div[data-v-464ac711] {
  line-height: 1;
}
.player-box-container[data-v-464ac711],
.tie-box-container[data-v-464ac711],
.banker-box-container[data-v-464ac711] {
  width: 22%;
}
.player-box[data-v-464ac711] {
  background-color: #086cb8;
  color: #fff;
  padding: 10px;
  height: 70px;
  line-height: 16px;
  text-transform: uppercase;
}
.player-box>div[data-v-464ac711] {
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
}
.player-box img[data-v-464ac711],
.banker-box img[data-v-464ac711] {
  height: 20px;
  margin-right: 5px;
  z-index: 1;
}
.tie-box[data-v-464ac711] {
  background-color: #279532;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  height: 70px;
}
.tie-box>div[data-v-464ac711] {
  line-height: 1;
}
.banker-box[data-v-464ac711] {
  background-color: #ae2130;
  color: #fff;
  padding: 10px;
  height: 70px;
  line-height: 16px;
  text-transform: uppercase;
}
.banker-box>div[data-v-464ac711] {
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
}
.banker-pair-box-container[data-v-464ac711] {
  width: 16%;
  margin-left: 1%;
}
.banker-pair-box[data-v-464ac711] {
  background-color: #ae2130;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  text-align: left;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-size: 14px;
  line-height: 17px;
}
.banker-pair-box.suspended-box[data-v-464ac711]:after {
  border-radius: 0 30px 30px 0;
  -webkit-border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  -ms-border-radius: 0 30px 30px 0;
  -o-border-radius: 0 30px 30px 0;
}
.banker-pair-box>div[data-v-464ac711] {
  line-height: 1;
}
.baccarat .casino-last-results .result.result-a[data-v-464ac711] {
  background-color: #086cb8;
  color: #fff;
}
.baccarat .casino-last-results .result.result-b[data-v-464ac711] {
  background-color: #ae2130;
  color: #fff;
}
.baccarat .casino-last-results .result.playertie[data-v-464ac711] {
  background: var(--theme2-bg);
  color: var(--secondary-color);
}
.baccarat .min-max[data-v-464ac711] {
  text-align: right;
  margin-top: 6px;
  padding-right: 10px;
  margin-bottom: 15px;
  font-weight: 500;
}
.l-rotate[data-v-464ac711],
.r-rotate[data-v-464ac711] {
  margin-left: 5px !important;
  margin-right: 10px !important;
}
.l-rotate img[data-v-464ac711],
img.l-rotate[data-v-464ac711] {
  transform: rotate(270deg);
}
.r-rotate>img[data-v-464ac711],
img.r-rotate[data-v-464ac711] {
  transform: rotate(90deg);
}
.r-rotate>img[data-v-464ac711],
img.r-rotate[data-v-464ac711] {
  transform: rotate(90deg);
}
.baccarat2game .baccarat-other-odd-box-container[data-v-464ac711] {
  width: 19%;
}
.casino-war .casino-nation-detail[data-v-464ac711] {
  width: 40%;
}
.casino-war .casino-odds-box[data-v-464ac711] {
  width: 10%;
  border-left: 1px solid #fff;
}
.casino-war .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: 0;
}
.casino-war .casino-nation-name img[data-v-464ac711] {
  height: 28px;
}
.casino-war .casino-odds-box img[data-v-464ac711] {
  width: 30px;
}
.casino-war .casino-table[data-v-464ac711] {
  background: #fff;
  padding: 10px 0;
}
.casino-war .casino-table[data-v-464ac711] {
  margin-top: 0;
}
.casino-war .flip-card[data-v-464ac711] {
  height: 43px;
}
.casino-war .casino-nation-name[data-v-464ac711] {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 8px;
}
.casino-war span.card-icon img[data-v-464ac711] {
  height: 11px;
}
.casino-war .min_max_collapse .range-collpase[data-v-464ac711] {
  left: inherit;
  right: 0;
}
.casinowarresult .result-item h4.result-title[data-v-464ac711] {
  font-size: 16px;
}
.casinowarresult .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 40px !important;
}
.aaa .casino-odd-box-container[data-v-464ac711] {
  width: calc(33.33% - 7.5px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.aaa .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.aaa .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.aaa .casino-odd-box-container[data-v-464ac711]:last-child {
  margin-right: 0;
}
.aaa .casino-table-left-box[data-v-464ac711],
.aaa .casino-table-center-box[data-v-464ac711],
.aaa .casino-table-right-box[data-v-464ac711] {
  width: 32%;
  padding: 10px 10px 0;
}
.aaa .aaa-odd-box[data-v-464ac711] {
  margin-bottom: 10px;
  min-height: 92px;
}
.aaa .casino-table-left-box .casino-odds-box[data-v-464ac711],
.aaa .casino-table-center-box .casino-odds-box[data-v-464ac711],
.aaa .casino-table-right-box .casino-odds-box[data-v-464ac711] {
  width: 100%;
  margin: 5px 0;
}
.aaacards[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 0;
  padding: 10px 10px 0;
}
.aaa .casino-odd-box-container .casino-nation-name[data-v-464ac711] {
  font-size: 18px;
}
.aaa .casino-table-box[data-v-464ac711] {
  margin-top: 10px;
}
.aaa .casino-table[data-v-464ac711] {
  background: 0 0;
  margin-top: 0;
}
.aaa .casino-tavle-box-background[data-v-464ac711] {
  background: #efefef;
}
.aaa .casino-table-left-box[data-v-464ac711],
.casino-table-center-box[data-v-464ac711],
.casino-table-right-box[data-v-464ac711] {
  border: none;
}
.aaa .min_max_collapse[data-v-464ac711] {
  text-align: right;
  padding-right: 10px;
  display: inline-block;
  width: 100%;
}
.aaa .casino-odds[data-v-464ac711] {
  font-size: 18px;
}
.aaa .card-odd-box img[data-v-464ac711] {
  height: 50px;
}
.aaa .casino-table-left-box .casino-odds-box[data-v-464ac711] {
  border-radius: 4px;
  overflow: hidden;
}
.aaa .ab-cards-container .card_f_s[data-v-464ac711] {
  font-size: 22px;
}
.aaa .casino-result-cardimg img[data-v-464ac711] {
  height: 40px !important;
}
.aaa .casino-nation-book[data-v-464ac711] {
  line-height: 8px;
}
.aaa .casino-odd-box-container[data-v-464ac711] {
  padding: 0 10px;
}
.aaa .casino-table-left-box[data-v-464ac711],
.aaa .casino-table-center-box[data-v-464ac711],
.aaa .casino-table-right-box[data-v-464ac711] {
  width: 32.7%;
}
.aaa .range-collpase[data-v-464ac711] {
  left: inherit;
  right: 0;
}
.aaa .casino-odds-box-row[data-v-464ac711] {
  display: flex;
  width: 100%;
}
.aaa .casino-odds-box-row .casino-odds-box[data-v-464ac711] {
  width: 50%;
  border: none;
  padding: 6px 10px !important;
  overflow: hidden;
}
.aaa .casino-odds img[data-v-464ac711] {
  height: 14px;
  margin-right: 5px;
}
.bollywood .casino-odd-box-container[data-v-464ac711] {
  width: calc(33.33% - 7.5px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.bollywood .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.bollywood .casino-odds[data-v-464ac711] {
  font-size: 16px;
  line-height: 1.5;
}
.bollywood .min_max_collapse a.minmax-btn[data-v-464ac711] {
  padding: 10px;
}
.bollywood .card-odd-box img[data-v-464ac711] {
  height: 50px;
}
.bollywood .casino-table-box_top_bg[data-v-464ac711] {
  background: #efefef;
  padding: 0 10px 10px;
}
.bollywood .casino-odds-box-row[data-v-464ac711] {
  display: flex;
  width: 100%;
}
.bollywood .casino-odds-box[data-v-464ac711] {
  border-left: none;
}
.casino-table-left-box[data-v-464ac711],
.casino-table-center-box[data-v-464ac711],
.casino-table-right-box[data-v-464ac711] {
  border: none;
}
.bollywood .casino-odds-box[data-v-464ac711] {
  width: 50%;
}
.bollywood .casino-odd-box-container[data-v-464ac711]:nth-child(3n) {
  margin-right: 0;
}
.bollywood .casino-table-left-box.left-odd-box[data-v-464ac711] {
  width: 33%;
  padding: 10px;
}
.bollywood .casino-table-left-box.left-odd-box .casino-odd-box-container[data-v-464ac711] {
  width: 100%;
  margin: 0%;
}
.bollywood .casino-table-left-box[data-v-464ac711],
.bollywood .casino-table-right-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
}
.bollywood .casino-table-right-box.right-odd-box[data-v-464ac711] {
  width: 66%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bollywood .casino-table-left-box[data-v-464ac711],
.bollywood .casino-table-right-box[data-v-464ac711] {
  padding: 10px;
}
.bollywood .casino-table-left-box .aaa-odd-box[data-v-464ac711],
.bollywood .casino-table-right-box .aaa-odd-box[data-v-464ac711] {
  width: 49%;
}
.bollywood .casino-table-left-box .aaa-odd-box .casino-odds-box[data-v-464ac711],
.bollywood .casino-table-right-box .aaa-odd-box .casino-odds-box[data-v-464ac711] {
  width: 100%;
}
.bollywood .casino-table-right-box.right-cards[data-v-464ac711] {
  justify-content: center;
  position: relative;
}
.bollywood .min_max_collapse .range-collpase[data-v-464ac711] {
  left: inherit;
  right: 0;
}
.bollywood .casino-odds img[data-v-464ac711] {
  height: 15px;
  margin-right: 5px;
}
.bollywood .casino-table-right-box.right-cards .min_max_collapse[data-v-464ac711] {
  position: absolute;
  right: 0;
}
.bollywood .casino-table-right-box.right-cards .card-odd-box[data-v-464ac711] {
  margin-bottom: 8px;
}
.bollywood .casino-table-right-box.right-cards .min_max_collapse .range-collpase[data-v-464ac711] {
  left: inherit;
  right: 0;
  min-width: 147px;
  max-width: 220px;
  width: max-content;
}
.andarbg[data-v-464ac711] {
  background-color: #ffa07a !important;
}
.baharbg[data-v-464ac711] {
  background-color: #90ee90 !important;
}
.ab .andar-box[data-v-464ac711] {
  background-color: #ffa07a;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
}
.ab-title[data-v-464ac711] {
  width: 10%;
  text-align: center;
  border-left: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-weight: 700;
}
.ab-cards[data-v-464ac711] {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #000;
  padding: 10px;
  padding-bottom: 0;
}
.ab .bahar-box[data-v-464ac711] {
  background-color: #90ee90;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
}
.ab-cards-container[data-v-464ac711] {
  width: 155px;
}
.ab-cards-container img[data-v-464ac711] {
  height: 50px !important;
  width: auto !important;
  margin-right: 5px !important;
}
.ab-result-container .slick-prev[data-v-464ac711]::before,
.ab-result-container .slick-next[data-v-464ac711]::before {
  color: #000;
}
.ab-result-container .ab-result-slider[data-v-464ac711] {
  margin-left: 7%;
  margin-right: 7%;
  width: 84% !important;
  display: block !important;
}
.ab-result-container .ab-result-slider img[data-v-464ac711] {
  height: 50px !important;
  width: 40px !important;
}
.ab2-result-container[data-v-464ac711] {
  align-items: center;
}
.ab2-result-container .ab-result-slider[data-v-464ac711] {
  margin-left: 4%;
  margin-right: 4%;
  width: 65% !important;
  display: block !important;
  margin-bottom: 5px;
}
.ab2-result-container img[data-v-464ac711] {
  height: 50px !important;
  width: 40px !important;
  margin-right: 5px;
}
.ab2 .casino-table-full-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--table-border);
  padding: 10px;
}
.ab2 .playera-bets[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 49%;
}
.ab2 .ab2cards .card-odd-box img[data-v-464ac711] {
  height: 50px;
}
.ab2 .ab2-box .casino-odds[data-v-464ac711] {
  height: 35px;
}
.ab2 .ab2-box .casino-odds-box .casino-odds[data-v-464ac711] {
  height: 20px;
}
.ab2 .ab2-box .casino-odds[data-v-464ac711] {
  font-size: 18px;
}
.ab2 .casino-nation-book[data-v-464ac711] {
  font-size: 13px;
  margin-top: 3px;
}
.ab2 .casino-table-left-box[data-v-464ac711],
.casino-table-center-box[data-v-464ac711],
.casino-table-right-box[data-v-464ac711] {
  border: none;
}
.ab2 .casino-table-full-box[data-v-464ac711] {
  border: none;
}
.ab2 .casino-table[data-v-464ac711] {
  background: #fff;
}
.ab2 .casino-table[data-v-464ac711] {
  margin-top: 0;
}
.ab2 .slick-prev[data-v-464ac711] {
  position: absolute;
  top: 45%;
  left: -12px;
  font-size: 0 !important;
  height: 10px;
  width: 10px;
  background-size: 10px;
  border: none;
  transform: translateY(-50%);
}
.ab2 .slick-next[data-v-464ac711] {
  position: absolute;
  top: 45%;
  right: -9px;
  font-size: 0 !important;
  height: 10px;
  width: 10px;
  background-size: 10px;
  border: none;
  transform: translateY(-50%);
}
.playera-title[data-v-464ac711] {
  width: 10%;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
}
.player-sa[data-v-464ac711] {
  width: 20%;
  margin-left: 2%;
}
.player-sa-box[data-v-464ac711] {
  text-align: center;
  padding: 5px 0;
  border: 3px solid #ff0;
  border-radius: 8px;
  margin-bottom: 5px;
}
.player-bet[data-v-464ac711] {
  width: 25%;
  margin-left: 2%;
}
.player-bet-box[data-v-464ac711] {
  text-align: center;
  padding: 5px 0;
  border: 3px solid #ff0;
  border-radius: 8px;
  background-color: #086cb8;
  color: #fff;
  margin-bottom: 5px;
}
.ab2 .casino-table-left-box[data-v-464ac711],
.ab2 .casino-table-right-box[data-v-464ac711] {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ab2-box[data-v-464ac711] {
  width: 49%;
}
.casino-table-right-box .ab2-box[data-v-464ac711] {
  width: 24%;
}
.ab2 .casino-table-left-box .casino-odds[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 35px;
}
.ab2 .ab2cards[data-v-464ac711] {
  justify-content: center;
}
.ab2 .ab-cards-container[data-v-464ac711] {
  width: 350px;
  color: #fff;
}
.ab2 .ab-cards-container img[data-v-464ac711] {
  height: 35px !important;
  margin-bottom: 5px !important;
}
.casino-video-cards .col-9[data-v-464ac711] {
  padding-left: 20px;
}
.ab.ab3 .andar-box[data-v-464ac711],
.andarbg3[data-v-464ac711] {
  background-color: #ffa07a;
}
.ab.ab3 .bahar-box[data-v-464ac711],
.baharbg3[data-v-464ac711] {
  background-color: #90ee90;
}
.ab.ab3 .card-odd-box img[data-v-464ac711] {
  height: 56px;
}
.ab.ab3 .slick-prev[data-v-464ac711] {
  position: absolute;
  top: 45%;
  left: -12px;
  font-size: 0 !important;
  height: 10px;
  width: 10px;
  background-size: 10px;
  border: none;
  transform: translateY(-50%);
}
.ab.ab3 .slick-next[data-v-464ac711] {
  position: absolute;
  top: 45%;
  right: -9px;
  font-size: 0 !important;
  height: 10px;
  width: 10px;
  background-size: 10px;
  border: none;
  transform: translateY(-50%);
}
.ab.ab3 .ab-cards-container[data-v-464ac711] {
  width: 240px;
  color: #fff;
  padding: 0 10px;
}
.ab.ab3 .ab-cards-container img[data-v-464ac711] {
  height: 37px !important;
}
.worli .tab-content[data-v-464ac711] {
  width: 100%;
}
.worli .worli_welcome[data-v-464ac711] {
  color: #00b4d8;
}
.worli .worli_trio[data-v-464ac711] {
  padding: 5px 10px;
  background: #007bff;
  color: #fff;
  border-radius: 5px;
}
.worlibox[data-v-464ac711] {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.worli-left[data-v-464ac711] {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
}
.worli-box-title[data-v-464ac711] {
  width: 100%;
  text-align: center;
  margin: 5px 0;
  min-height: 24px;
}
.worli-box-row[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}
.worli-odd-box[data-v-464ac711] {
  text-align: center;
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.worli-left .worli-odd-box[data-v-464ac711],
.worli-full .worli-odd-box[data-v-464ac711] {
  width: calc(20% - 2px);
  margin-right: 2px;
}
.worli-odd-box .worli-odd[data-v-464ac711] {
  font-size: 40px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: casinonumfont;
}
.worli-right[data-v-464ac711] {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
}
.worli-right .worli-odd-box[data-v-464ac711] {
  width: calc(50% - 2px);
  margin-right: 2px;
}
.worli-full[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.worli-odd-box.back.selected[data-v-464ac711] {
  background-color: var(--bg-success) !important;
  color: #fff;
}
.sp .worli-right .worli-odd-box[data-v-464ac711],
.dp .worli-right .worli-odd-box[data-v-464ac711],
.chart56 .worli-right .worli-odd-box[data-v-464ac711],
.chart64 .worli-right .worli-odd-box[data-v-464ac711],
.colordp .worli-right .worli-odd-box[data-v-464ac711] {
  height: 142px;
  width: 100%;
}
.trio .worli-full .worli-odd-box[data-v-464ac711] {
  width: 100%;
}
.abr .worli-left .worli-odd-box[data-v-464ac711] {
  width: calc(33.33% - 2px);
}
.abr .worli-right .worli-odd-box[data-v-464ac711] {
  width: 100%;
}
.abr .worli-right .worli-odd-box[data-v-464ac711]:last-child {
  margin-right: 0;
}
.abr .worli-right .worli-odd-box[data-v-464ac711]:last-child {
  margin-right: 0;
}
.worli-cards-container[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
}
.worli-cards-block[data-v-464ac711] {
  width: calc(10% - 9px);
  text-align: center;
  background-color: var(--bg-secondary85);
  padding: 10px;
  font-size: 20px;
  color: var(--text-secondary);
  border-radius: 8px;
  box-shadow: 0 0 2px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.worli-cards-block[data-v-464ac711]:nth-child(10n) {
  margin-right: 0;
}
.worli-cards-block span[data-v-464ac711] {
  margin-right: 5px;
}
.threecardjbox[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
}
.threecard-title[data-v-464ac711] {
  width: 10%;
  text-align: center;
  border-left: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}
.threecardj-cards[data-v-464ac711] {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #000;
  padding: 10px;
  padding-bottom: 0;
}
.threecard-info[data-v-464ac711] {
  width: 100%;
}
.threecardj-cards img[data-v-464ac711] {
  border: 2px solid transparent;
  padding: 2px;
}
.threecardj-cards .selected img[data-v-464ac711] {
  border: 2px solid var(--bg-success);
}
.casino-table-box .brate[data-v-464ac711] {
  width: 100%;
  text-align: center;
  border: 1px solid #000;
  border-bottom: 0;
  font-weight: 600;
  padding: 3px;
}
.threecrdju .ab-title[data-v-464ac711] {
  flex-direction: column;
}
.threecrdju .ab-title p[data-v-464ac711] {
  display: block;
  font-weight: 500;
  color: #8d0000;
  line-height: 15px;
}
.threecrdju .andar-box[data-v-464ac711] {
  background-color: transparent !important;
}
.threecrdju .andar-box .ab-title[data-v-464ac711] {
  border-bottom: 0;
  background: #72bbef;
}
.threecrdju .andar-box .ab-cards[data-v-464ac711] {
  border-bottom: 0;
  background: #72bbef;
}
.threecrdju .bahar-box[data-v-464ac711] {
  background-color: transparent !important;
}
.threecrdju .bahar-box .ab-title[data-v-464ac711] {
  border-bottom: 0;
  background: #72bbef;
}
.threecrdju .bahar-box .ab-cards[data-v-464ac711] {
  border-bottom: 0;
  background: #faa9ba;
}
.race-total-point[data-v-464ac711] {
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  background: #404040;
  padding: 5px;
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  z-index: 10;
  text-align: center;
  min-width: 220px;
  flex-direction: column;
}
.race20 .race-total-point .casino-name[data-v-464ac711] {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 20px;
  color: #fdcf13;
  line-height: 22px;
  padding: 0;
  background: 0 0;
  position: static;
  position: initial;
  width: 100%;
}
.race20 .race-total-point .casino-video-rid[data-v-464ac711] {
  font-weight: 500;
  color: #ddd;
  margin-top: 3px;
  font-size: 16px;
  width: 100%;
}
.race20 .race-total-point .points_row[data-v-464ac711] {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.race20 .race-total-point .points_row .text-white[data-v-464ac711] {
  padding: 5px;
  margin-right: 5px;
  border: 1px solid #fdcf13;
  color: #fff;
  width: 45%;
  line-height: 14px;
  margin-top: 7px;
}
.casino-video-rules-icon[data-v-464ac711] {
  border: 1px solid #999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  right: 5px;
  top: 5px;
  z-index: 100;
  color: #fff;
  display: flex;
  height: 40px;
  width: 40px;
  font-size: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.casino-video-rules-icon[data-v-464ac711]:hover,
.casino-video-rules-icon[data-v-464ac711]:focus {
  background-color: rgba(115, 115, 115, 0.5);
  outline: 0;
}
.race20 .flip-card-container[data-v-464ac711] {
  justify-content: flex-start;
}
.race20 .flip-card[data-v-464ac711] {
  height: auto !important;
  width: 24px;
}
.race20 .casino-video-cards[data-v-464ac711] {
  top: 26%;
  transform: translateY(-50%);
}
.race20 .casino-video-cards .flip-card[data-v-464ac711] {
  height: auto;
  width: auto;
  padding: 0px 10px;
}
.race20 .min_max_collapse .range-collpase[data-v-464ac711] {
  min-width: max-content;
}
.race20 .casinobox-row[data-v-464ac711] {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}
.race20 .casino-odd-box-container[data-v-464ac711] {
  width: calc(25% - 7.5px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.race20 .casino-odd-box-container[data-v-464ac711]:last-child {
  margin-right: 0;
}
.race20 .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.race20 .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.race20 .casino-odds-box[data-v-464ac711] {
  background: 0 0 !important;
  border: 1px solid #72bbef;
  height: 40px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: revert;
}
.race20 .casino-odds-box.lay[data-v-464ac711] {
  border-color: #f994ba;
}
.race20 .casino-odds-box.lay[data-v-464ac711]:hover {
  border: 2px solid #f994ba;
}
.race20 .casino-odds-box[data-v-464ac711]:hover {
  border: 2px solid #72bbef;
}
.race20 .casino-table-left-box[data-v-464ac711],
.casino-table-center-box[data-v-464ac711],
.casino-table-right-box[data-v-464ac711] {
  border: none;
}
.race20 .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.race20 .min_max_collapse a.minmax-btn[data-v-464ac711] {
  font-size: 14px;
}
.race20 .casino-table-left-box .casino-odd-box-container .casino-nation-name[data-v-464ac711],
.race20 .casino-table-left-box .casino-odd-box-container .casino-odds-box[data-v-464ac711] {
  width: 32.33% !important;
}
.casino-table[data-v-464ac711] {
  background: #fff;
}
.race20 .casino-table-box_bg[data-v-464ac711] {
  background: #efefef;
  padding-left: 15px;
  padding-right: 15px;
}
.race20 .casino-nation-name img[data-v-464ac711] {
  height: 40px;
  margin-bottom: 5px;
}
.race20 .casino-table-left-box[data-v-464ac711] {
  width: 33%;
  padding: 5px 5px 0;
}
.race20 .casino-table-left-box .casino-odd-box-container[data-v-464ac711] {
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.race20 .casino-table-left-box .casino-odd-box-container .casino-nation-name[data-v-464ac711],
.race20 .casino-table-left-box .casino-odd-box-container .casino-odds-box[data-v-464ac711] {
  width: 33.33%;
}
.race20 .casino-table-left-box .casino-odd-box-container>.casino-nation-book[data-v-464ac711] {
  width: 66.66%;
  text-align: center;
  margin-left: auto;
  margin-top: 5px;
}
.race20 .video-winner-text[data-v-464ac711] {
  color: #000 !important;
  border: 1px solid #000;
}
.race20 .right_box_padding .casino-odd-box-container[data-v-464ac711] {
  padding-bottom: 10px;
}
.race20 .casino-table-right-box[data-v-464ac711] {
  width: 66%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 0;
}
.race20 .casino-table-right-box .casino-odd-box-container[data-v-464ac711] {
  width: calc(33.33% - 10px);
}
.race20 .casino-table-right-box .casino-odd-box-container[data-v-464ac711]:nth-child(3n) {
  margin-right: 0;
}
.race20 .casino-table-right-box .casino-odds-box[data-v-464ac711] {
  width: 100%;
}
.race20 .casino-last-results .result[data-v-464ac711] {
  background-color: #d5d5d5;
  border: 1px solid #626262;
}
.race20 .casino-last-results .result img[data-v-464ac711] {
  height: 30px;
}
.race-result-box[data-v-464ac711] {
  width: 325px;
  position: relative;
}
.race-result-box .casino-result-cards[data-v-464ac711] {
  position: relative;
  justify-content: flex-start;
}
.race-result-box .casino-result-cards .k-image[data-v-464ac711] {
  position: absolute;
  right: -50px;
}
.race-result-box .video-winner-text[data-v-464ac711] {
  color: var(--text-info);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  width: 42px;
  height: 100%;
  border: 1px solid var(--text-info);
  padding: 2px 12px;
  background-color: var(--bg-body);
  z-index: 100;
}
.race-result-box .casino-winner-icon[data-v-464ac711] {
  right: -95px;
  top: 15px;
  position: absolute;
}
.raceto17 .casino-odd-box-container[data-v-464ac711] {
  width: calc(25% - 7.5px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.raceto17 .casino-odd-box-container[data-v-464ac711]:last-child {
  margin-right: 0;
}
.raceto17 .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.raceto17 .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.baccarat29 .casino-odds-box[data-v-464ac711] {
  width: 25%;
}
.baccarat29 .casino-table-full-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--table-border);
}
.baccarat29 .casino-table-full-box img[data-v-464ac711] {
  height: 100px;
  z-index: 10;
}
.baccarat29 .casino-odd-box-container[data-v-464ac711] {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: 20px;
}
.baccarat29 .casino-odd-box-container .casino-odds-box[data-v-464ac711] {
  width: 50%;
}
.baccarat29 .casino-odds-box.suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.trio .casino-odd-box-container[data-v-464ac711] {
  width: calc(33.33% - 10px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.trio .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
}
.trio .casino-odds-box[data-v-464ac711] {
  width: 49%;
}
.trio .casino-odd-box-container[data-v-464ac711]:last-child {
  margin-right: 0;
}
.trio .triocards .casino-odd-box-container[data-v-464ac711] {
  width: calc(25% - 7.5px);
}
.trio .trioodds .casino-odd-box-container[data-v-464ac711] {
  width: calc(20% - 10px);
}
.trio .trioodds .casino-odds-box[data-v-464ac711] {
  width: 100%;
}
.note-number .casino-odd-box-container-box[data-v-464ac711] {
  width: calc(33.33% - 10px);
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.note-number .casino-odd-box-container[data-v-464ac711] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.note-number .casino-nation-name[data-v-464ac711] {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
}
.note-number .casino-nation-name img[data-v-464ac711] {
  height: 30px;
  margin-right: 5px;
}
.note-number .casino-odds-box[data-v-464ac711] {
  width: 49%;
  text-align: center;
}
.note-number .casino-table-left-box[data-v-464ac711] {
  width: 33%;
  padding: 5px 5px 0;
  border-bottom: 1px solid var(--table-border);
}
.note-number .casino-table-left-box .casino-odd-box-container[data-v-464ac711] {
  width: 100%;
}
.note-number .casino-table-left-box .casino-odds-box[data-v-464ac711] {
  width: 100%;
}
.note-number .casino-table-right-box[data-v-464ac711] {
  width: 66%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 5px 0;
  border-bottom: 1px solid var(--table-border);
}
.note-number .casino-table-left-box .suspended-box[data-v-464ac711]::before {
  top: -12px;
}
.onecard1day .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.onecard1day .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.onecard1day .seven-up-down-box[data-v-464ac711] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 2px solid #72bbef;
}
.onecard1day .up-box[data-v-464ac711] {
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  position: relative;
  justify-content: flex-end;
}
.onecard1day .up-box .up-down-book[data-v-464ac711] {
  position: absolute;
  left: 10px;
  z-index: 1;
}
.onecard1day .up-down-odds[data-v-464ac711] {
  font-weight: 700;
  font-size: 18px;
}
.onecard1day .down-box[data-v-464ac711] {
  width: 50%;
  text-align: right;
  height: 50px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 40px;
  justify-content: flex-start;
  position: relative;
}
.onecard1day .down-box .up-down-book[data-v-464ac711] {
  position: absolute;
  right: 10px;
  z-index: 1;
}
.onecard1day .seven-box[data-v-464ac711] {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.onecard1day .seven-box img[data-v-464ac711] {
  height: 70px;
}
.onecard20 .casino-table-full-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border: 0;
}
.onecard20 .onecard20player[data-v-464ac711],
.onecard20 .onecard20dealer[data-v-464ac711],
.onecard20 .onecard20pair[data-v-464ac711] {
  width: calc(29% - 15px);
}
.onecard20 .casino-odds-box[data-v-464ac711] {
  margin: 5px 0;
  padding: 6px;
}
.onecard20 .onecard20oddbox[data-v-464ac711] {
  margin-right: 20px;
}
.onecard20 .onecard20tie[data-v-464ac711] {
  width: calc(13% - 15px);
}
.onecard20 .onecard20pair[data-v-464ac711] {
  margin-right: 0;
  border-left: 5px solid var(--bg-secondary);
  padding-left: 20px;
}
.trap .casino-video-cards[data-v-464ac711] {
  color: #fff;
  text-align: center;
}
.trap .casino-video-cards img[data-v-464ac711] {
  height: 30px;
  margin-bottom: 5px;
}
.trap .casino-nation-detail[data-v-464ac711] {
  width: 60%;
}
.trap .casino-nation-detail img[data-v-464ac711] {
  height: 30px;
  margin-right: 5px;
}
.trap .casino-odds-box[data-v-464ac711] {
  width: 20%;
}
.trap .seven-up-down-box[data-v-464ac711] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 2px solid #72bbef;
}
.trap .up-box[data-v-464ac711] {
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  position: relative;
  justify-content: flex-end;
}
.trap .up-box .up-down-book[data-v-464ac711] {
  position: absolute;
  left: 10px;
}
.trap .up-down-odds[data-v-464ac711] {
  font-weight: 700;
  font-size: 18px;
}
.trap .down-box[data-v-464ac711] {
  width: 50%;
  text-align: right;
  height: 50px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 40px;
  justify-content: flex-start;
  position: relative;
}
.trap .down-box .up-down-book[data-v-464ac711] {
  position: absolute;
  right: 10px;
  z-index: 1;
}
.trap .seven-box[data-v-464ac711] {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.trap .seven-box img[data-v-464ac711] {
  height: 70px;
}
.trap .casino-video-cards .v-slider[data-v-464ac711] {
  height: 200px;
  margin-top: 20px;
}
.trap .casino-video-cards[data-v-464ac711] {
  width: 70px;
}
.trap .casino-video-cards .v-slider .slick-prev[data-v-464ac711] {
  left: 45%;
  transform: translateX(-50%) rotate(90deg);
  top: -20px;
}
.trap .casino-video-cards .v-slider .slick-next[data-v-464ac711] {
  left: 45%;
  transform: translateX(-50%) rotate(90deg);
  bottom: -20px;
  top: auto;
  top: initial;
}
.trap .casino-video-cards img[data-v-464ac711] {
  margin-bottom: 2px;
}
.trap-number-icon[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: var(--bg-secondary85);
  margin-bottom: 10px;
  padding: 5px;
}
.trap-number-icon img[data-v-464ac711] {
  height: 50px;
  margin-right: 5px;
}
.kbc .kbc-btns[data-v-464ac711] {
  align-items: center;
  width: 100%;
}
.casino-odd-box-container[data-v-464ac711] {
  width: 100%;
}
.kbc .kbc-btns .casino-nation-name[data-v-464ac711] {
  background: var(--bg-secondary);
  border-radius: 12px;
  color: var(--text-secondary);
  padding: 6px;
  border: 1px solid var(--text-fancy);
  border-bottom: 0;
  text-align: left;
}
.kbc .kbc-btns .btn-group[data-v-464ac711] {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.kbc .kbc-btns .btn.active[data-v-464ac711] {
  background-color: var(--btn-primary);
}
.kbc .kbc-btns .btn[data-v-464ac711] {
  background-color: #72bbef;
  color: #000;
  color: #000;
  border: 2px solid var(--text-fancy);
  width: calc(50% - 4px);
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 1px;
  border-top: 0;
  border-radius: 0;
  font-weight: 700;
  font-size: 18px;
}
.kbc .kbc-btns .btn-check:checked+.btn[data-v-464ac711] {
  background-color: var(--bg-success);
}
.kbc .kbc-btns .btn img[data-v-464ac711] {
  width: 30px;
}
.kbc .kbc-btns .btn.active[data-v-464ac711] {
  background-color: var(--bg-success);
}
.hfquitbtns[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.kbc .hfquitbtns .btn[data-v-464ac711] {
  opacity: 1;
  width: 130px;
  height: 130px;
  border-radius: 50% !important;
  color: #fff;
}
.kbc .hfquitbtns .hbtn[data-v-464ac711] {
  background-image: linear-gradient(-180deg, #03b37f 0%, #06553e 100%);
  border-width: 5px !important;
  border: 5px solid #116f52;
  margin-right: 20px;
}
.kbc .hfquitbtns .fbtn[data-v-464ac711] {
  background-image: linear-gradient(-180deg, #fc4242 0%, #6f0404 100%);
  border-color: #6f0404 !important;
  border-width: 5px !important;
  border: 5px solid #6f0404;
}
.kbc .hfquitbtns .btn.selected[data-v-464ac711] {
  border-color: var(--text-fancy) !important;
  box-shadow: 0 0 5px #fff !important;
  opacity: 1;
}
.kbcbtesbox[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 5px;
}
.kbcbtesbox .bet-box[data-v-464ac711] {
  background: var(--bg-secondary);
  padding: 5px 10px;
  border-radius: 8px;
  margin-left: 3px;
  margin-right: 3px;
  width: calc(33.33% - 6px);
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  color: var(--text-secondary);
}
.kbcbtesbox .bet-box i[data-v-464ac711] {
  color: var(--text-danger);
  cursor: pointer;
}
.kbcbtesbox[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.kbcbtesbox.casino-place-bet-row[data-v-464ac711] {
  background-color: var(--bg-secondary);
  width: 100%;
  margin: 5px auto;
  border-bottom: 0;
}
.casino-place-bet-row.back-border[data-v-464ac711] {
  border-left: 5px solid #72bbef;
}
.kbcbtesbox .bet-box[data-v-464ac711] {
  margin-left: 3px;
  margin-right: 3px;
  width: calc(33.33% - 6px);
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-secondary);
}
.kbcbtesbox>div[data-v-464ac711] {
  width: calc(33.33% - 6px);
  margin-bottom: 5px;
}
.kbcbtesbox .bet-box span[data-v-464ac711] {
  flex: 1 1;
  text-align: center;
}
.kbcbtesbox.casino-place-bet-row .bet-box[data-v-464ac711] {
  background-color: transparent;
}
.kbcbtesbox.casino-place-bet-row .bet-box[data-v-464ac711] {
  padding: 0;
  min-height: 28px;
}
.kbcbtesbox .hbtn[data-v-464ac711] {
  border: 1px solid #03b37f;
  color: #03b37f;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.kbcbtesbox .fbtn[data-v-464ac711] {
  border: 1px solid #fc4242;
  color: #fc4242;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.kbcbtesbox .fivebtn[data-v-464ac711] {
  border: 1px solid #115dbf;
  color: #115dbf;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.kbc-result-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.kbc-result-box .casino-box-row[data-v-464ac711] {
  width: calc(16.66% - 4px);
  margin-right: 4px;
}
.kbc-result-box .question-name[data-v-464ac711] {
  width: 100%;
  background: var(--bg-secondary);
  border-radius: 12px;
  color: var(--text-secondary);
  padding: 6px;
  border: 1px solid var(--text-fancy);
  text-align: center;
}
.kbc-result-box .answer-name[data-v-464ac711] {
  width: 80%;
  padding: 6px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  border: 3px solid #72bbef;
  border-top: 0;
}
.kbc-result-box .answer-name.true[data-v-464ac711] {
  background-color: #03b37f;
}
.kbc-result-box .answer-name.false[data-v-464ac711] {
  background-color: #fc4242;
}
.kbc-result-box .kbchf[data-v-464ac711] {
  width: 100%;
  text-align: center;
  color: #fff;
}
.kbc-result-box .kbchf .kbc-result-box-half[data-v-464ac711] {
  border: 1px solid #03b37f;
  color: #03b37f;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.kbc-result-box .kbchf .kbc-result-box-full[data-v-464ac711] {
  border: 1px solid #fc4242;
  color: #fc4242;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.kbc-result-box .kbchf .kbc-result-box-five[data-v-464ac711] {
  border: 1px solid #115dbf;
  color: #115dbf;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
}
.kbc .casino-odd-box-container[data-v-464ac711] {
  margin-bottom: 10px;
}
.five-cricket .scorecard[data-v-464ac711] {
  width: 100%;
  margin: 0;
  margin-bottom: 8px;
}
.score-head[data-v-464ac711] {
  background-color: var(--bg-secondary85);
  padding: 10px;
  color: var(--text-secondary);
}
.five-cricket-result h4[data-v-464ac711] {
  background-color: var(--bg-primary90);
  color: var(--text-primary);
  padding: 5px;
}
.five-cricket .market-6[data-v-464ac711] {
  min-width: calc(33.33% - 8px);
}
.five-cricket .market-2[data-v-464ac711] {
  min-width: calc(50% - 8px);
}
.five-cricket-result .table[data-v-464ac711] {
  table-layout: fixed;
}
.five-cricket .flip-card-container[data-v-464ac711] {
  justify-content: flex-start;
}
.five-video-baner[data-v-464ac711] {
  position: relative;
}
.five-video-baner img[data-v-464ac711] {
  height: 100%;
  width: 100%;
}
.animate-text[data-v-464ac711] {
  position: absolute;
  font-size: 22px;
  width: 100%;
  background-color: #343434;
  text-align: left;
  top: 0;
  padding: 15px;
  text-transform: uppercase;
}
.animate-text span[data-v-464ac711] {
  opacity: 0;
  color: var(--text-white);
  font-weight: 700;
  display: inline-block;
  transform: translate(-150px, -50px) rotate(-180deg) scale(3);
  animation: revolveScale-data-v-464ac711 0.4s forwards;
}
.animate-text span[data-v-464ac711]:nth-of-type(2) {
  animation-delay: 0.04s;
}
.animate-text span[data-v-464ac711]:nth-of-type(3) {
  animation-delay: 0.08s;
}
.animate-text span[data-v-464ac711]:nth-of-type(4) {
  animation-delay: 0.12s;
}
.animate-text span[data-v-464ac711]:nth-of-type(5) {
  animation-delay: 0.16s;
}
.animate-text span[data-v-464ac711]:nth-of-type(6) {
  animation-delay: 0.2s;
}
.animate-text span[data-v-464ac711]:nth-of-type(7) {
  animation-delay: 0.24s;
}
.animate-text span[data-v-464ac711]:nth-of-type(8) {
  animation-delay: 0.28s;
}
.animate-text span[data-v-464ac711]:nth-of-type(9) {
  animation-delay: 0.32s;
}
.animate-text span[data-v-464ac711]:nth-of-type(10) {
  animation-delay: 0.36s;
}
.animate-text span[data-v-464ac711]:nth-of-type(11) {
  animation-delay: 0.4s;
}
.animate-text span[data-v-464ac711]:nth-of-type(12) {
  animation-delay: 0.44s;
}
.animate-text span[data-v-464ac711]:nth-of-type(13) {
  animation-delay: 0.48s;
}
.animate-text span[data-v-464ac711]:nth-of-type(14) {
  animation-delay: 0.52s;
}
.animate-text span[data-v-464ac711]:nth-of-type(15) {
  animation-delay: 0.56s;
}
.animate-text span[data-v-464ac711]:nth-of-type(16) {
  animation-delay: 0.6s;
}
.animate-text span[data-v-464ac711]:nth-of-type(17) {
  animation-delay: 0.64s;
}
.animate-text span[data-v-464ac711]:nth-of-type(18) {
  animation-delay: 0.68s;
}
.animate-text span[data-v-464ac711]:nth-of-type(19) {
  animation-delay: 0.72s;
}
.animate-text span[data-v-464ac711]:nth-of-type(20) {
  animation-delay: 0.76s;
}
.animate-text span[data-v-464ac711]:nth-of-type(21) {
  animation-delay: 0.8s;
}
.animate-text span[data-v-464ac711]:nth-of-type(22) {
  animation-delay: 0.84s;
}
.animate-text span[data-v-464ac711]:nth-of-type(23) {
  animation-delay: 0.88s;
}
.animate-text span[data-v-464ac711]:nth-of-type(24) {
  animation-delay: 0.92s;
}
.animate-text span[data-v-464ac711]:nth-of-type(25) {
  animation-delay: 0.96s;
}
.animate-text span[data-v-464ac711]:nth-of-type(26) {
  animation-delay: 1s;
}
@keyframes revolveScale-data-v-464ac711 {
60% {
    transform: translate(20px, 20px) rotate(30deg) scale(0.3);
}
100% {
    transform: translate(0) rotate(0) scale(1);
    opacity: 1;
}
}
.cricketv3 .scorecard[data-v-464ac711] {
  width: 100%;
  margin: 0;
  margin-bottom: 8px;
}
.cricketv3 .video-box-container[data-v-464ac711] {
  margin-left: auto;
  margin-right: auto;
}
.super-over .casino-video-cards img[data-v-464ac711] {
  height: 35px;
  width: auto;
}
.super-over .scorecard[data-v-464ac711] {
  width: 100%;
  margin: 0;
  margin-bottom: 8px;
}
.super-over .market-title[data-v-464ac711] {
  padding: 5px 10px;
  font-size: 15px;
  line-height: 16px;
  text-transform: capitalize;
  font-weight: 600;
}
.super-over-rule td img[data-v-464ac711] {
  height: 30px;
}
.superover .market-title[data-v-464ac711] {
  font-size: 15px;
  line-height: 16px;
}
.cricket5-rules_table[data-v-464ac711] {
  padding: 0 !important;
  padding-top: 5px !important;
}
.cricket5-rules_table .table th[data-v-464ac711],
.cricket5-rules_table .table td[data-v-464ac711] {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.cricket2020 .score-box[data-v-464ac711] {
  position: relative;
  height: 70px;
  margin-bottom: 40px;
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border: 0 !important;
  background-image: linear-gradient(to right,
      var(--bg-primary),
      var(--bg-secondary)) !important;
  color: #fff;
}
.cricket2020 .ball-image[data-v-464ac711] {
  width: 50px;
  position: absolute;
  z-index: 1000;
  top: 40px;
  left: 10px;
}
.cricket2020 .score-img[data-v-464ac711] {
  width: 70%;
}
.cricket2020 .team-score[data-v-464ac711] {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
  height: 50px;
  left: 21%;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cricket2020 .min-max[data-v-464ac711] {
  position: absolute;
  top: 53px;
  right: 5px;
  font-size: 12px;
}
.cricket2020 .backbox[data-v-464ac711] {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  font-weight: 700;
  font-size: 18px;
}
.cricket2020 .laybox[data-v-464ac711] {
  position: absolute;
  top: 0;
  left: 50.1%;
  width: 50%;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.cricket2020 .cricket20oddsrow[data-v-464ac711] {
  position: absolute;
  right: 0;
  width: 30%;
  top: 0;
}
.cricket20odds[data-v-464ac711] {
  width: 100%;
  position: relative;
  right: 0;
  text-align: right;
  margin-left: auto;
  height: 50px;
}
.cricket2020resulticon[data-v-464ac711] {
  width: 40px !important;
  margin: 2px !important;
}
.cricket2020 .suspended[data-v-464ac711] {
  position: relative;
}
.cricket2020 .suspended[data-v-464ac711]:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white-color);
  text-transform: uppercase;
  font-family: "font awesome 5 free";
  content: "\f023";
  font-weight: 900;
  font-size: 18px;
  top: 0;
  width: 30%;
  height: 50px;
  top: 0;
  right: 0;
  border-radius: 0;
}
.cricket20-container[data-v-464ac711] {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.cricket20-left[data-v-464ac711],
.cricket20-right[data-v-464ac711] {
  width: 49%;
  padding: 5px;
  background: var(--bg-table-row);
  box-shadow: 0 0 3px #aaa;
}
.cricket20 .score-box[data-v-464ac711] {
  position: relative;
  height: 64px;
  margin-top: 30px;
  padding: 0;
  background-image: url("https://versionobj.ecoassetsservice.com/v21/static/front/img/score-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 45px;
}
.cricket20 .team-score[data-v-464ac711] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
}
.cricket20 .ball-icon[data-v-464ac711] {
  position: absolute;
  left: 50%;
  top: -25px;
  height: 50px;
  transform: translateX(-50%);
}
.cricket20 .ball-icon img[data-v-464ac711] {
  height: 60px;
}
.cricket20 .blbox[data-v-464ac711] {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  width: 170px;
  height: 40px;
  transform: translateX(-50%);
  bottom: -15px;
}
.cricket20 .blbox div[data-v-464ac711] {
  width: 35%;
  text-align: center;
  color: #000;
  height: 40px;
  line-height: 40px;
  border: 0;
}
.cricket20 .casino-nation-book[data-v-464ac711] {
  position: absolute;
  left: 15px;
  bottom: -20px;
}
.cricket20videobannerbox[data-v-464ac711] {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  padding-bottom: 56.25%;
}
.cricket20videobannerbox img[data-v-464ac711] {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  border: 0;
}
.cricket20videobanner[data-v-464ac711] {
  background-color: rgba(0, 0, 0, 0.6);
  height: 160px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  left: 50%;
  top: 50%;
  width: 50%;
  color: #fff;
  line-height: 1;
  text-align: center;
  font-size: 34px;
  transform: translate(-50%, -50%);
  font-family: Numeric;
  border: 2px solid var(--bg-table-row);
  border-radius: 8px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.cricket20bannertitle[data-v-464ac711] {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--bg-secondary70);
  padding: 20px;
  border-radius: 20px 20px 0 0;
}
.cricket20balls[data-v-464ac711] {
  position: absolute;
  left: 0;
  bottom: -25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.cricket20books[data-v-464ac711] {
  position: absolute;
  right: 5px;
  top: 5px;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
}
.cricket20books>div[data-v-464ac711] {
  display: flex;
  justify-content: space-between;
  color: #fff;
  line-height: 1;
}
.cricket20 .casino-last-results .result[data-v-464ac711] {
  background-color: transparent;
  border: 1px solid 0;
}
.cricket20 .casino-last-results .result img[data-v-464ac711] {
  height: 30px;
}
.cricket20ballpopup[data-v-464ac711] {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -75px;
  z-index: 10;
  animation: zoom-in-zoom-out-data-v-464ac711 1s ease;
  -webkit-animation: zoom-in-zoom-out-data-v-464ac711 1s ease;
  animation-iteration-count: 1;
}
@keyframes zoom-in-zoom-out-data-v-464ac711 {
0% {
    transform: scale(1, 1);
}
50% {
    transform: scale(1.5, 1.5);
}
100% {
    transform: scale(1, 1);
}
}
.cricket20ballpopup img[data-v-464ac711] {
  width: 150px;
}
.lottery .casino-table[data-v-464ac711] {
  background-color: var(--table-border);
}
.lottery .single[data-v-464ac711],
.lottery .double[data-v-464ac711],
.lottery .tripple[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  align-items: flex-start;
  padding: 5px;
}
.lottery-box[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0;
  width: 100%;
}
.lottery-box .lottery-card[data-v-464ac711] {
  width: 10%;
  text-align: center;
  margin: 5px 0;
}
.lottery-box .lottery-card img[data-v-464ac711] {
  width: 55px;
  cursor: pointer;
}
.lottery-place-balls[data-v-464ac711] {
  display: flex;
  padding: 6px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.lottery-place-balls img[data-v-464ac711] {
  width: 50px;
  margin-right: 20px;
  animation-name: ballspin-data-v-464ac711;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.lottery-place-balls img[data-v-464ac711]:last-child {
  margin-right: 0;
}
.lottery .single .lottery-place-balls img[data-v-464ac711] {
  width: 100px;
}
@keyframes ballspin-data-v-464ac711 {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
.lottery .double .lottery-place-balls[data-v-464ac711],
.lottery .tripple .lottery-place-balls[data-v-464ac711] {
  width: auto;
  align-items: flex-end;
  margin: 0 auto;
}
.lottery-btn.active[data-v-464ac711] {
  border: 1px solid var(--bg-primary90);
  background-color: var(--bg-primary65);
  color: var(--text-primary);
}
.random-bets[data-v-464ac711] {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--bg-secondary70);
  color: var(--text-secondary);
  border-radius: 16px;
  padding: 5px;
  margin: 10px auto 0;
}
.random-bets h4[data-v-464ac711] {
  font-size: 16px;
}
.random-bets button[data-v-464ac711] {
  min-width: 50px;
  height: 40px;
  margin-right: 7px;
  margin-bottom: 7px;
  border-radius: 8px;
}
.lottery-buttons[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}
.lottery-buttons .btn[data-v-464ac711] {
  background: var(--bg-info);
  color: #fff;
  width: 30%;
  font-size: 18px;
}
.lottery-result-group[data-v-464ac711] {
  padding: 5px;
  margin-right: 5px;
  background-color: var(--bg-secondary85);
  color: var(--text-secondary);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
  margin-bottom: 5px;
}
.lottery-result-icon[data-v-464ac711] {
  margin-right: 5px;
}
.lottery-result-icon[data-v-464ac711]:last-child {
  margin-right: 0;
}
.lottery-bets[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}
.lottery-my-bet-box[data-v-464ac711] {
  height: 46px;
  width: 32%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--bg-primary65);
  color: var(--text-primary);
  border-radius: 8px;
}
.lottery-rules-box[data-v-464ac711] {
  border: 1px solid var(--text-fancy);
}
.lottery-rules-row[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--text-fancy);
}
.lottery-rules-title-name[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-right: 1px solid var(--text-fancy);
  width: 20%;
  justify-content: center;
  padding: 5px;
}
.lottery-rules-title-name>div[data-v-464ac711]:first-child {
  font-size: 22px;
  font-weight: 700;
  color: var(--text-fancy);
}
.lottery-rules-title-name>div[data-v-464ac711] {
  width: 100%;
}
.lottery-rules-cards[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.lottery-rules-cards .lottery-card[data-v-464ac711] {
  margin-right: 5px;
  text-align: center;
}
.lottery-rules-cards .lottery-card img[data-v-464ac711] {
  width: 40px;
  height: auto;
  max-height: none;
  max-height: initial;
}
.vcasino .casino-video[data-v-464ac711] {
  padding-bottom: 80px;
  /*  background-image: url(../images/table-bg.jpg); */
  background-size: cover;
  background-position: bottom;
}
.vcasino .video-box-container[data-v-464ac711] {
  max-width: 100%;
}
.vcasino .casino-video-box[data-v-464ac711] {
  padding-bottom: 0;
}
.vcasino .casino-video-box .vcasinobg[data-v-464ac711] {
  width: 100%;
  height: auto;
}
.vcasino .casino-video-cards[data-v-464ac711] {
  bottom: 5px;
  top: auto;
  top: initial;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: top;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  z-index: 2;
}
.vcasino .casino-video-cards>div[data-v-464ac711] {
  margin-right: 50px;
  position: relative;
}
.vcasino .casino-video-cards>div[data-v-464ac711]:last-child {
  margin-right: 0;
}
.vcasino .casino-video-cards h5[data-v-464ac711] {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}
.vcasino .casino-video-cards .vcasinocards-playera h5[data-v-464ac711] {
  color: #ff4500;
}
.vcasino .casino-video-cards .vcasinocards-playerb h5[data-v-464ac711] {
  color: #ff3;
}
.vcasino .casino-video-cards .vcasinocards-playerc h5[data-v-464ac711] {
  color: #33c6ff;
}
.vcasino .casino-video-cards .virtual-winner[data-v-464ac711] {
  height: 100px;
  width: auto;
  position: absolute;
  left: -100px;
  top: -10px;
}
.vcasino .casino-video-cards>div:last-child .virtual-winner[data-v-464ac711] {
  right: -100px;
  left: auto;
  left: initial;
}
.vcasino .winner-animation[data-v-464ac711] {
  z-index: 1000;
  animation-name: winnerani-data-v-464ac711;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}
@keyframes winnerani-data-v-464ac711 {
0% {
    transform: scale(1);
}
50% {
    transform: scale(2) perspective(1800px) rotateX(40deg);
    -webkit-transform: scale(2) perspective(1800px) rotateX(40deg);
    -moz-transform: scale(2) perspective(1800px) rotateX(40deg);
    -ms-transform: scale(2) perspective(1800px) rotateX(40deg);
    -o-transform: scale(2) perspective(1800px) rotateX(40deg);
}
100% {
    transform: scale(1);
}
}
.odds-title[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.odds-title>div[data-v-464ac711] {
  width: 40px;
  margin-right: 50px;
  text-align: center;
}
.odds-title>div[data-v-464ac711]:last-child {
  margin-right: 0;
}
.odds-title .black-card[data-v-464ac711] {
  color: #000;
}
.odds-title .red-card[data-v-464ac711] {
  color: #bb0404;
}
.odds-title .card-character[data-v-464ac711] {
  font-family: Card Characters;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 3px;
}
.bollywood.vcasino .casino-video[data-v-464ac711] {
  padding-bottom: 120px;
}
.bollywood.vcasino .casino-video-cards[data-v-464ac711] {
  bottom: 60px;
}
.aaa.vcasino .casino-video[data-v-464ac711] {
  padding-bottom: 100px;
}
.slot-page .casino-tab[data-v-464ac711] {
  width: calc(100% - 110px);
  margin-left: 5px;
  margin-right: 5px;
}
.slot-page .casino-tab[data-v-464ac711] {
  width: 100%;
  margin: 0;
}
.slot-page .casino-tab-list[data-v-464ac711],
.slot-page .casino-sub-tab-list[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0;
  padding: 0 5px;
  justify-content: space-between;
}
.slot-page .casino-sub-tab-list .casino-sub-tab[data-v-464ac711] {
  width: 100%;
  margin: 0;
  background-color: var(--bg-primary65);
}
.slot-page .casino-sub-tab[data-v-464ac711] {
  width: calc(100% - 40px);
  margin: 0;
}
.slot-page .casino-sub-tab-list .tabs-arow.tabs-arow-left+.casino-sub-tab[data-v-464ac711] {
  width: calc(100% - 130px);
}
.slot-page .casino-sub-tab-list .nav-pills .nav-link[data-v-464ac711] {
  background-color: transparent;
  border: 0;
  color: var(--text-primary);
  text-decoration: none;
}
.slot-page .casino-sub-tab-list .nav-pills .nav-link.active[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-weight: 700;
}
.slot-page .casino-tab-list .tabs-arow[data-v-464ac711],
.slot-page .casino-sub-tab-list .tabs-arow[data-v-464ac711] {
  background-image: linear-gradient(to right, #0088cc82, var(--bg-sidebar));
  color: var(--text-sidebar);
  width: 65px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.slot-page .casino-tab-list .tabs-arow.tabs-arow-right[data-v-464ac711],
.slot-page .casino-sub-tab-list .tabs-arow.tabs-arow-right[data-v-464ac711] {
  background-image: linear-gradient(to right, var(--bg-sidebar), #0088cc82);
}
.slot-page .show-close-btn .casino-tab[data-v-464ac711],
.slot-page .show-close-btn .casino-sub-tab[data-v-464ac711] {
  width: calc(100% - 40px);
  margin: 0;
}
.slot-page .tabs-arow.tabs-arow-left+.casino-tab[data-v-464ac711] {
  width: calc(100% - 130px);
}
.slot-page .casino-tab-list.show-close-btn .tabs-arow.tabs-arow-left+.casino-tab[data-v-464ac711] {
  width: calc(100% - 170px);
}
.slot-page.list-page .casino-tab .nav-link[data-v-464ac711] {
  height: 34px;
  background-color: transparent;
  color: var(--text-secondary);
}
.slot-page.list-page .casino-sub-tab .nav-link[data-v-464ac711] {
  height: 34px;
}
.slot-page.list-page .casino-tab .nav-link.active[data-v-464ac711] {
  background-color: var(--bg-primary65);
  color: var(--text-primary);
}
.slot-page .center-container .nav.casino-sub-tab.left-list .nav-item[data-v-464ac711] {
  flex: auto;
  width: 100%;
}
.slot-page .center-container .nav.casino-sub-tab.left-list .nav-item .nav-link[data-v-464ac711] {
  border-right: 0;
  border-bottom: 1px solid var(--bg-secondary);
}
.slot-page .casino-sub-tab-list .tabs-arow[data-v-464ac711] {
  color: var(--text-body);
  width: 65px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.slot-iframe[data-v-464ac711] {
  position: fixed;
  left: 285px;
  top: 100%;
  width: calc(100% - 285px);
  z-index: 10;
}
.slot-iframe iframe[data-v-464ac711] {
  width: 100%;
  height: 100%;
}
.slot-iframe.show[data-v-464ac711] {
  top: 159px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  z-index: 1000;
  height: calc(100vh - 159px);
}
.close-slot-frame[data-v-464ac711] {
  font-size: 18px;
  color: red;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-weight: 700;
  text-decoration: underline;
  background-color: transparent;
}
.secure-auth .nav-tabs[data-v-464ac711] {
  border-bottom: 0;
  width: 70%;
  margin: 0 auto;
}
.secure-auth .nav-tabs .nav-link[data-v-464ac711] {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border: 1px solid #28a745 !important;
  padding: 10px;
}
.secure-auth .nav-tabs .nav-link[data-v-464ac711]::after {
  display: none;
}
.secure-auth .nav-tabs .nav-link.active[data-v-464ac711] {
  background-color: #28a745;
  color: #fff;
}
.secure-auth .verification-code[data-v-464ac711] {
  border: 1px solid #f2f2f2;
  width: auto;
  font-size: 45px;
  line-height: 1;
  color: #585858;
  background: #e4e4e4;
  padding: 10px;
  display: inline-block;
}
.report-page .secure-auth .btn[data-v-464ac711] {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.report-page .secure-auth .btn i[data-v-464ac711] {
  font-size: 46px;
}
.report-page .secure-auth .login-password[data-v-464ac711] {
  display: flex;
  justify-content: center;
}
.report-page .secure-auth .login-password input[data-v-464ac711] {
  width: auto;
}
.report-page .secure-auth .login-password button[data-v-464ac711] {
  display: inline-block;
  margin: 0 10px;
}
.report-page .secure-auth p[data-v-464ac711] {
  margin-bottom: 5px;
}
.follow-instruction[data-v-464ac711] {
  background: #e4e4e4;
  color: #000;
  padding: 5px;
}
.report-page .secure-auth p[data-v-464ac711] {
  margin-bottom: 5px;
}
.report-page .card[data-v-464ac711] {
  margin-bottom: 10px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.follow-instruction[data-v-464ac711] {
  background: #e4e4e4;
  color: #000;
  padding: 5px;
}
.horse-table .bet-nation-name[data-v-464ac711] {
  width: 400px;
  justify-content: flex-start;
}
.horse-time-detail[data-v-464ac711] {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 400px);
}
.horse-time-detail a[data-v-464ac711] {
  display: flex;
}
.horse-time-detail span[data-v-464ac711] {
  background: var(--bg-sidebar);
  color: var(--text-sidebar);
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.horse-table .bet-table-row[data-v-464ac711] {
  padding: 6px 8px 1px;
}
.horse-time-detail span.active[data-v-464ac711] {
  position: relative;
}
.horse-time-detail span.active[data-v-464ac711]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-right: 10px solid transparent;
  border-top: 10px solid green;
}
.horse-table .bet-nation-name .bet-nation-game-name[data-v-464ac711] {
  max-width: calc(100% - 30px);
  padding-left: 10px;
}
.fantasy-desc-container[data-v-464ac711] {
  background: var(--bg-table);
  padding: 5px;
  color: var(--text-table);
  box-shadow: 0 0 5px #cacaca;
}
.fantasy-desc-container .fantasy-detail-title[data-v-464ac711] {
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.fantasy-desc-container .btn[data-v-464ac711] {
  min-width: 150px;
}
.fantasy-desc-container ul[data-v-464ac711] {
  padding: 0 20px;
}
.list-decimal[data-v-464ac711] {
  padding: 0 20px;
}
.list-decimal>li[data-v-464ac711] {
  list-style: decimal;
}
.fantasy-desc-container .list-style[data-v-464ac711],
.fantasy-desc-container .list-style li[data-v-464ac711] {
  list-style: disc;
}
.fantasy-desc-container .list-style li[data-v-464ac711] {
  margin-bottom: 10px;
}
.fantasy-desc-container .cards-box[data-v-464ac711] {
  background: #fff;
  padding: 0 5px;
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
}
.fantasy-desc-container .card-character[data-v-464ac711] {
  font-family: Card Characters;
}
.fantasy-desc-container .red-card[data-v-464ac711] {
  color: red;
}
.fantasy-desc-container .black-card[data-v-464ac711] {
  color: #000;
}
.fantasy-desc-container h6[data-v-464ac711] {
  color: var(--text-primary);
  font-weight: 700;
}
.fantasy-desc-container img[data-v-464ac711] {
  max-width: 100%;
}
.fantasy-iframe[data-v-464ac711] {
  position: fixed;
  left: 285px;
  top: 100%;
  width: calc(100% - 285px);
  z-index: 10;
}
.fantasy-iframe iframe[data-v-464ac711] {
  width: 100%;
  height: 100%;
}
.fantasy-iframe.show[data-v-464ac711] {
  top: 125px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  z-index: 1000;
  height: calc(100vh - 125px);
}
.close-fantasy-frame[data-v-464ac711] {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  background: #ff5757;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #4a0303;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 1001;
}
.login-demo .login-box[data-v-464ac711] {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.disconnected-box[data-v-464ac711] {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 1060;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.disconnected-message[data-v-464ac711] {
  padding: 10px;
  font-size: 12px;
  background-color: var(--bg-table);
  color: var(--text-table);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.disconnected-message i[data-v-464ac711] {
  color: var(--text-fancy);
  font-size: 14px;
}
.disconnected-message b[data-v-464ac711] {
  font-size: 14px;
}
.disconnected-buttons[data-v-464ac711] {
  display: flex;
  justify-content: space-between;
}
.disconnected-buttons .btn[data-v-464ac711] {
  width: 49%;
  padding: 4px;
  border-radius: 8px;
  font-size: 0.7rem;
  color: var(--text-sidebar);
}
.slick-prev[data-v-464ac711]::before,
.slick-next[data-v-464ac711]::before {
  opacity: 1;
}
.center-main-container.full-page[data-v-464ac711] {
  width: 100%;
}
.close-home-modal[data-v-464ac711] {
  position: absolute;
  top: -10px;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 50%;
  background-color: red;
  color: var(--text-white);
  font-size: 24px;
  cursor: pointer;
}
.casino-card[data-v-464ac711] {
  width: 100%;
}
.casino-card .casino_header[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px;
  line-height: 16px;
  display: block;
  width: 100%;
  font-weight: 600;
}
.casino-card .casino_body[data-v-464ac711] {
  padding: 0 10px;
}
.casino-card .casino-card-items[data-v-464ac711] {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: 0 0;
  padding: 0;
  display: block;
  margin-top: 15px;
  overflow: hidden;
}
.casino-card .casino-card-items img[data-v-464ac711] {
  transition: all 0.5s linear;
}
.casino-card .casino-card-items:hover img[data-v-464ac711] {
  transform: scale3d(1.1, 1.1, 1);
  transition: all 0.5s linear;
}
.lucky7a .casino-video-cards img[data-v-464ac711] {
  height: auto;
  width: 35px;
}
.lucky7a .flip-card-inner[data-v-464ac711] {
  text-align: left;
}
.lucky7a .casino-odds .card-icon img[data-v-464ac711] {
  height: 16px;
  margin-right: 3px;
}
.lucky7a .card-odd-box img[data-v-464ac711] {
  height: auto;
  width: 44px;
}
.lucky7a .lucky7acards[data-v-464ac711] {
  justify-content: center;
}
.lucky7a .card-odd-box[data-v-464ac711] {
  margin-bottom: 2px;
  margin-right: 12px;
  margin-top: 10px;
}
.lucky7a .casino-table-full-box .lucky7 img[data-v-464ac711] {
  width: 45px;
  height: auto;
}
.casino-table .min_max_odds[data-v-464ac711] {
  text-align: right;
  display: flex;
  justify-content: end;
  width: 100%;
}
.casino-table .min_max_odds .min_max_span[data-v-464ac711] {
  display: inline-flex;
  margin-left: 10px;
}
.casino_odds_row[data-v-464ac711] {
  width: 40%;
  display: flex;
  position: relative;
}
.casino_odds_row .casino-odds-box[data-v-464ac711] {
  width: 50% !important;
}
.cards32b .casino-table-header[data-v-464ac711] {
  background-color: #fff;
}
.cards32b .casino-table-left-box[data-v-464ac711] {
  margin-bottom: 15px;
  border: 1px solid #c7c8ca;
}
.cards32b .casino-table-header .casino-odds-box[data-v-464ac711] {
  height: 32px !important;
}
.cards32b .casino-table-header[data-v-464ac711] {
  height: 31px !important;
}
.cards32b .min_max_collapse[data-v-464ac711] {
  position: relative;
  width: 100%;
}
.cards32b .card32numbers .min_max_collapse[data-v-464ac711] {
  position: relative;
  width: auto;
}
.cards32b .card32numbers .min_max_collapse .range-collpase[data-v-464ac711] {
  left: inherit;
  right: 0;
  width: max-content;
}
.teenpattiopen .flip-card-container[data-v-464ac711] {
  flex-wrap: initial;
}
.match-details-rules[data-v-464ac711] {
  max-width: 90%;
}
.match-details-rules .card-header[data-v-464ac711] {
  border-radius: 0;
  padding: 4px 10px;
  font-size: 22px;
}
.match-details-rules .table tbody tr[data-v-464ac711] {
  background-color: transparent;
}
.match-details-rules .table[data-v-464ac711] {
  background-color: transparent;
}
.modal .match-details-rules .table td[data-v-464ac711],
.modal .match-details-rules .table th[data-v-464ac711] {
  padding: 8px;
}
.rules-table-design[data-v-464ac711] {
  padding: 0 15px;
  display: block;
}
.fancy-market-design .market-odd-box[data-v-464ac711] {
  width: 52px;
}
.fancy-market-design .fancy-min-max-box[data-v-464ac711] {
  width: 125px;
}
.fancy-market-design .market-nation-detail[data-v-464ac711] {
  width: calc(100% - 229px);
  display: inline-block;
}
.market-6.fancy-market-design .suspended-row[data-v-464ac711]::after,
.market-6.fancy-market-design .suspended-table[data-v-464ac711]::after {
  width: 228px;
}
.fancy-tab[data-v-464ac711] {
  margin-bottom: 10px;
}
.fancy-tab a[data-v-464ac711] {
  background-color: var(--bg-primary);
  color: #fff;
  padding: 8px 15px;
  box-shadow: 0 8px 8px -7px;
}
.fancy-tab a.active[data-v-464ac711] {
  background-color: var(--bg-secondary);
}
.desktop-rules .nav[data-v-464ac711] {
  min-height: 85vh;
  background-color: var(--bg-primary);
  height: 100%;
}
.desktop-rules .nav button.nav-link[data-v-464ac711] {
  width: 250px;
  text-align: left;
  border: none;
  border-bottom: 1px solid #fff;
  background-color: var(--bg-primary);
  color: #fff;
}
.desktop-rules .nav button.nav-link[data-v-464ac711]:hover {
  text-decoration: underline;
}
.desktop-rules .tab-content[data-v-464ac711] {
  width: 100%;
}
.Rulespage .table tbody tr[data-v-464ac711] {
  background-color: transparent;
}
.Rulespage .table[data-v-464ac711] {
  background-color: transparent;
  margin-top: 8px;
}
.Rulespage .rules-content[data-v-464ac711] {
  width: 100%;
  padding: 0 10px;
}
.Rulespage .table td[data-v-464ac711],
.Rulespage .table th[data-v-464ac711] {
  padding: 8px;
}
.Rulespage .Rulespage-head-title[data-v-464ac711] {
  background-color: var(--bg-secondary);
  color: #fff;
  font-size: 15px;
  padding: 12px;
  line-height: 16px;
  display: block;
  width: 100%;
  margin-top: 6px;
}
.search-game[data-v-464ac711] {
  background: #fff;
  color: #000;
  padding: 10px;
  width: 500px;
  max-height: 450px;
  overflow: auto;
  box-shadow: 1px 0 10px #000;
  position: absolute;
  top: 100%;
  z-index: 999;
}
.search-items[data-v-464ac711] {
  border-bottom: 1px solid #ccc;
  padding: 4px 0;
  margin-bottom: 4px;
  line-height: 16px;
}
.Result32cardA .casino-result-modal .casino-result-cards img[data-v-464ac711] {
  height: 62px;
  width: auto;
  margin-right: 5px;
}
.Result32cardA h4.result-title[data-v-464ac711] {
  margin: 11px 0;
}
.result-modal .modal-body[data-v-464ac711] {
  padding: 15px;
}
.result-modal .casino-result-round-id[data-v-464ac711] {
  font-size: 18px;
}
.Lucky7Result .casino-result-modal .casino-result-cards img[data-v-464ac711] {
  height: 62px;
}
.DragonTiger .casino-result-modal .casino-result-cards img[data-v-464ac711] {
  margin-right: 10px;
}
.dragontiger-box[data-v-464ac711] {
  margin-top: 15px;
}
.DragonTiger .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 50px;
  height: auto;
}
.Queenresult .result-item .casino-result-cards[data-v-464ac711] {
  margin-bottom: 15px;
}
.Queenresult .result-item .result-title[data-v-464ac711] {
  font-size: 24px;
}
.Queenresult .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 50px;
  height: auto;
}
.br1[data-v-464ac711] {
  border-right: 1px solid #bfbfbf;
}
.img_transform[data-v-464ac711] {
  transform: rotate(270deg);
}
.Baccarat2result .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 50px;
  height: auto;
}
.Baccarat2result .result-item .result-title[data-v-464ac711] {
  font-size: 24px;
  margin-bottom: 5px;
}
.Open_teenpatti_result .result-item .result-title[data-v-464ac711] {
  margin-bottom: 8px;
}
.casino_meter .casino-result-modal .casino-result-cards[data-v-464ac711] {
  justify-content: flex-start;
  margin-top: 0;
}
.casino_meter .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 35px;
  height: auto;
}
.casino_meter .result-item .result-title[data-v-464ac711] {
  font-size: 16px;
  padding-left: 13px;
  text-align: left;
}
.dt2020 .result-item .result-title[data-v-464ac711] {
  font-size: 16px;
  margin-bottom: 0;
}
.baccarat2game .baccarat-other-odd-box-container[data-v-464ac711] {
  width: 18%;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
  font-size: 11px;
  padding: 4px 9px;
}
.Warresult .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 40px;
  height: auto;
}
.Warresult .result-item .result-title[data-v-464ac711] {
  font-size: 16px;
}
.Warresult .result-item[data-v-464ac711] {
  margin-top: 10px;
}
.bord_center-text[data-v-464ac711] {
  text-align: center;
  font-size: 24px;
  margin-top: 15px;
}
.text-rad[data-v-464ac711] {
  color: #dc3545;
}
.text-yellow[data-v-464ac711] {
  color: #ffc107;
}
.ander_bahar .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 40px;
  height: auto;
}
.ander_bahar .result-item .result-title[data-v-464ac711] {
  font-size: 24px;
  padding-left: 13px;
}
.ander_bahar .casino-result-cardimg[data-v-464ac711] {
  display: block;
  width: 100%;
  text-align: center;
  padding: 0 10px;
}
.ander_bahar .owl-carousel .owl-stage[data-v-464ac711] {
  text-align: center;
  margin: 0 auto;
}
.casino-result-cardimg[data-v-464ac711] {
  position: relative;
}
.owl-prev[data-v-464ac711] {
  position: absolute;
  top: 0;
  left: -20px;
}
.owl-next[data-v-464ac711] {
  position: absolute;
  top: 0;
  right: -20px;
}
.owl-prev span[data-v-464ac711] {
  font-size: 30px;
}
.owl-next span[data-v-464ac711] {
  font-size: 30px;
}
.Winner_green_bg[data-v-464ac711] {
  padding: 0 25px;
  background: #28a745;
  display: inline-block;
  color: #fff;
  margin-top: 20px;
}
.JudgementResult .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: 50px;
  height: auto;
}
.JudgementResult .result-item .result-title[data-v-464ac711] {
  font-size: 16px;
}
.JudgementResult .result-item[data-v-464ac711] {
  margin-top: 10px;
}
.andar_bahar_result .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
  width: auto !important;
  height: 40px;
}
.andar_bahar_result .casino-result-cards img[data-v-464ac711] {
  margin-right: 0 !important;
}
.andar_bahar_result .casino-result-cardimg[data-v-464ac711] {
  display: block;
  width: 100%;
  text-align: center;
  padding: 0 10px;
}
.andar_bahar_result .owl-carousel .owl-stage[data-v-464ac711] {
  text-align: center;
  margin: 0 auto;
}
.owl-item[data-v-464ac711] {
  margin-right: 7px !important;
}
.sidebar-box-rules .my-bets tr[data-v-464ac711] {
  background-color: transparent;
}
.sidebar-box-rules .my-bets[data-v-464ac711] {
  border: 1px solid #c7c8ca;
  padding: 6px;
}
.sidebar-box-rules table[data-v-464ac711] {
  background-color: transparent;
}
.sidebar-box-rules .table th[data-v-464ac711],
.sidebar-box-rules .table td[data-v-464ac711] {
  padding: 4px 8px;
  font-size: 16px;
}
.fa-spin[data-v-464ac711] {
  animation: a-data-v-464ac711 2s infinite linear;
}
.load-inner[data-v-464ac711] {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.load-inner i[data-v-464ac711] {
  margin-top: 40px;
  font-size: 24px;
  color: #fff;
}
.load-inner .icon-loader svg.icon[data-v-464ac711] {
  width: 24px;
  height: 24px;
  fill: var(--text-primary);
  margin-top: 40px;
}
.login-form .btn-block .f-spin[data-v-464ac711] {
  display: none;
  animation: a-data-v-464ac711 2s infinite linear;
}
.login-form .login-btn-loader[data-v-464ac711] {
  position: relative;
  opacity: 0.7;
}
.login-form .login-btn-loader svg.icon.f-spin[data-v-464ac711] {
  position: absolute;
  right: 8px;
  top: 9px;
  display: block;
}
.login-form .login-btn-loader .f-sign[data-v-464ac711] {
  display: none;
  animation: a-data-v-464ac711 2s infinite linear;
}
@keyframes a-data-v-464ac711 {
0% {
    transform: rotate(0deg);
}
to {
    transform: rotate(1turn);
}
}
.fancy-monile-ddesign .min_max_inplay[data-v-464ac711] {
  position: relative;
}
.fancy-monile-ddesign .min_max_inplay a.minmax-btn[data-v-464ac711] {
  font-size: 13px;
  color: var(--bg-secondary);
}
.fancy-monile-ddesign .min_max_inplay .range-collpase[data-v-464ac711] {
  background: #fff;
  padding: 2px 7px;
  position: absolute;
  color: #242424;
  right: 0;
  z-index: 1000;
  font-size: 14px;
  bottom: -6px;
}
.sideviewmore[data-v-464ac711] {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 4px;
  color: #ffff;
}
.sideviewmore[data-v-464ac711]:hover {
  text-decoration: underline;
  color: #ffff;
}
.placebetloader[data-v-464ac711] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  font-size: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-header[data-v-464ac711] {
  background-color: #08c;
  color: white;
}
.placebetloadermobile[data-v-464ac711] {
  background: rgba(0, 0, 0, 0.5);
  pointer-events: fill;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2222;
}
.baccrat-graph-header[data-v-464ac711] {
  background-color: var(--bg-secondary70);
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
  font-style: italic;
}
.baccrat-graph-header span[data-v-464ac711] {
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
}
.baccrat-graph-header i[data-v-464ac711] {
  font-size: 14px;
  margin: 0 5px;
}
.blinking span[data-v-464ac711] {
  animation: blinkingText-data-v-464ac711 0.8s infinite;
}
.blinking:hover span[data-v-464ac711] {
  animation: blinkingHoverText-data-v-464ac711 0.8s infinite;
}
@keyframes blinkingText-data-v-464ac711 {
0% {
    color: var(--red-color);
}
20% {
    color: var(--red-color);
}
40% {
    color: var(--yellow-color);
}
60% {
    color: var(--yellow-color);
}
80% {
    color: var(--white-color);
}
100% {
    color: var(--white-color);
}
}
@keyframes blinkingHoverText-data-v-464ac711 {
0% {
    color: var(--red-color);
}
20% {
    color: var(--red-color);
}
40% {
    color: var(--yellow-color);
}
60% {
    color: var(--yellow-color);
}
80% {
    color: var(--white-color);
}
100% {
    color: var(--white-color);
}
}
@keyframes blinkingHoverWhite-data-v-464ac711 {
0% {
    color: var(--red-color);
}
20% {
    color: var(--red-color);
}
40% {
    color: var(--yellow-color);
}
60% {
    color: var(--yellow-color);
}
80% {
    color: var(--white-color);
}
100% {
    color: var(--white-color);
}
}
.header-top .dropdown .dropdown-item .form-check-input[data-v-464ac711] {
  border-radius: 0 !important;
  height: 20px;
  width: 20px;
  background-size: 16px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-color: #ccc;
}
.header-top .dropdown .dropdown-item .form-check-input[data-v-464ac711]:focus,
.header-top .dropdown .dropdown-item .form-check-input[data-v-464ac711]:focus-visible {
  box-shadow: none;
}
.header-top .dropdown .dropdown-item .form-check-input[data-v-464ac711]:checked {
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}
.daimo_Payment[data-v-464ac711] {
  padding: 0;
}
.daimo_Payment .nav-pills .nav-link[data-v-464ac711] {
  background-color: #eee;
  padding: 5px 20px;
  margin-right: 10px;
  height: 43px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: none;
}
.daimo_Payment .nav-pills .nav-link.active[data-v-464ac711],
.nav-pills .show>.nav-link[data-v-464ac711] {
  color: #fff;
  background-color: var(--bg-primary);
}
.payment_type_tabs .nav-link img[data-v-464ac711] {
  width: 34px;
  margin-right: 12px;
}
.payment_type_tabs[data-v-464ac711] {
  margin-top: 18px;
}
.bankdetails[data-v-464ac711] {
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
  margin-top: 15px;
  min-height: 93%;
}
.bankdetails table[data-v-464ac711] {
  border-collapse: collapse;
  width: 100%;
}
.bankdetails table td[data-v-464ac711] {
  padding: 4px 0;
}
.bankdetails table td i[data-v-464ac711] {
  margin-left: 5px;
}
.payment_QR_Code[data-v-464ac711] {
  display: block;
  text-align: left;
  margin-top: 15px;
}
.payment_QR_Code img[data-v-464ac711] {
  width: 117px;
}
@media only screen and (max-width: 1600px) {
.home-page .casino-list-item[data-v-464ac711] {
    width: calc(12% - 10px);
}
.casino-list-item-banner[data-v-464ac711] {
    width: 130px;
    position: relative;
}
.fancy-market-design .fancy-min-max-box[data-v-464ac711] {
    width: 90px;
}
.market-6 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 196px);
}
.market-6.fancy-market-design .suspended-row[data-v-464ac711]::after,
  .market-6.fancy-market-design .suspended-table[data-v-464ac711]::after {
    width: 195px;
}
.baccarat-odds-container[data-v-464ac711] {
    width: 67%;
}
app-pie-chart[data-v-464ac711] {
    width: 141px;
    transform: scale(0.9);
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right[data-v-464ac711] {
    right: 24px !important;
}
#chart path[data-v-464ac711] {
    stroke: none !important;
}
}
@media only screen and (max-width: 1440px) {
.casino-list-item-banner[data-v-464ac711] {
    width: 130px;
    position: relative;
}
.home-page .casino-list-item[data-v-464ac711] {
    width: calc(13% - 10px);
}
.game-market[data-v-464ac711] {
    margin-bottom: 3px !important;
}
.fancy-market .market-nation-detail .market-nation-name[data-v-464ac711] {
    max-width: calc(100% - 5px);
}
.place-bet-box .place-bet-box-body .place-bet-stake[data-v-464ac711] {
    margin-left: 14px;
}
}
@media only screen and (max-width: 1400px) {
.fancy-market-design .fancy-min-max-box[data-v-464ac711] {
    width: 70px;
}
.market-6 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 174px);
}
.market-6.fancy-market-design .suspended-row[data-v-464ac711]::after,
  .market-6.fancy-market-design .suspended-table[data-v-464ac711]::after {
    width: 174px;
}
.home-page .casino-list-item[data-v-464ac711] {
    width: calc(13.4% - 10px);
}
}
@media only screen and (max-width: 1366px) {
.home-page .casino-list-item[data-v-464ac711] {
    width: calc(13.8% - 10px);
}
app-pie-chart[data-v-464ac711] {
    width: 141px;
    transform: scale(0.75);
}
}
@media only screen and (max-width: 1199px) {
.home-page .center-container .nav.sports-tab[data-v-464ac711] {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    white-space: nowrap;
    overflow: auto !important;
}
.game-market[data-v-464ac711] {
    margin-bottom: 3px !important;
}
.fancy-tab[data-v-464ac711] {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: var(--bg-secondary);
}
.fancy-tab a[data-v-464ac711] {
    flex: 1 1 auto;
    text-align: center;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    font-weight: 600;
}
.casino-list-item-banner[data-v-464ac711] {
    width: 100%;
    position: relative;
}
.matchbettable .table[data-v-464ac711] {
    background-color: transparent !important;
}
.matchbettable .table th[data-v-464ac711],
  .matchbettable .table td[data-v-464ac711] {
    padding: 2px 8px;
    vertical-align: middle;
    border: 1px solid #aaa;
}
.matchbettable .table td[data-v-464ac711],
  .matchbettable .table tr[data-v-464ac711] {
    background-color: transparent !important;
}
}
@media only screen and (max-width: 991px) {
app-pie-chart[data-v-464ac711] {
    width: 100%;
    transform: scale(1);
}
.login-page .logo-login[data-v-464ac711] {
    margin-top: 0;
}
.login-page .logo-login img[data-v-464ac711] {
    max-height: 65px !important;
    max-width: 100%;
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-464ac711] {
    margin-bottom: 6px;
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-464ac711]:first-child {
    width: 100%;
}
.Poker_oneday .casino-table-box .casino-table-left-box[data-v-464ac711]:last-child {
    width: 100%;
}
.Poker_oneday .casino-nation-detail[data-v-464ac711] {
    width: 40% !important;
}
.Poker_oneday .casino-table-row .casino-odds-box[data-v-464ac711] {
    width: 50% !important;
}
.Poker_oneday .casino_odds_row[data-v-464ac711] {
    width: 60% !important;
}
.Poker_oneday .market-title span[data-v-464ac711] {
    text-transform: capitalize;
}
.Poker_oneday .casino-odds-box[data-v-464ac711] {
    width: 30% !important;
}
.card32numbers .casino-odds[data-v-464ac711] {
    font-size: 55px;
    height: 48px;
    line-height: 52px;
}
}
@media only screen and (max-width: 991px) {
body[data-v-464ac711] {
    font-size: 12px;
}
p[data-v-464ac711] {
    font-size: 12px;
}
h1[data-v-464ac711],
  .h1[data-v-464ac711] {
    font-size: 22px;
}
h2[data-v-464ac711],
  .h2[data-v-464ac711] {
    font-size: 20px;
}
h3[data-v-464ac711],
  .h3[data-v-464ac711] {
    font-size: 18px;
}
h4[data-v-464ac711],
  .h4[data-v-464ac711] {
    font-size: 16px;
}
h5[data-v-464ac711],
  .h5[data-v-464ac711] {
    font-size: 14px;
}
h6[data-v-464ac711],
  .h6[data-v-464ac711] {
    font-size: 12px;
}
.detail-page .center-container[data-v-464ac711] {
    width: 100%;
}
a .game-header[data-v-464ac711] {
    font-size: 12px;
    margin: 0;
    width: 100%;
}
.market-title[data-v-464ac711] {
    font-size: 15px;
    padding: 5px 6px;
    height: 32px;
}
.market-nation-detail[data-v-464ac711] {
    font-size: 13px;
}
.market-odd-box[data-v-464ac711] {
    width: 20%;
    padding: 2px 0;
}
.market-nation-book .market-book[data-v-464ac711] {
    font-size: 12px;
}
.fancy-min-max-box[data-v-464ac711] {
    width: 20%;
    padding: 0 2px;
    display: none;
}
.fancy-min-max[data-v-464ac711] {
    font-size: 10px;
}
.market-odd-box .market-odd[data-v-464ac711] {
    font-size: 15px;
}
.market-odd-box .market-volume[data-v-464ac711] {
    font-size: 11px;
}
.market-nation-detail[data-v-464ac711] {
    width: calc(100% - 210px);
}
.market-1[data-v-464ac711],
  .market-2[data-v-464ac711],
  .market-2.width30[data-v-464ac711],
  .market-3[data-v-464ac711],
  .market-4[data-v-464ac711],
  .market-4.width70[data-v-464ac711],
  .market-5[data-v-464ac711],
  .market-6[data-v-464ac711],
  .market-9[data-v-464ac711],
  .market-10[data-v-464ac711],
  .market-11[data-v-464ac711],
  .market-12[data-v-464ac711] {
    min-width: 100%;
    margin: 0;
}
.market-1 .market-row .market-1-item[data-v-464ac711] {
    min-width: 50%;
}
.market-1 .market-odd-box[data-v-464ac711] {
    width: 30%;
}
.market-2 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 40%);
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 40%;
}
.market-2.width30 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 40%);
}
.market-3 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 60%);
}
.market-4 .market-header .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 48%);
}
.market-3 .market-odd-box[data-v-464ac711] {
    width: 15%;
}
.market-3 .suspended-row[data-v-464ac711]::after,
  .market-3 .suspended-table[data-v-464ac711]::after {
    width: 60%;
}
.market-4 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 72%);
}
.market-4 .market-odd-box[data-v-464ac711] {
    width: 12%;
}
.market-4 .suspended-row[data-v-464ac711]::after,
  .market-4 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.market-5 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 40%);
}
.casino-page-container .ball-runs[data-v-464ac711] {
    height: 19px;
    line-height: 19px;
    width: 19px;
}
.scorecard-row-box[data-v-464ac711] {
    width: 98%;
}
.market-5 .suspended-row[data-v-464ac711]::after,
  .market-5 .suspended-table[data-v-464ac711]::after {
    width: 40%;
    right: 0;
}
.market-6 .row.row10[data-v-464ac711] {
    margin-left: 0;
    margin-right: 0;
}
.market-6 .row.row10>[class*="col-"][data-v-464ac711],
  .market-6 .row.row10>[class*="col"][data-v-464ac711] {
    padding-left: 0;
    padding-right: 1px;
}
.market-6 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 40%);
}
.market-6 .market-nation-detail .market-nation-name[data-v-464ac711] {
    max-width: calc(100% - 30px);
}
.market-6 .market-nation-detail .dropdown i[data-v-464ac711] {
    color: var(--text-table);
    cursor: pointer;
}
.market-6 .market-nation-detail .dropdown i[data-v-464ac711]:after {
    display: none;
}
.market-6 .market-nation-detail .dropdown .fancy-min-max[data-v-464ac711] {
    text-align: center;
}
.market-6 .suspended-row[data-v-464ac711]::after,
  .market-6 .suspended-table[data-v-464ac711]::after {
    width: 40%;
}
.market-9 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 20%);
}
.market-9 .suspended-row[data-v-464ac711]::after,
  .market-9 .suspended-table[data-v-464ac711]::after {
    width: 20%;
}
.market-10 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 20%);
}
.market-10 .market-nation-detail .market-nation-name[data-v-464ac711] {
    max-width: calc(100% - 30px);
}
.market-10 .market-nation-detail .dropdown i[data-v-464ac711] {
    color: var(--text-table);
    cursor: pointer;
}
.market-header .market-odd-box[data-v-464ac711] {
    min-height: 0;
    min-height: initial;
    font-size: 12px;
    text-transform: uppercase;
}
.fancy-monile-ddesign .market-header .market-odd-box[data-v-464ac711] {
    height: 32px;
}
.market-10 .market-nation-detail .dropdown i[data-v-464ac711]:after {
    display: none;
}
.market-10 .suspended-row[data-v-464ac711]::after,
  .market-10 .suspended-table[data-v-464ac711]::after {
    width: 20%;
}
.market-11 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 40%);
}
.market-11 .suspended-row[data-v-464ac711]::after {
    width: 40%;
}
.market-12 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 40%);
}
.market-12 .jockey-detail[data-v-464ac711] {
    margin: 5px 0;
    width: 100%;
    justify-content: flex-start;
    padding: 0 5px;
}
.market-12 .suspended-row[data-v-464ac711]::after,
  .market-12 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.casino-list-item[data-v-464ac711] {
    width: calc(33.33% - 10px);
}
.casino-list-item.rect[data-v-464ac711] {
    width: calc(50% - 10px);
}
.suspended-row[data-v-464ac711]::after,
  .suspended-table[data-v-464ac711]::after {
    font-weight: 600;
    font-family: roboto condensed, sans-serif;
    font-size: 16px;
    color: #ff3c3c;
}
.market-header[data-v-464ac711],
  .market-row[data-v-464ac711] {
    border-bottom: 1px solid #8f8f8f;
}
.market-odd-box[data-v-464ac711] {
    border-left: 1px solid #8f8f8f;
}
.market-header .market-odd-box.lay[data-v-464ac711] {
    border-right: 1px solid #8f8f8f;
}
.Market-odd-row-box[data-v-464ac711] {
    display: flex;
    width: 40%;
}
.market-4 .Market-odd-row-box[data-v-464ac711] {
    display: flex;
    width: 72%;
}
.Market-odd-row-box .market-odd-box[data-v-464ac711] {
    width: 50%;
}
.market-11 .suspended-row[data-v-464ac711]::after,
  .market-11 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.poker-20 .casino-nation-name[data-v-464ac711] {
    font-weight: 600;
}
.poker-20 .casino-table-header .casino-odds-box[data-v-464ac711] {
    font-size: 14px !important;
    padding: 0 !important;
}
.poker-20 .casino-video-cards[data-v-464ac711] {
    width: 160px;
}
.casino_list_title[data-v-464ac711] {
    line-height: 14px;
    min-height: 21px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 3px;
}
.video-box-container[data-v-464ac711] {
    max-width: 100%;
}
.casino-table-left-box[data-v-464ac711],
  .casino-table-right-box[data-v-464ac711] {
    width: 100%;
    padding: 0;
}
.casino-table-header[data-v-464ac711],
  .casino-table-row[data-v-464ac711] {
    border-bottom: 1px solid #aaa;
    background-color: #fff;
}
.cards32b .casino-table-left-box[data-v-464ac711] {
    margin-bottom: 7px;
}
.casino-table-header .casino-odds-box[data-v-464ac711] {
    text-transform: uppercase;
    height: 25px !important;
}
.casino-nation-name p[data-v-464ac711] {
    line-height: 15px;
}
.cards32a .casino_odds_row .casino-odds-box[data-v-464ac711] {
    padding: 3px !important;
}
.cards32a .casino-odds-box p[data-v-464ac711] {
    line-height: 16px;
    font-weight: 500;
}
.card32numbers .casino-odds-box[data-v-464ac711] {
    border-color: #aaa !important;
}
.casino-video-cards img[data-v-464ac711] {
    width: 23px;
}
.casino-title-header-mobile[data-v-464ac711] {
    background-color: var(--bg-primary) !important;
    color: var(--text-primary);
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.game-header[data-v-464ac711] {
    font-size: 12px;
    width: 100%;
    margin: 0;
}
.casino-header .casino-name[data-v-464ac711] {
    font-size: 12px;
}
.casino-odds-box[data-v-464ac711] {
    border-left: 1px solid #aaa;
}
.clock .flip-card[data-v-464ac711] {
    height: 20px !important;
}
.clock .flip[data-v-464ac711] {
    position: relative;
    height: 40px;
    width: 30px;
}
.clock .content[data-v-464ac711] {
    height: 40px;
}
.clock .flip-card-front[data-v-464ac711],
  .clock .up[data-v-464ac711] {
    width: 30px;
    height: 20px;
}
.clock .flip-card-back[data-v-464ac711],
  .clock .down[data-v-464ac711] {
    width: 30px;
    height: 20px;
}
.clock .down>div[data-v-464ac711],
  .clock .up>div[data-v-464ac711] {
    font-size: 40px;
    font-weight: 800;
    line-height: 40px;
    align-self: center;
}
.clock .down>div>div[data-v-464ac711] {
    margin-top: -19px;
}
.clock .content[data-v-464ac711] {
    height: 50px;
    margin-left: 29px;
}
.clock .count-no[data-v-464ac711]:first-child {
    margin-right: 24px !important;
}
.clock .count-no[data-v-464ac711]:last-child {
    margin-right: 58px !important;
}
.main-container .casino-title-header-mobile .nav-tabs[data-v-464ac711] {
    box-shadow: none;
    box-shadow: initial;
}
.casino-title-header-mobile .casino-rid[data-v-464ac711] {
    font-size: 12px;
}
.main-container .casino-title-header-mobile .nav-tabs[data-v-464ac711] {
    width: auto;
}
.teenpatti2 .teen2other .casino-odds-box[data-v-464ac711] {
    width: 49%;
    margin-bottom: 10px;
}
.teenpatti2 .casino-table-right-box[data-v-464ac711] {
    margin-top: 10px;
}
.card-odd-box img[data-v-464ac711] {
    height: 45px;
}
.dt20 .dt20-odd-box[data-v-464ac711] {
    margin-right: 6px;
}
.dt20 .dt20dragon[data-v-464ac711],
  .dt20 .dt20tiger[data-v-464ac711],
  .dt20 .dt20pair[data-v-464ac711] {
    width: calc(29% - 7px);
}
.dt20 .dt20tie[data-v-464ac711] {
    width: calc(13% - 4px);
}
.dtl20 .casino-table-left-box[data-v-464ac711],
  .dtl20 .casino-table-right-box[data-v-464ac711] {
    width: 49%;
}
.dtl20 .casino-nation-detail[data-v-464ac711] {
    width: 60%;
}
.dtl20 .casino-odds-box[data-v-464ac711] {
    width: 40%;
}
.casino-video-title>div[data-v-464ac711]:first-child {
    padding: 5px;
}
.casino-video-title[data-v-464ac711] {
    width: calc(100% - 10px);
    left: 5px;
    top: 5px;
}
.casino-video-title .rules-icon[data-v-464ac711] {
    top: 0;
    font-size: 24px;
}
.casino-video-title .casino-name[data-v-464ac711] {
    font-size: 12px;
    line-height: 1;
}
.casino-video-title .casino-video-rid[data-v-464ac711] {
    font-size: 12px;
    line-height: 1;
}
.casino-video-cards h5[data-v-464ac711] {
    font-size: 12px;
    line-height: 1;
}
.sidebar-box-rules table[data-v-464ac711] {
    background-color: transparent;
}
.clock[data-v-464ac711] {
    right: 5px !important;
    bottom: -12px !important;
}
.vcasino.bollywood .flip-card-container[data-v-464ac711],
  .vcasino.aaa .flip-card-container[data-v-464ac711] {
    height: 20px;
    width: 16px;
}
.teenpatti1day .casino-odds-box[data-v-464ac711] {
    width: 20%;
    font-size: 14px !important;
}
.casino-table .min_max_odds[data-v-464ac711] {
    font-size: 13px;
}
.teenpattiopen .casino-table-header .casino-nation-detail[data-v-464ac711] {
    width: 24%;
}
.teenpattiopen .casino-odds-box[data-v-464ac711] {
    width: 38%;
    text-align: center;
    padding: 1px !important;
}
.teenpattiopen .flip-card[data-v-464ac711] {
    margin-right: 10px;
}
.teenpattiopen .casino-nation-name[data-v-464ac711] {
    font-size: 11px;
}
.teenpattiopen .patern-name[data-v-464ac711] {
    width: auto;
    margin: 0;
}
.teenpattiopen .patern-name .card-icon img[data-v-464ac711] {
    width: 10px;
    margin-left: 1px;
}
.teenpattiopen .patern-name .card-icon[data-v-464ac711] {
    padding: 2px 1px;
    font-size: 12px;
}
.flip-clock-wrapper ul[data-v-464ac711] {
    width: 24px !important;
    height: 34px !important;
    line-height: 29px !important;
    font-size: 22px !important;
    margin: 2px !important;
    border-radius: 2px !important;
}
.flip-clock-wrapper ul li a div div.inn[data-v-464ac711] {
    font-size: 24px !important;
}
.flip-clock-wrapper ul li[data-v-464ac711] {
    line-height: 34px !important;
}
.newcasino.queen .casino-video-cards[data-v-464ac711] {
    bottom: 0;
    top: auto;
    top: initial;
    transform: none;
    transform: initial;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
}
.newcasino.queen .flip-card[data-v-464ac711] {
    height: 15px;
    width: 12px;
}
.vcasino.bollywood .flip-card-container[data-v-464ac711],
  .vcasino.aaa .flip-card-container[data-v-464ac711] {
    height: 15px;
    width: 12px;
}
.queen .casino-table-box[data-v-464ac711] {
    padding: 5px;
}
.queen .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 10px);
}
.queen .casino-odd-box-container[data-v-464ac711]:nth-child(2n) {
    margin-right: 0;
}
.error-404[data-v-464ac711] {
    min-height: calc(100vh - 159px);
}
.casino-odds-box-theme[data-v-464ac711] {
    border-radius: 0;
}
.lucky7a .casino-odds .card-icon img[data-v-464ac711] {
    height: 12px;
}
.lucky7a .card-odd-box img[data-v-464ac711] {
    width: 30px;
}
.casino-nation-book[data-v-464ac711] {
    font-size: 14px;
    margin-top: 4px;
}
.lucky7a .casino-table-full-box .lucky7 img[data-v-464ac711] {
    width: 46px;
    margin-top: 16px;
    display: inline-block;
}
.casino-last-result-title[data-v-464ac711] {
    padding: 4px 10px;
    font-size: 12px;
    margin-top: 0 !important;
}
.right-sidebar .sidebar-title[data-v-464ac711] {
    background-color: var(--bg-secondary85);
}
.sidebar-box-rules .sidebar-title h4[data-v-464ac711] {
    width: 100%;
    font-size: 12px;
    text-align: left;
}
.sidebar-box-rules .my-bets[data-v-464ac711] {
    padding: 0;
}
.sidebar-box-rules .table th[data-v-464ac711],
  .sidebar-box-rules .table td[data-v-464ac711] {
    padding: 3px 8px;
    font-size: 12px;
}
.lucky7a .lucky7low[data-v-464ac711],
  .lucky7a .lucky7high[data-v-464ac711] {
    width: 40%;
}
.lucky7a .lucky7 img[data-v-464ac711] {
    height: 50px;
}
.lucky7a .casino-video-cards h4[data-v-464ac711] {
    display: none;
}
.lucky7a .casino-table-left-box[data-v-464ac711],
  .lucky7a .casino-table-right-box[data-v-464ac711] {
    width: 49%;
}
.lucky7cards[data-v-464ac711] {
    width: 49%;
    margin-top: 10px;
}
.baccarat .baccarat-graph[data-v-464ac711] {
    width: 100%;
    margin-bottom: 10px;
}
.baccarat-odds-container[data-v-464ac711] {
    width: 100%;
}
.player-box img[data-v-464ac711],
  .banker-box img[data-v-464ac711] {
    height: 15px;
    margin-right: 4px;
}
.casino-page-container .casino-table-full-box .nav-tabs .nav-item[data-v-464ac711] {
    flex: 1 1;
}
.casino-war .casino-table-header .casino-nation-detail[data-v-464ac711] {
    width: 0;
    padding: 0;
}
.casino-war .casino-table-header .casino-odds-box[data-v-464ac711] {
    width: 16.66%;
}
.casino-war .casino-nation-detail[data-v-464ac711] {
    width: 60%;
}
.casino-war .casino-odds-box[data-v-464ac711] {
    width: 40%;
}
.aaa .casino-odd-box-container[data-v-464ac711] {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid var(--table-border);
}
.aaa .casino-nation-name[data-v-464ac711] {
    width: 60%;
    text-align: left;
    padding-left: 5px;
}
.aaa .casino-odds-box[data-v-464ac711] {
    width: 20%;
}
.aaa .casino-table-left-box[data-v-464ac711],
  .aaa .casino-table-center-box[data-v-464ac711],
  .aaa .casino-table-right-box[data-v-464ac711] {
    padding: 5px 5px 0;
}
.bollywood .casino-odd-box-container[data-v-464ac711] {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid var(--table-border);
}
.bollywood .casino-nation-name[data-v-464ac711] {
    width: 60%;
    text-align: left;
    padding-left: 5px;
}
.bollywood .casino-odds-box[data-v-464ac711] {
    width: 20%;
}
.bollywood .casino-table-left-box.left-odd-box[data-v-464ac711] {
    width: 100%;
}
.bollywood .casino-table-right-box.right-odd-box[data-v-464ac711] {
    width: 100%;
}
.casino-war .nav-tabs .nav-link[data-v-464ac711] {
    width: 100%;
}
.casino-war .nav-tabs .nav-link.active[data-v-464ac711]::after {
    background-color: #fff;
}
.ab2 .playera-bets[data-v-464ac711] {
    width: 100%;
    margin-bottom: 20px;
}
.ab2 .playera-bets[data-v-464ac711]:last-child {
    margin-bottom: 0;
}
.worli .nav .nav-item[data-v-464ac711] {
    flex: 1 1 auto;
    margin: 1px;
}
.worli .nav .nav-item button[data-v-464ac711] {
    width: 100%;
}
.worli .nav .nav-item .nav-link[data-v-464ac711] {
    border-right: 0;
}
.worli-odd-box .worli-odd[data-v-464ac711] {
    font-size: 20px;
}
.worli-clear-box[data-v-464ac711] {
    background-color: var(--bg-table-row);
    padding: 5px;
    font-size: 14px;
}
.worli-box-title[data-v-464ac711] {
    min-height: 18px;
}
.sp .worli-left[data-v-464ac711],
  .dp .worli-left[data-v-464ac711],
  .chart56 .worli-left[data-v-464ac711],
  .chart64 .worli-left[data-v-464ac711],
  .colordp .worli-left[data-v-464ac711] {
    width: 75%;
}
.sp .worli-right[data-v-464ac711],
  .dp .worli-right[data-v-464ac711],
  .chart56 .worli-right[data-v-464ac711],
  .chart64 .worli-right[data-v-464ac711],
  .colordp .worli-right[data-v-464ac711] {
    width: 25%;
}
.threecard-title[data-v-464ac711] {
    width: 100%;
}
.threecardj-cards[data-v-464ac711] {
    width: 100%;
}
.race20 .casino-table-box[data-v-464ac711] {
    padding: 5px;
}
.race20 .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 5px);
}
.race20 .casino-odd-box-container[data-v-464ac711]:nth-child(2n) {
    margin-right: 0;
}
.race20 .casino-table-left-box[data-v-464ac711] {
    width: 100%;
}
.race20 .casino-table-right-box[data-v-464ac711] {
    width: 100%;
}
.raceto17 .casino-table-box[data-v-464ac711] {
    padding: 5px;
}
.raceto17 .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 5px);
}
.raceto17 .casino-odd-box-container[data-v-464ac711]:nth-child(2n) {
    margin-right: 0;
}
.baccarat29 .casino-table-full-box img[data-v-464ac711] {
    height: 60px;
}
.baccarat29 .casino-odd-box-container[data-v-464ac711] {
    width: 50%;
}
.trio .casino-table-box[data-v-464ac711] {
    padding: 5px;
}
.trio .triocards .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 3px);
    margin-bottom: 10px;
}
.trio .triocards .casino-odd-box-container[data-v-464ac711]:nth-child(2n) {
    margin-right: 0;
}
.trio .trioodds .casino-odd-box-container[data-v-464ac711] {
    width: 33%;
}
.trio .trioodds .casino-odd-box-container[data-v-464ac711] {
    width: calc(33.33% - 4px);
    margin-bottom: 10px;
}
.trio .trioodds .casino-odd-box-container[data-v-464ac711]:nth-child(3n) {
    margin-right: 0;
}
.trio .casino-table-box.trioodds[data-v-464ac711] {
    justify-content: center;
}
.ab-cards-container[data-v-464ac711] {
    width: 95px;
}
.ab-cards-container .flip-card[data-v-464ac711] {
    height: 30px !important;
    width: 24px !important;
}
.ab-cards-container img[data-v-464ac711] {
    height: 30px !important;
    width: 24px !important;
}
.ab2 .ab-cards-container img[data-v-464ac711] {
    height: 30px !important;
    width: 24px !important;
}
.casino-result-modal .casino-result-cards img[data-v-464ac711] {
    height: 35px;
}
.race-result-box[data-v-464ac711] {
    width: 235px;
}
.race-result-box .video-winner-text[data-v-464ac711] {
    font-size: 16px;
    width: 30px;
}
.race-result-box .casino-result-cards .k-image[data-v-464ac711] {
    right: -35px;
}
.race-result-box .casino-winner-icon[data-v-464ac711] {
    right: -70px;
    top: 5px;
}
.note-number .casino-odd-box-container-box[data-v-464ac711] {
    width: 100%;
    margin-right: 0;
}
z .note-number .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 5px);
}
.note-number .casino-odd-box-container[data-v-464ac711]:nth-child(2n) {
    margin-right: 0;
}
.note-number .casino-nation-name img[data-v-464ac711] {
    height: 24px;
    margin-right: 2px;
}
.casino-table-box[data-v-464ac711] {
    padding: 0;
}
.casino-table[data-v-464ac711] {
    margin-top: 0;
}
.note-number .casino-table-left-box[data-v-464ac711] {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.note-number .casino-table-left-box .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 5px);
}
.note-number .casino-table-right-box[data-v-464ac711] {
    width: 100%;
    justify-content: center;
}
.sevenupbox .casino-table-left-box[data-v-464ac711] {
    margin-bottom: 20px;
}
.onecard20 .onecard20oddbox[data-v-464ac711] {
    margin-right: 6px;
}
.onecard20 .onecard20player[data-v-464ac711],
  .onecard20 .onecard20dealer[data-v-464ac711],
  .onecard20 .onecard20pair[data-v-464ac711] {
    width: calc(29% - 7px);
}
.onecard20 .onecard20tie[data-v-464ac711] {
    width: calc(13% - 4px);
}
.trio .casino-odd-box-container[data-v-464ac711] {
    width: calc(33.33% - 4px);
    margin-right: 5px;
}
.trio .casino-nation-name[data-v-464ac711] {
    font-size: 11px;
}
.trap .casino-video-cards .v-slider[data-v-464ac711] {
    height: 110px;
}
.super-over .casino-video-cards img[data-v-464ac711] {
    height: 24px;
}
.super-over-rule[data-v-464ac711] {
    width: 100%;
}
.super-over-rule h4[data-v-464ac711] {
    background-color: var(--bg-secondary85);
    color: var(--text-secondary);
    padding: 5px;
}
.cricket20-left[data-v-464ac711],
  .cricket20-right[data-v-464ac711] {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
}
a .cricket20-left[data-v-464ac711] {
    margin-top: 30px;
}
.cricket20 .score-box[data-v-464ac711] {
    margin-top: 0;
    margin-bottom: 60px;
}
.cricket20 .blbox[data-v-464ac711] {
    bottom: -25px;
}
.cricket20videobanner[data-v-464ac711] {
    height: 90px;
    padding: 6px;
    width: 70%;
    font-size: 16px;
}
.cricket20bannertitle[data-v-464ac711] {
    top: -15px;
    padding: 5px;
    border-radius: 10px 10px 0 0;
    font-size: 14px;
}
.race20 .casino-last-results .result img[data-v-464ac711],
  .cricket20 .casino-last-results .result img[data-v-464ac711] {
    height: 25px;
}
.place-bet-modal .hfquitbtns .hbtn[data-v-464ac711] {
    background-image: linear-gradient(-180deg, #03b37f 0, #06553e 100%);
    border-color: #116f52 !important;
    border-width: 2px !important;
    border-top-width: 5px;
    margin-right: 20px;
    color: #fff;
}
.place-bet-modal .hfquitbtns .fbtn[data-v-464ac711] {
    background-image: linear-gradient(-180deg, #fc4242 0, #6f0404 100%);
    border-color: #6f0404 !important;
    border-width: 2px !important;
    color: #fff;
}
.place-bet-modal .hfquitbtns .btn.selected[data-v-464ac711] {
    border-color: var(--text-fancy) !important;
}
.kbc .casino-table[data-v-464ac711] {
    padding: 5px;
}
.kbc .kbc-btns[data-v-464ac711] {
    width: auto;
}
.vcasino .casino-video[data-v-464ac711] {
    padding-bottom: 60px;
}
.vcasino .casino-video-cards>div[data-v-464ac711] {
    margin-right: 20px;
}
.vcasino .casino-video-cards h5[data-v-464ac711] {
    font-size: 14px;
}
.vcasino .casino-video-cards .virtual-winner[data-v-464ac711] {
    height: 50px;
    left: -50px;
    top: -5px;
}
.vcasino .casino-video-cards>div:last-child .virtual-winner[data-v-464ac711] {
    right: -50px;
}
.cricket20ballpopup[data-v-464ac711] {
    margin-left: -40px;
    margin-top: -40px;
}
.cricket20ballpopup img[data-v-464ac711] {
    width: 80px;
}
.worli-cards-block[data-v-464ac711] {
    width: calc(25% - 9px);
    font-size: var(--font-caption);
    padding: 10px 5px;
    margin-right: 10px !important;
}
.worli-cards-block[data-v-464ac711]:nth-child(4n) {
    margin-right: 0 !important;
}
.lottery-box .lottery-card[data-v-464ac711] {
    width: 20%;
}
.lottery .double .lottery-place-balls[data-v-464ac711],
  .lottery .tripple .lottery-place-balls[data-v-464ac711] {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-sidebar);
    margin-top: 10px;
}
.lottery-place-balls img[data-v-464ac711] {
    width: 30px;
    margin-right: 10px;
}
.lottery-buttons[data-v-464ac711] {
    width: 100%;
    padding: 5px;
}
.lottery .casino-last-results[data-v-464ac711] {
    padding: 5px;
    margin-top: 5px;
}
.lottery-result-group[data-v-464ac711] {
    width: calc(20% - 5px);
}
.lottery-place-balls[data-v-464ac711] {
    padding: 0;
}
.lottery .casino-table .nav[data-v-464ac711] {
    width: 100%;
}
.lottery .casino-table .nav .nav-item[data-v-464ac711] {
    flex: auto;
}
.teenpatti20 .casino-table-header .casino_odds_row .casino-odds-box.back[data-v-464ac711]:last-child {
    display: none;
}
.teenpatti20 .casino-table-header .casino_odds_row .casino-odds-box.back[data-v-464ac711] {
    width: 100% !important;
}
.teenpattitest .casino-odds-box[data-v-464ac711] {
    border-color: #aaa;
}
.teenpattitest .casino-table-row .casino-odds[data-v-464ac711] {
    font-size: 14px;
}
.teenpattitest .casino-table-row .casino-nation-name[data-v-464ac711] {
    font-size: 14px;
    font-weight: 700;
}
.teenpattitest .casino-table-header .casino-odds-box[data-v-464ac711] {
    font-size: 14px !important;
}
.modal .five-cricket-result .table td[data-v-464ac711],
  .modal .five-cricket-result .table th[data-v-464ac711] {
    font-size: 12px;
}
.modal .five-cricket-result .table th[data-v-464ac711]:first-child,
  .modal .five-cricket-result .table th[data-v-464ac711]:nth-child(8n),
  .modal .five-cricket-result .table th[data-v-464ac711]:nth-child(9n) {
    width: 60px;
}
.five-cricket-result .table[data-v-464ac711] {
    table-layout: auto;
    table-layout: initial;
}
.five-cricket .market-6[data-v-464ac711] {
    min-width: 100%;
}
.modal .table td[data-v-464ac711],
  .modal .table th[data-v-464ac711] {
    font-size: 12px;
}
.modal-dialog[data-v-464ac711] {
    margin: 0 auto;
    padding: 9px;
}
.modal-body[data-v-464ac711] {
    max-height: calc(100vh - 44px);
    padding: 0;
}
.cricket20 .casino-detail[data-v-464ac711] {
    margin-top: 30px;
}
.scorecard[data-v-464ac711] {
    margin: 0;
    width: 100%;
}
.market-9 .suspended-box[data-v-464ac711]::after {
    width: 20%;
    left: auto;
    left: initial;
    right: 0;
}
.market-9 .suspended-box[data-v-464ac711]::before {
    width: 20%;
    left: auto;
    left: initial;
    right: 0;
}
.horse-table .bet-table-row[data-v-464ac711] {
    padding: 0;
}
.horse-table .bet-nation-name[data-v-464ac711] {
    width: 100%;
    background: var(--bg-table);
    padding: 5px;
    margin-bottom: 5px;
}
.horse-time-detail[data-v-464ac711] {
    width: 100%;
}
.market-12 .market-nation-detail label img[data-v-464ac711] {
    height: 20px;
}
.market-12 .market-nation-detail .form-check label>div[data-v-464ac711]:nth-child(2) {
    width: calc(100% - 35px);
}
.market-12 .market-nation-detail .market-nation-name[data-v-464ac711] {
    font-weight: 700;
    width: 100%;
    font-size: 12px;
}
.market-12 .market-book[data-v-464ac711] {
    width: 100%;
}
.horse-banner .horse-banner-detail span[data-v-464ac711] {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 16px;
    color: #fff;
}
.horse-banner[data-v-464ac711] {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
.home-page .casino-list-item[data-v-464ac711] {
    width: calc(25% - 10px);
}
.rules-section img[data-v-464ac711] {
    max-width: 100%;
}
.rules-modal .rules-left-sidebar[data-v-464ac711] {
    width: 100%;
    min-height: 0;
    min-height: initial;
    margin-top: 5px;
}
.rules-modal .rules-left-sidebar .nav[data-v-464ac711] {
    flex-wrap: nowrap;
    overflow: auto;
}
.rules-modal .rules-left-sidebar .nav .nav-item[data-v-464ac711] {
    width: auto;
    flex: 1 1 auto;
    white-space: nowrap;
}
.rules-content[data-v-464ac711] {
    width: 100%;
    margin-top: 10px;
}
.patern-name[data-v-464ac711] {
    width: 100%;
    margin: 0;
}
.fantasy-banners[data-v-464ac711] {
    margin-top: 5px;
}
.dropdown .dropdown-item[data-v-464ac711] {
    padding: 6px 12px;
}
.login-page[data-v-464ac711] {
    justify-content: center;
}
.odds-title>div[data-v-464ac711] {
    margin-right: 20px;
    width: 12px;
}
.odds-title .card-character[data-v-464ac711] {
    font-size: 12px;
}
.report-page .card[data-v-464ac711] {
    border: none;
}
.report-page .card-header[data-v-464ac711] {
    padding: 6px 8px;
    border-radius: 0;
}
.report-page .card-title[data-v-464ac711] {
    font-size: 18px;
}
.report-page .react-datepicker-wrapper[data-v-464ac711] {
    width: 100%;
    min-width: 100%;
}
.report-page .btn[data-v-464ac711] {
    padding: 2px 30px;
    border-radius: 0;
    height: 31px;
    width: 100%;
}
.Verification_box h2[data-v-464ac711] {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    align-items: center;
}
.report-page .Verification_box .btn-group[data-v-464ac711] {
    margin-bottom: 10px;
    display: flex;
}
.report-page .Verification_box h2 span[data-v-464ac711] {
    font-size: 14px;
}
.report-page .Verification_box h2 span[data-v-464ac711] {
    font-size: 14px;
    border-radius: 3px;
    padding: 6px;
}
.report-page .Verification_box button.btn.btn-primary[data-v-464ac711] {
    font-size: 14px;
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
}
.report-page .Verification_box .btn-group>.btn-group:not(:last-child)>.btn[data-v-464ac711],
  .report-page .Verification_box .btn-group>.btn[data-v-464ac711]:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.report-page .Verification_box .btn-group>.btn-group:not(:first-child)>.btn[data-v-464ac711],
  .report-page .Verification_box .btn-group>.btn[data-v-464ac711]:nth-child(n + 3),
  .report-page .Verification_box .btn-group> :not(.btn-check)+.btn[data-v-464ac711] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.report-page .Verification_box .btn-group[data-v-464ac711] {
    margin-bottom: 10px;
    display: flex;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 7px;
}
.report-page .Verification_box .verify-code[data-v-464ac711] {
    font-size: 30px;
    margin-top: 3px;
}
.report-page .btn-download[data-v-464ac711] {
    height: auto;
    width: 196px;
    padding: 8px 5px;
}
.report-page .change_btn_value .form-control[data-v-464ac711] {
    border-radius: 1px;
    box-shadow: 0 0 2px #7e7e7e;
    border: none;
    height: 34px;
}
.report-form.change_btn_value[data-v-464ac711] {
    padding: 0 4px;
}
.change_btn_value .row.row10>[class*="col-"][data-v-464ac711],
  .change_btn_value .row.row10>[class*="col"][data-v-464ac711] {
    padding-left: 3px;
    padding-right: 3px;
}
.change_password_design .form-control[data-v-464ac711] {
    border: none;
    border-bottom: 1px solid #000 !important;
    border-radius: 0;
    height: 33px;
}
.ball-by-ball .market-6 .blb-box[data-v-464ac711] {
    width: 20%;
}
.ball-by-ball .market-6 .blb-box .market-odd-box[data-v-464ac711] {
    width: 100%;
}
.ball-by-ball .market-6 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 56%);
    padding-left: 16px;
}
.ball-by-ball .market-6 .fancy-min-max[data-v-464ac711] {
    font-size: 10px;
    padding-left: 4px;
}
}
@media only screen and (max-width: 1199px) {
.mobile-inplay-design .market-11[data-v-464ac711] {
    width: 100%;
}
.market-6 .fancy-market .market-nation-detail .market-nation-name[data-v-464ac711] {
    max-width: calc(100% - 20px);
    font-weight: 600;
    color: #000;
}
.login-form[data-v-464ac711] {
    padding: 10px;
}
.header-top[data-v-464ac711] {
    padding: 5px 12px;
    align-items: center;
    height: auto;
    height: initial;
}
.header-top .logo-header img[data-v-464ac711] {
    max-height: 50px;
    max-width: 120px;
}
.header-rules[data-v-464ac711] {
    display: none;
}
.logo-header[data-v-464ac711] {
    display: flex;
    align-items: center;
}
.logo-header i[data-v-464ac711] {
    font-size: 20px;
    color: var(--text-primary);
}
.user-balance[data-v-464ac711] {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 14px;
}
.user-balance .dropdown[data-v-464ac711] {
    display: inline-block;
    margin-left: 5px;
}
.user-balance>div[data-v-464ac711]:first-child {
    width: 100%;
    text-align: right;
    margin-bottom: 5px;
}
.user-balance .dropdown .dropdown-toggle[data-v-464ac711] {
    font-size: 14px;
}
.search-box-container[data-v-464ac711] {
    width: 100%;
    z-index: 2;
}
.search-box[data-v-464ac711] {
    height: 36px;
    margin-top: 5px;
}
.search-box .btn-search[data-v-464ac711] {
    height: 30px;
    width: 30px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 17px;
    border-radius: 50%;
    border-radius: 0 30px 30px 0;
}
.collapse_sreach a.btn-search[data-v-464ac711] {
    border-radius: 50%;
}
.search-box .btn-search i[data-v-464ac711] {
    font-size: 15px;
    cursor: pointer;
    color: #000;
    margin-left: 0;
}
.search-box input[data-v-464ac711] {
    width: 200px;
    padding: 0;
    border: 0;
    height: 30px;
    border: 0;
    border-radius: 30px 0 0 30px;
    box-shadow: none;
    transition: width 0.4s linear;
    padding: 0 10px;
    color: #000;
    font-size: 13px;
    line-height: 17px;
    padding: 12px;
    margin-right: -1px;
}
.mobile-header-bottom .sports-tab[data-v-464ac711] {
    position: relative;
}
.header-top .user-balance span i[data-v-464ac711] {
    font-size: 14px;
}
.user-details .Exposure_txt[data-v-464ac711] {
    font-size: 14px;
    text-decoration: underline;
}
.user-balance .dropdown .dropdown-toggle span[data-v-464ac711] {
    text-decoration: underline;
}
.user-balance .dropdown .dropdown-toggle i[data-v-464ac711] {
    font-size: 10px;
}
.user-menu-dropdown .dropdown-menu[data-v-464ac711] {
    background: #f1f5f8;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.user-menu-dropdown .dropdown-item a[data-v-464ac711] {
    color: var(--text-body);
    font-size: 1rem;
    color: #212529;
}
.header-top .dropdown .dropdown-item[data-v-464ac711] {
    font-size: 13px;
    padding: 10px 12px;
    font-family: roboto condensed, sans-serif;
}
.user-menu-dropdown .dropdown-item:last-child a[data-v-464ac711] {
    color: #b71e2d;
    font-weight: 600;
}
.user-menu-dropdown .dropdown-divider[data-v-464ac711] {
    display: none;
}
.mobile-header-bottom .menu-tabs[data-v-464ac711] {
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
    position: relative;
}
.sports-tab .nav-link[data-v-464ac711] {
    padding: 5px 10px;
}
.home-page .bet-nation-name[data-v-464ac711] {
    padding-left: 0;
}
.news[data-v-464ac711] {
    bottom: 14px;
    font-size: 12px;
    width: 85%;
    display: flex;
    align-items: center;
}
.main-container[data-v-464ac711] {
    min-height: calc(100vh - 145px);
    margin-top: 0;
    align-content: flex-start;
}
.left-sidebar[data-v-464ac711] {
    display: none;
}
.center-main-container[data-v-464ac711] {
    width: 100%;
    padding: 0;
}
.center-main-container.detail-page[data-v-464ac711] {
    padding: 0;
}
.main-container .nav-tabs[data-v-464ac711] {
    background-color: var(--bg-primary);
    border-bottom: 0;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
    position: relative;
    width: 100%;
}
.sports-tab .nav-link i[data-v-464ac711],
  .casino-tab .nav-link i[data-v-464ac711] {
    font-size: 15px;
    margin-bottom: 5px;
    display: block;
}
.sports-tab.nav-pills .nav-link[data-v-464ac711],
  .casino-tab.nav-pills .nav-link[data-v-464ac711] {
    background-color: transparent;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}
.sports-tab.nav-pills .nav-link.active[data-v-464ac711],
  .sports-tab.nav-pills .show>.nav-link[data-v-464ac711],
  .casino-tab.nav-pills .nav-link.active[data-v-464ac711],
  .casino-tab.nav-pills .show>.nav-link[data-v-464ac711] {
    background-color: var(--bg-primary);
    color: var(--text-primary);
}
.center-container .nav.sports-tab[data-v-464ac711],
  .center-container .nav.casino-tab[data-v-464ac711] {
    position: relative;
    width: 100%;
    margin: 0;
    overflow-x: auto;
    background-color: var(--bg-secondary);
}
.own-casino-page .casino-sub-tab-list[data-v-464ac711] {
    padding: 0;
    background-color: var(--bg-secondary);
}
.own-casino-page .casino-sub-tab-list .nav.casino-sub-tab[data-v-464ac711] {
    background-color: var(--bg-primary65);
    padding: 5px;
    width: 100%;
}
.slot-page .casino-tab-list[data-v-464ac711],
  .slot-page .casino-sub-tab-list[data-v-464ac711] {
    padding: 0;
}
.slot-page .casino-sub-tab-list .casino-sub-tab[data-v-464ac711] {
    padding: 3px 5px;
}
.slot-page.list-page .casino-sub-tab .nav-link[data-v-464ac711] {
    height: auto;
}
.bet-table[data-v-464ac711] {
    padding: 10px;
}
.home-page .bet-table[data-v-464ac711] {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}
.bet-table-header[data-v-464ac711] {
    display: none;
}
.bet-table-row[data-v-464ac711] {
    padding-bottom: 5px;
}
.home-page .bet-table-row[data-v-464ac711] {
    padding: 5px 10px;
}
.home-page .bet-table[data-v-464ac711] {
    padding: 0;
}
.casino_list_title[data-v-464ac711] {
    line-height: 1.5 !important;
    min-height: 22px !important;
    font-size: 8px;
    border-radius: 4px !important;
}
.bet-nation-name[data-v-464ac711] {
    width: 100%;
}
.bet-nation-odd[data-v-464ac711] {
    width: 33.33%;
}
.bet-nation-name .bet-nation-game-name[data-v-464ac711] {
    max-width: calc(100% - 120px);
}
.home-page .bet-nation-game-name span[data-v-464ac711] {
    width: 100%;
    display: block;
    line-height: normal;
    color: #000;
    margin-bottom: 3px;
}
.bet-nation-game-name span[data-v-464ac711]:first-child {
    font-weight: 700;
}
.bet-nation-game-name span[data-v-464ac711]:last-child {
    font-size: 12px;
}
.bet-nation-name .game-icons[data-v-464ac711] {
    width: 120px;
}
.bet-nation-name .game-icons .game-icon[data-v-464ac711] {
    width: 20px;
}
.bet-nation-name .game-icons .game-icon img[data-v-464ac711] {
    height: 10px;
}
.bet-nation-name .game-icons .game-icon i[data-v-464ac711] {
    font-size: 10px;
}
.bet-nation-name .game-icons .game-icon .active[data-v-464ac711] {
    height: 12px;
    width: 12px;
    background-color: #97cc00;
}
.right-sidebar[data-v-464ac711] {
    display: block;
    min-height: auto;
    max-height: inherit;
    width: 100% !important;
    position: relative;
    top: inherit;
}
.casino-page .right-sidebar[data-v-464ac711] {
    margin-left: 0;
}
.right-sidebar .sidebar-title[data-v-464ac711] {
    height: auto;
    text-align: center;
}
.sidebar-title h4[data-v-464ac711] {
    width: 100%;
}
.center-main-container.list-page[data-v-464ac711] {
    padding: 0;
}
.list-page .casino-sub-tab[data-v-464ac711] {
    width: 100%;
}
.center-container .nav.sports-tab[data-v-464ac711],
  .center-container .nav.casino-tab[data-v-464ac711],
  .center-container .nav.casino-sub-tab[data-v-464ac711] {
    overflow-x: auto;
    width: 100%;
}
.home-page .center-container .nav.sports-tab[data-v-464ac711] {
    background-color: var(--bg-secondary);
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
}
.casino-sub-tab.nav-pills .nav-link[data-v-464ac711] {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}
.casino-sub-tab.nav-pills .nav-link[data-v-464ac711] {
    background: 0 0;
    color: var(--text-primary);
    margin-right: 0;
    margin-bottom: 0;
    border-right: 0;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.slot-page.list-page .casino-tab .nav-link[data-v-464ac711] {
    height: auto;
    padding: 12px 16px;
}
.own-casino-page .casino-sub-tab .nav-link[data-v-464ac711] {
    height: auto;
}
.slot-page .casino-tab-list[data-v-464ac711] {
    padding: 0;
}
.slot-page .casino-tab-list .tabs-arow[data-v-464ac711] {
    display: none;
}
.slot-page .casino-sub-tab-list .tabs-arow[data-v-464ac711] {
    display: none;
}
.casino-sub-tab.nav-pills .nav-link.active[data-v-464ac711] {
    color: var(--text-primary);
    background: var(--bg-primary);
    border-radius: 12px;
}
.place-bet-modal[data-v-464ac711] {
    padding: 10px 5px;
}
.place-bet-modal .stakeactionminus[data-v-464ac711],
  .place-bet-modal .stakeactionplus[data-v-464ac711] {
    background: var(--bg-secondary);
    height: 28px;
    width: 28px;
    line-height: 28px;
    padding: 0;
    float: left;
    border-radius: 0;
    color: var(--text-secondary);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.place-bet-modal .stakeinput[data-v-464ac711] {
    width: 70px;
    height: 28px;
    float: left;
    background-color: #fff;
    border: 1px solid #646464;
    border-radius: 0;
}
.place-bet-modal .btn-primary[data-v-464ac711] {
    height: 28px;
    line-height: 28px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0;
}
.place-bet-modal .place-bet-buttons[data-v-464ac711] {
    padding: 0;
}
.place-bet-buttons .btn-place-bet[data-v-464ac711] {
    padding: 4px;
    font-weight: 700;
}
.place-bet-modal .place-bet-buttons .btn-place-bet[data-v-464ac711] {
    width: 32%;
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.casino-page .center-container[data-v-464ac711] {
    width: 100%;
}
.casino-nation-name[data-v-464ac711] {
    font-size: 12px;
}
.casino-odds[data-v-464ac711] {
    font-size: 14px;
    text-align: center;
    line-height: 14px;
}
.casino-volume[data-v-464ac711] {
    font-size: 12px;
    font-weight: 500;
}
.casino-last-results .result[data-v-464ac711] {
    width: 30px;
    height: 30px;
    font-weight: 600;
}
.teenopenresult .casino-winner-icon[data-v-464ac711] {
    right: 20px;
}
.page-details[data-v-464ac711] {
    height: auto;
}
.custom-pagination>div[data-v-464ac711]:last-child {
    width: 100%;
    margin-top: 5px;
}
.own-casino-page .casino-sub-tab-list .tabs-arow[data-v-464ac711] {
    display: none;
}
.casino-tab-list .tabs-arow[data-v-464ac711] {
    display: none;
}
.close-slot-frame[data-v-464ac711] {
    display: none;
}
.mobile-nation-name[data-v-464ac711] {
    font-size: 16px;
    padding: 0 5px;
    font-weight: 700;
}
.secure-auth .nav-tabs[data-v-464ac711] {
    background: 0 0 !important;
    box-shadow: none !important;
    display: flex !important;
    flex-wrap: wrap !important;
}
.secure-auth .nav-tabs .nav-item[data-v-464ac711] {
    padding: 0;
}
.report-page .secure-auth .login-password[data-v-464ac711] {
    flex-wrap: wrap;
    margin-top: 10px;
}
.report-page .secure-auth .login-password input[data-v-464ac711] {
    width: 100%;
}
.report-page .secure-auth .login-password button[data-v-464ac711] {
    width: 100%;
    margin: 5px 0 0;
}
.ab-title[data-v-464ac711] {
    writing-mode: vertical-lr;
    text-orientation: upright;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
    font-size: 11px;
}
.race20 .flip-card[data-v-464ac711] {
    height: 20px;
    width: 16px;
}
.login-box[data-v-464ac711] {
    margin: 0% 0;
}
.trap-number-icon[data-v-464ac711] {
    margin-top: 10px;
}
.report-page .table td[data-v-464ac711],
  .report-page .table td[data-v-464ac711],
  .report-page .table th[data-v-464ac711] {
    padding: 7px;
    font-size: 12px;
    white-space: nowrap;
}
.report-page .table thead th[data-v-464ac711] {
    background: var(--bg-secondary);
    color: var(--text-secondary);
}
.slot-page .casino-tab-list.show-close-btn .tabs-arow.tabs-arow-left+.casino-tab[data-v-464ac711] {
    width: 100%;
}
.slot-page .casino-sub-tab-list .tabs-arow.tabs-arow-left+.casino-sub-tab[data-v-464ac711] {
    width: 100%;
}
.slot-page .tabs-arow.tabs-arow-left+.casino-tab[data-v-464ac711] {
    width: 100%;
}
.latest-event[data-v-464ac711] {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    margin-bottom: 0;
    white-space: nowrap;
    background-color: var(--bg-primary);
    padding: 0 5px 5px;
}
.latest-event-item[data-v-464ac711] {
    min-width: 0;
    min-width: initial;
    width: auto;
    max-width: none;
    max-width: initial;
}
.latest-event-item a[data-v-464ac711] {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
}
.latest-event-item a span[data-v-464ac711] {
    width: 100%;
    text-align: center;
}
}
@media only screen and (max-width: 359px) {
.header-top .logo-header img[data-v-464ac711] {
    max-height: 45px;
    max-width: 100px;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
    font-size: 10px;
}
.note-number .casino-nation-name img[data-v-464ac711] {
    height: 16px;
}
.trio .casino-nation-name[data-v-464ac711] {
    font-size: 9px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
.detail-page .center-container[data-v-464ac711] {
    width: 100%;
}
.game-header[data-v-464ac711] {
    margin-top: 8px;
}
.card-odd-box img[data-v-464ac711] {
    height: 55px;
}
.market-odd-box[data-v-464ac711] {
    width: 60px;
}
.market-6 .market-odd-box[data-v-464ac711] {
    width: 15%;
}
.fancy-min-max-box[data-v-464ac711] {
    width: 60px;
}
.market-odd-box .market-odd[data-v-464ac711] {
    font-size: 16px;
}
.market-nation-detail[data-v-464ac711] {
    width: calc(100% - 360px);
}
.market-2[data-v-464ac711] {
    min-width: calc(50% - 8px);
}
.market-2 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-2.width30 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-2.width30 .suspended-row[data-v-464ac711]::after,
  .market-2.width30 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-3 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 240px);
}
.market-3 .suspended-row[data-v-464ac711]::after,
  .market-3 .suspended-table[data-v-464ac711]::after {
    width: 240px;
}
.market-4 .suspended-row[data-v-464ac711]::after,
  .market-4 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.market-5 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 240px);
}
.market-5 .suspended-row[data-v-464ac711]::after,
  .market-5 .suspended-table[data-v-464ac711]::after {
    width: 120px;
    right: 120px;
}
.market-6 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 30%);
}
.market-6 .suspended-row[data-v-464ac711]::after,
  .market-6 .suspended-table[data-v-464ac711]::after {
    width: 180px;
}
.market-9 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 60px);
}
.market-9 .suspended-row[data-v-464ac711]::after,
  .market-9 .suspended-table[data-v-464ac711]::after {
    width: 60px;
}
.market-10 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-10 .suspended-row[data-v-464ac711]::after,
  .market-10 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-11 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-11 .suspended-row[data-v-464ac711]::after,
  .market-11 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-12 .suspended-row[data-v-464ac711]::after,
  .market-12 .suspended-table[data-v-464ac711]::after {
    width: 360px;
}
.casino-page .center-container[data-v-464ac711] {
    width: 100%;
}
.suspended-row[data-v-464ac711]::after,
  .suspended-table[data-v-464ac711]::after {
    font-size: 13px;
}
.casino-list-item[data-v-464ac711] {
    width: calc(25% - 10px);
}
.casino-list-item.rect[data-v-464ac711] {
    width: calc(33.33% - 10px);
}
.kbc .hfquitbtns .btn[data-v-464ac711] {
    width: 110px;
    height: 110px;
}
.market-9 .suspended-box[data-v-464ac711]::after {
    width: 60px;
    left: auto;
    left: initial;
    right: 0;
}
.market-9 .suspended-box[data-v-464ac711]::before {
    width: 60px;
    left: auto;
    left: initial;
    right: 0;
}
.home-page .casino-list-item[data-v-464ac711] {
    width: calc(16.66% - 10px);
}
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
.left-sidebar[data-v-464ac711] {
    width: 210px;
}
.center-main-container[data-v-464ac711] {
    width: calc(100% - 210px);
}
.detail-page .center-container[data-v-464ac711] {
    width: calc(100% - 352px);
}
.right-sidebar[data-v-464ac711] {
    width: 350px;
}
.right-sidebar.sticky[data-v-464ac711] {
    width: 350px;
}
.card-odd-box img[data-v-464ac711] {
    height: 45px;
}
.market-odd-box[data-v-464ac711] {
    width: 55px;
}
.fancy-min-max-box[data-v-464ac711] {
    width: 55px;
}
.market-nation-detail[data-v-464ac711] {
    width: calc(100% - 330px);
}
.market-odd-box .market-odd[data-v-464ac711] {
    font-size: 16px;
}
.market-nation-detail[data-v-464ac711] {
    font-size: 13px;
}
.market-2 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 110px);
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 110px;
}
.market-2.width30 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 110px);
}
.market-2.width30 .suspended-row[data-v-464ac711]::after,
  .market-2.width30 .suspended-table[data-v-464ac711]::after {
    width: 110px;
}
.market-3 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 220px);
}
.market-3 .suspended-row[data-v-464ac711]::after,
  .market-3 .suspended-table[data-v-464ac711]::after {
    width: 220px;
}
.market-4 .suspended-row[data-v-464ac711]::after,
  .market-4 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.market-5 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 220px);
}
.market-5 .suspended-row[data-v-464ac711]::after,
  .market-5 .suspended-table[data-v-464ac711]::after {
    width: 110px;
    right: 110px;
}
.market-9 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 55px);
}
.market-9 .suspended-row[data-v-464ac711]::after,
  .market-9 .suspended-table[data-v-464ac711]::after {
    width: 55px;
}
.market-10 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 110px);
}
.market-10 .suspended-row[data-v-464ac711]::after,
  .market-10 .suspended-table[data-v-464ac711]::after {
    width: 110px;
}
.market-11 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 110px);
}
.market-11 .suspended-row[data-v-464ac711]::after,
  .market-11 .suspended-table[data-v-464ac711]::after {
    width: 110px;
}
.market-12 .suspended-row[data-v-464ac711]::after,
  .market-12 .suspended-table[data-v-464ac711]::after {
    width: 330px;
}
.casino-page .center-container[data-v-464ac711] {
    width: calc(100% - 355px);
}
.suspended-row[data-v-464ac711]::after,
  .suspended-table[data-v-464ac711]::after {
    font-size: 16px;
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 110px;
}
.slot-iframe[data-v-464ac711] {
    left: 215px;
    width: calc(100% - 215px);
}
.casino-list-item[data-v-464ac711] {
    width: calc(16.66% - 10px);
}
.dt1day .dtredblack .casino-table-header .casino-odds-box[data-v-464ac711] {
    font-size: 13px;
}
.kbc .hfquitbtns .btn[data-v-464ac711] {
    width: 90px;
    height: 90px;
}
.flip-card[data-v-464ac711] {
    height: 40px;
    width: 32px;
}
.vcasino.bollywood .flip-card-container[data-v-464ac711],
  .vcasino.aaa .flip-card-container[data-v-464ac711] {
    height: 40px;
    width: 32px;
}
.vcasino .casino-video-cards .virtual-winner[data-v-464ac711] {
    height: 70px;
    left: -70px;
    top: 5px;
}
.vcasino .casino-video-cards>div:last-child .virtual-winner[data-v-464ac711] {
    right: -70px;
}
.market-9 .suspended-box[data-v-464ac711]::after {
    width: 55px;
    left: auto;
    left: initial;
    right: 0;
}
.market-9 .suspended-box[data-v-464ac711]::before {
    width: 55px;
    left: auto;
    left: initial;
    right: 0;
}
.fantasy-iframe[data-v-464ac711] {
    left: 215px;
    width: calc(100% - 215px);
}
.teenpatti2024 .casino-nation-detail>div img[data-v-464ac711] {
    height: 20px;
}
.fancy-min-max[data-v-464ac711] {
    font-size: 11px;
}
.odds-title>div[data-v-464ac711] {
    width: 32px;
}
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
.left-sidebar[data-v-464ac711] {
    width: 240px;
}
.center-main-container[data-v-464ac711] {
    width: calc(100% - 240px);
}
.detail-page .center-container[data-v-464ac711] {
    width: calc(100% - 402px);
}
.right-sidebar[data-v-464ac711] {
    width: 400px;
}
.right-sidebar.sticky[data-v-464ac711] {
    width: 400px;
}
.card-odd-box img[data-v-464ac711] {
    height: 50px;
}
.market-odd-box[data-v-464ac711] {
    width: 60px;
}
.fancy-min-max-box[data-v-464ac711] {
    width: 60px;
}
.market-nation-detail[data-v-464ac711] {
    width: calc(100% - 360px);
}
.bookmaker4 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-odd-box .market-odd[data-v-464ac711] {
    font-size: 16px;
}
.market-2 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-2.width30 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-2.width30 .suspended-row[data-v-464ac711]::after,
  .market-2.width30 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-3 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 240px);
}
.market-3 .suspended-row[data-v-464ac711]::after,
  .market-3 .suspended-table[data-v-464ac711]::after {
    width: 240px;
}
.market-4 .suspended-row[data-v-464ac711]::after,
  .market-4 .suspended-table[data-v-464ac711]::after {
    width: 100%;
}
.market-5 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 240px);
}
.market-5 .suspended-row[data-v-464ac711]::after,
  .market-5 .suspended-table[data-v-464ac711]::after {
    width: 120px;
    right: 120px;
}
.market-9 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 60px);
}
.market-9 .suspended-row[data-v-464ac711]::after,
  .market-9 .suspended-table[data-v-464ac711]::after {
    width: 60px;
}
.market-10 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-10 .suspended-row[data-v-464ac711]::after,
  .market-10 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-11 .market-nation-detail[data-v-464ac711] {
    width: calc(100% - 120px);
}
.market-11 .suspended-row[data-v-464ac711]::after,
  .market-11 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.market-12 .suspended-row[data-v-464ac711]::after,
  .market-12 .suspended-table[data-v-464ac711]::after {
    width: 360px;
}
.casino-page .center-container[data-v-464ac711] {
    width: calc(100% - 405px);
}
.suspended-row[data-v-464ac711]::after,
  .suspended-table[data-v-464ac711]::after {
    font-size: 15px;
}
.market-2 .suspended-row[data-v-464ac711]::after,
  .market-2 .suspended-table[data-v-464ac711]::after {
    width: 120px;
}
.slot-iframe[data-v-464ac711] {
    left: 245px;
    width: calc(100% - 245px);
}
.casino-list-item[data-v-464ac711] {
    width: calc(16.66% - 10px);
}
.dt1day .dtredblack .casino-table-header .casino-odds-box[data-v-464ac711] {
    font-size: 14px;
}
.kbc .hfquitbtns .btn[data-v-464ac711] {
    width: 110px;
    height: 110px;
}
.flip-card[data-v-464ac711] {
    height: 45px;
    width: 36px;
}
.vcasino.bollywood .flip-card-container[data-v-464ac711],
  .vcasino.aaa .flip-card-container[data-v-464ac711] {
    height: 45px;
    width: 36px;
}
.vcasino .casino-video-cards .virtual-winner[data-v-464ac711] {
    height: 90px;
    left: -90px;
}
.vcasino .casino-video-cards .virtual-winner[data-v-464ac711] {
    right: -90px;
}
.market-9 .suspended-box[data-v-464ac711]::after {
    width: 60px;
    left: auto;
    left: initial;
    right: 0;
}
.market-9 .suspended-box[data-v-464ac711]::before {
    width: 60px;
    left: auto;
    left: initial;
    right: 0;
}
.fantasy-iframe[data-v-464ac711] {
    left: 245px;
    width: calc(100% - 245px);
}
.teenpatti2024 .casino-nation-detail>div img[data-v-464ac711] {
    height: 24px;
}
.fancy-min-max[data-v-464ac711] {
    font-size: 11px;
}
.odds-title>div[data-v-464ac711] {
    width: 36px;
}
}
@media only screen and (min-width: 1200px) {
.user-details[data-v-464ac711] {
    margin-top: -10px;
}
}
@media only screen and (max-width: 1140px) {
.result-modal .casino-result-round-id[data-v-464ac711] {
    font-size: 16px;
}
.result-modal .modal-body[data-v-464ac711] {
    padding: 10px;
}
.Result32cardA .casino-result-cards[data-v-464ac711] {
    justify-content: space-between;
}
.Result32cardA .casino-result-modal .casino-result-cards img[data-v-464ac711] {
    height: 45px;
}
.Result32cardA .result-item[data-v-464ac711] {
    border-radius: 0;
    margin-bottom: 6px;
    padding: 4px 7px;
    box-shadow: 0 0 2px;
}
.Result32cardA .casino-result-modal .casino-result-cards[data-v-464ac711] {
    margin-top: 0;
}
.Lucky7Result .Result-box[data-v-464ac711] {
    background-color: #f1f5f8;
    padding: 10px;
    box-shadow: 0 0 5px #aaa;
}
.Lucky7Result .casino-result-modal .casino-result-cards img[data-v-464ac711] {
    height: 50px;
}
}
@media only screen and (max-width: 1023px) {
.search-box svg.icon[data-v-464ac711] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    fill: #000;
    margin-left: 1px;
}
.ander_bahar .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
    width: 30px;
}
.ander_bahar .result-item .result-title[data-v-464ac711] {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
}
.cards32a .casino-table-header .casino-odds-box[data-v-464ac711] {
    text-transform: uppercase;
    font-size: 14px !important;
    color: #212529;
}
.casino-odds[data-v-464ac711] {
    color: #212529;
}
.casino-title-header-mobile[data-v-464ac711] {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.casino-title-header-mobile .casino-rid[data-v-464ac711] {
    font-size: 12px;
}
.casino-title-header-mobile .casino-rid a[data-v-464ac711] {
    text-align: right;
    color: #fff;
    text-decoration: underline;
}
.game-header[data-v-464ac711] {
    font-size: 12px;
    width: 100%;
    margin: 0;
}
.casino-header .casino-name[data-v-464ac711] {
    font-size: 12px;
}
.casino-odds-box[data-v-464ac711] {
    border-left: 1px solid #aaa;
}
.casino-detail .suspended-row[data-v-464ac711]::after,
  .casino-detail .suspended-table[data-v-464ac711]::after {
    font-size: 16px;
    font-weight: 700;
}
.mobile-match-tab .nav-item[data-v-464ac711] {
    flex: inherit !important;
}
.mobile-match-tab .mobiletv-btn[data-v-464ac711] {
    position: absolute;
    right: 11px;
    font-size: 18px;
    color: #fff;
    top: 9px;
}
.mobile-match-tab .mobiletv-btn svg.icon[data-v-464ac711] {
    height: 23px;
    width: 23px;
    fill: var(--text-primary);
}
.load-inner[data-v-464ac711] {
    position: absolute;
}
.poker-6player .casino-table[data-v-464ac711] {
    margin-top: 0;
}
.poker-6player .nav-tabs[data-v-464ac711] {
    box-shadow: none !important;
}
.poker-6player .nav-tabs .nav-item[data-v-464ac711] {
    width: 50%;
}
.poker-6player .nav-tabs .nav-item .nav-link[data-v-464ac711] {
    width: 100%;
}
.casino-title-header-mobile .nav-item[data-v-464ac711] {
    width: auto !important;
}
.poker-6player .casino6poker-item[data-v-464ac711] {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border-radius: 0 !important;
    font-size: 14px !important;
}
.poker-6player .casino-nation-name[data-v-464ac711] {
    font-size: 14px;
    font-weight: 400;
}
.poker-6player .patern-name[data-v-464ac711] {
    font-weight: 600;
    width: auto;
}
.poker-6player .patern-name .card-icon[data-v-464ac711] {
    font-size: 12px;
    font-weight: 500;
}
.poker-6player .patern-name img[data-v-464ac711] {
    height: 12px;
}
.poker-6player .suspended-box[data-v-464ac711]::before {
    background-size: 18px 18px;
}
.poker-6player .hands-container .player[data-v-464ac711] {
    margin-bottom: 0%;
}
.poker-6player span.min_max_poker6[data-v-464ac711] {
    font-size: 10px;
    float: right;
    width: 100%;
    text-align: right;
    font-weight: 500;
}
.poker-6player .patern-name img[data-v-464ac711] {
    height: 14px;
}
.poker-6player .poker6-odds p[data-v-464ac711] {
    font-size: 14px;
}
.poker-6player .poker6-odds[data-v-464ac711] {
    margin-top: 5px;
    padding-left: 3px;
    margin-bottom: 2px;
}
.nav-tabs .nav-link.active[data-v-464ac711]::after {
    background-color: #0a2462;
}
.poker-6player .casino-odds-box[data-v-464ac711] {
    border: none;
    padding: 0 !important;
}
.poker-6player .row.row5>[class*="col-"][data-v-464ac711],
  .poker-6player .row.row5>[class*="col"][data-v-464ac711] {
    padding-left: 5px;
    padding-right: 3px;
}
.dt20 .casino-table-full-box[data-v-464ac711] {
    justify-content: center;
}
.dt20 .dt20dragon[data-v-464ac711],
  .dt20 .dt20tiger[data-v-464ac711] {
    width: calc(43% - 7px);
    margin: 0 5px;
}
.dt20 .dt20tie[data-v-464ac711] {
    width: calc(13% - 4px);
    margin-right: 0;
}
.dt20 .dt20pair[data-v-464ac711] {
    width: 97%;
    margin: 0;
    padding: 0;
    border: none;
}
.dt20 .casino-odds-dwn[data-v-464ac711] {
    line-height: 16px;
}
.dt20 .card-odd-box img[data-v-464ac711] {
    height: 38px;
}
.dt20 .card-odd-box[data-v-464ac711] {
    margin-bottom: 5px;
    margin-right: 6px;
}
.dt20 .casino-odds-box[data-v-464ac711] {
    margin: 3px 0;
    border-radius: 0 !important;
}
.dt20 .dt20cards[data-v-464ac711] {
    padding: 6px 6px 0;
}
.dt1day .dtpair[data-v-464ac711] {
    margin-top: 15px;
}
.dt1day .dtredblack[data-v-464ac711] {
    margin-top: 15px;
}
.dt1day .casino-table-header .casino-nation-detail[data-v-464ac711] {
    border-top: 1px solid #aaa;
}
.dt1day .min-max-dt20 span[data-v-464ac711] {
    font-size: 14px;
    font-weight: 500 !important;
}
.dt1day .min-max-dt20 b[data-v-464ac711] {
    font-weight: 500;
}
.dt1day .dtpair .casino-odds-box[data-v-464ac711] {
    color: #fff;
}
.suspended-box[data-v-464ac711]::before {
    background-size: 18px 18px !important;
}
.dtl20 .casino-nation-detail[data-v-464ac711] {
    padding: 0 4px;
}
.min_max_collapse a.minmax-btn[data-v-464ac711] {
    font-size: 13px;
    color: #000;
}
.dtl20 .casino-table li.nav-item[data-v-464ac711] {
    width: 33%;
}
.dtl20 .casino-table li.nav-item .nav-link[data-v-464ac711] {
    width: 100%;
}
.dragontiger-box[data-v-464ac711] {
    margin-top: 15px;
    background-color: #f1f5f8;
    padding: 10px;
    box-shadow: 0 0 5px #aaa;
    text-align: center;
}
.dtl20 .casino-table-box[data-v-464ac711] {
    padding: 0;
    padding: 0 8px;
    margin-top: 8px;
}
.dtl20 .casino-table-box .casino-table-body[data-v-464ac711] {
    border-top: 1px solid #aaa;
    margin-bottom: 2px;
}
.dtl20 .min_max_collapse .range-collpase[data-v-464ac711] {
    padding: 6px;
    left: inherit;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 5px #999;
    color: #242424;
    width: max-content;
}
.dtl20 .min_max_collapse .range-collpase .min_max_span[data-v-464ac711] {
    width: 100%;
    display: inline-block;
    margin: 0 !important;
}
.cards32b .casino-table-header .casino-odds-box[data-v-464ac711] {
    height: 31px !important;
    border-bottom: 1px solid #aaa;
}
.Baccarat2result .result-item[data-v-464ac711] {
    box-shadow: 0 0 2px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding: 5px 8px;
}
.Baccarat2result .result-item .result-title[data-v-464ac711] {
    font-size: 16px;
    margin-bottom: 3px;
    font-weight: 600;
}
.Baccarat2result .casino-result-cards .casino-result-cardimg img[data-v-464ac711] {
    width: 34px;
    height: auto;
}
.Baccarat2result .casino-result-cards .casino-result-cardimg .img_transform[data-v-464ac711] {
    margin-right: 9px !important;
}
.Baccarat2result .casino-result-modal .casino-result-cards[data-v-464ac711] {
    margin-top: 0;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
    display: flex;
    min-height: 40px;
}
.casino-war .casino-nation-name[data-v-464ac711] {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 3px;
}
.casino-war span.card-icon img[data-v-464ac711] {
    height: 9px;
}
.casino-war .casino-table-header .flip-card[data-v-464ac711] {
    height: 35px !important;
}
.casino-war .casino-table-header .casino-odds-box[data-v-464ac711] {
    text-transform: uppercase;
    height: 41px !important;
}
.casino-war .casino-table[data-v-464ac711] {
    margin-top: 0;
    padding-top: 0;
}
.casino-war .tab-content[data-v-464ac711] {
    padding: 6px;
}
.casino-war .casino-table-header[data-v-464ac711],
  .casino-table-row[data-v-464ac711] {
    border-bottom: 1px solid #aaa;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
}
.casino-war .min_max_collapse .range-collpase .min_max_span[data-v-464ac711] {
    display: flex;
    margin: 0 !important;
}
.min_max_collapse .range-collpase[data-v-464ac711] {
    padding: 6px;
}
.queen .casino-odds-box[data-v-464ac711] {
    height: 46px;
}
.queen .casino-odd-box-container[data-v-464ac711] {
    width: calc(50% - 3px);
}
.queen .casino-odd-box-container[data-v-464ac711] {
    margin-right: 4px;
    margin-bottom: 5px;
}
.queen .casino_odd_row[data-v-464ac711] {
    justify-content: space-between;
    padding: 0;
}
.queen .casino-odds-box[data-v-464ac711] {
    border-width: 2px !important;
}
.queen .casino-odds[data-v-464ac711] {
    margin-bottom: 5px;
}
.ab2 .ab2-box .casino-odds[data-v-464ac711] {
    height: 30px;
}
.ab2 .ab2-box .casino-odds-box .casino-odds[data-v-464ac711] {
    height: 30px;
}
.ab2 .card-odd-box[data-v-464ac711] {
    margin-right: 4px;
}
.ab2 .player-bet-box[data-v-464ac711] {
    height: 60px;
}
.ab2 .player-sa-box[data-v-464ac711] {
    height: 60px;
}
.ab2 .casino-odds[data-v-464ac711] {
    line-height: 26px;
}
.ab2 .casino-table-full-box .playera-bets .playera-title[data-v-464ac711] {
    font-size: 18px;
}
.ab2 .casino-table-right-box[data-v-464ac711] {
    margin-top: 10px;
}
.ab2 .casino-table-box[data-v-464ac711] {
    margin-top: 10px !important;
}
.ab2 .ab2cards[data-v-464ac711] {
    margin-top: 10px !important;
}
.ab2 .ab-cards-container[data-v-464ac711] {
    width: 210px;
}
.ab.ab3 .andar-boxmob[data-v-464ac711] {
    border: 1px solid #000;
    width: 100%;
    display: block;
    background-color: #ffa07a;
}
.ab.ab3 .andar-boxmob .andar_boxmob_title[data-v-464ac711] {
    border-bottom: 1px solid #000;
    padding: 5px;
    font-weight: 600;
    text-align: center;
}
.andar-boxmob-slider[data-v-464ac711] {
    padding: 5px;
}
.ab.ab3 .card-odd-box img[data-v-464ac711] {
    height: 68px;
}
.ab.ab3 .andar-boxmob-slider[data-v-464ac711] {
    padding: 5px 20px;
}
.ab.ab3 .card-odd-box[data-v-464ac711] {
    text-align: center;
}
.ab.ab3 .slick-prev[data-v-464ac711] {
    /*   background: url(../images/left-arrow-black.jpg) no-repeat; */
    position: absolute;
    top: 40%;
    left: 2px;
    font-size: 0 !important;
    height: 13px;
    width: 13px;
    background-size: 13px;
    border: none;
    transform: translateY(-50%);
}
.ab.ab3 .slick-next[data-v-464ac711] {
    /*  background: url(../images/right_arrow_black.jpg) no-repeat; */
    position: absolute;
    top: 40%;
    right: 12px;
    font-size: 0 !important;
    height: 13px;
    width: 13px;
    background-size: 13px;
    border: none;
    transform: translateY(-50%);
}
.ab.ab3 .casino-result-cards .slick-prev[data-v-464ac711] {
    /*  background: url(../images/left-arrow-whi.jpg) no-repeat; */
    position: absolute;
    top: 50%;
    left: -12px;
    font-size: 0 !important;
    height: 10px;
    width: 10px;
    background-size: 10px;
    border: none;
    transform: translateY(-50%);
}
.ab.ab3 .casino-result-cards .slick-next[data-v-464ac711] {
    /*  background: url(../images/right_arrow_white.jpg) no-repeat; */
    position: absolute;
    top: 50%;
    right: -8px;
    font-size: 0 !important;
    height: 10px;
    width: 10px;
    background-size: 10px;
    border: none;
    transform: translateY(-50%);
}
.ab.ab3 .lightgreen_box[data-v-464ac711] {
    border: 1px solid #000;
    width: 100%;
    display: block;
    background-color: #90ee90;
    margin-top: 8px;
}
.ab.ab3 .lightgreen_box .andar_boxmob_title[data-v-464ac711] {
    border-bottom: 1px solid #000;
    padding: 5px;
    font-weight: 600;
    text-align: center;
}
.ab.ab3 .ab-cards-container img[data-v-464ac711] {
    height: 30px !important;
    width: auto !important;
}
.ab.ab3 .ab-cards-container[data-v-464ac711] {
    width: 140px;
}
.aaa .casino-table-left-box[data-v-464ac711],
  .aaa .casino-table-center-box[data-v-464ac711],
  .aaa .casino-table-right-box[data-v-464ac711] {
    width: 32%;
}
.aaa .casino-odds[data-v-464ac711] {
    font-size: 14px;
}
.aaa .casino-table-box-margin[data-v-464ac711] {
    margin: 10px 5px 0;
}
.aaa .casino-tavle-box-background[data-v-464ac711] {
    background: #fff;
}
.aaa .min_max_collapse[data-v-464ac711] {
    padding-right: 0;
}
.aaa .card-odd-box img[data-v-464ac711] {
    height: 42px;
}
.aaa .mobile-akbar .casino-nation-detail[data-v-464ac711] {
    width: 60%;
}
.aaa .mobile-akbar[data-v-464ac711] {
    margin-top: 0;
}
.threecrdju .andar-boxmob[data-v-464ac711] {
    background: #72bbef !important;
}
.threecrdju .lightgreen_box[data-v-464ac711] {
    background: #faa9ba !important;
}
.threecrdju .andar-boxmob .andar_boxmob_title[data-v-464ac711] {
    display: flex;
    justify-content: space-between;
}
.threecrdju .lightgreen_box .andar_boxmob_title[data-v-464ac711] {
    display: flex;
    justify-content: space-between;
}
.baccarat .player-box img[data-v-464ac711],
  .banker-box img[data-v-464ac711] {
    height: 15px;
    margin-right: 5px;
    margin-top: 5px;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
    display: flex;
    background-color: #2c3e50 !important;
    color: white;
}
.baccarat .baccarat-graph summary[data-v-464ac711] {
    background: #092844d9;
    color: #fff;
    padding: 5px;
    text-align: start;
}
.race20 .casino-table-box[data-v-464ac711] {
    padding: 0;
}
.race20 .casino-nation-name img[data-v-464ac711] {
    height: 35px;
}
.race20 .casino-odd-box-container[data-v-464ac711] {
    padding-top: 5px;
}
.race20 .casino-nation-name[data-v-464ac711] {
    font-size: 14px;
}
.race20 .right_box_padding[data-v-464ac711] {
    margin-top: 8px;
}
.race20 .casino-table-box_bg[data-v-464ac711] {
    padding-bottom: 10px !important;
    padding-left: 10px;
    padding-right: 10px;
}
.race20 .casino-table-left-box[data-v-464ac711] {
    padding: 5px 5px 10px;
}
.race20 .casino-nation-book[data-v-464ac711] {
    font-weight: 700;
}
.race20 .casinobox-row[data-v-464ac711] {
    padding: 1px;
}
.race20 .casino-odds[data-v-464ac711] {
    font-size: 16px;
    line-height: 17px;
}
.race20 .casino-odds-box[data-v-464ac711] {
    border: 2px solid #72bbef;
    height: 48px;
}
.race20 .min_max_collapse .range-collpase[data-v-464ac711] {
    left: -67%;
    transform: translateX(-50%);
    font-weight: 500;
    background: rgb(51 51 51/84%) none repeat scroll 0% 0%;
}
.bollywood .casino-nation-name[data-v-464ac711] {
    font-size: 12px;
}
.bollywood .casino-nation-book[data-v-464ac711] {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 700;
}
.bollywood .casino-table .min_max_odds[data-v-464ac711] {
    padding: 5px 0;
}
.bollywood .casino-nation-detail[data-v-464ac711] {
    padding-left: 3px;
}
.bollywood .casino-table-left-box[data-v-464ac711],
  .bollywood .casino-table-right-box[data-v-464ac711] {
    margin-bottom: 10px;
}
.bollywood .card-odd-box img[data-v-464ac711] {
    height: 40px;
}
.bollywood .casino-table-header_border[data-v-464ac711] {
    border-top: 1px solid #aaa;
}
.bollywood .casino-table-right-box h4[data-v-464ac711] {
    position: inherit;
    right: inherit;
    text-align: center !important;
    margin-bottom: 9px;
}
.bollywood_minmaxcards[data-v-464ac711] {
    width: auto !important;
}
.bollywood .casino-odds[data-v-464ac711] {
    font-size: 15px;
    font-weight: 400;
}
.super-over .game-market[data-v-464ac711] {
    margin-bottom: 5px;
}
.super-over .game-market a.rules_btn[data-v-464ac711] {
    font-size: 14px;
    margin-right: 0;
}
.casino_list_head[data-v-464ac711] {
    display: flex !important;
    width: 100%;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}
.cricket2020resulticon[data-v-464ac711] {
    width: 30px !important;
    margin: 2px !important;
}
.nav-tabs .nav-item[data-v-464ac711] {
    padding: 13px 0;
    flex: auto;
}
.matchbettable #matched .back[data-v-464ac711] {
    background-color: #a7d8fd !important;
}
.matchbettable #matched .lay[data-v-464ac711] {
    background-color: #f9c9d4 !important;
}
.back[data-v-464ac711] {
    background-color: #a7d8fd !important;
}
.lay[data-v-464ac711] {
    background-color: #f9c9d4 !important;
}
.home-page .back[data-v-464ac711] {
    background-color: #72bbef !important;
}
.home-page .lay[data-v-464ac711] {
    background-color: #faa9ba !important;
}
.login-form[data-v-464ac711] {
    border-radius: 8px;
    box-shadow: none;
    width: 300px;
    padding: 26px 16px 16px;
}
.login-form .login-title[data-v-464ac711] {
    display: none;
}
.login-form form span.icon-from[data-v-464ac711] {
    display: none;
}
.login-form form .input-group>.form-control[data-v-464ac711],
  .login-form form .input-group>.form-select[data-v-464ac711] {
    width: 100%;
    border-radius: 4px !important;
    border: none;
    background: 0 0;
    border-radius: 0 !important;
    height: 38px;
    padding-left: 15px;
    border-bottom: 1px solid var(--bg-secondary85) !important;
    box-shadow: none;
    outline: none;
}
.login-form .input-group[data-v-464ac711] {
    margin-bottom: 24px;
}
.login-form .btn-primary[data-v-464ac711] {
    border-radius: 0;
}
.login-form .btn-primary svg.icon[data-v-464ac711] {
    float: none !important;
    margin-left: 10px;
    top: 0;
}
.login-form .recaptchaTerms[data-v-464ac711] {
    font-size: 10px !important;
    line-height: 18px;
    margin-top: 2px !important;
}
.login-form .login-btn-loader svg.icon.f-spin[data-v-464ac711] {
    position: inherit;
    display: inline;
    top: 0;
    right: inherit;
}
.bet-nation-name .game-icons .game-icon i[data-v-464ac711] {
    font-size: 12px;
}
.bet-nation-name .game-icons .game-icon.fancy-icon img[data-v-464ac711] {
    height: inherit;
    width: 15px;
    margin-top: -2px;
}
.bet-nation-name .game-icons .game-icon.bookma-icon img[data-v-464ac711] {
    height: auto;
    width: 15px;
    margin-top: -5px;
}
.bet-nation-odd .odd-box .bet-odd[data-v-464ac711] {
    color: #000;
}
.bet-table-body .bet-nation-odd.d-xl-none[data-v-464ac711] {
    font-size: 12px;
    color: #000;
}
.worli-box-title[data-v-464ac711] {
    min-height: 26px;
    border: 1px solid #aaa;
    margin: 0;
    padding-top: 3px;
}
.worli-left .worli-odd-box[data-v-464ac711],
  .worli-full .worli-odd-box[data-v-464ac711] {
    width: calc(20% - 1px);
    margin-right: 1px;
}
.worli-box-row[data-v-464ac711] {
    margin-bottom: 1px;
}
.worli-right .worli-odd-box[data-v-464ac711] {
    width: calc(50% - 1px);
    margin-right: 1px;
}
.worli .worli-right .worli-odd-box[data-v-464ac711] {
    width: calc(25% - 1px);
}
.casino-detail .back[data-v-464ac711] {
    background-color: #a7d8fd !important;
}
.casino-detail .lay[data-v-464ac711] {
    background-color: #f9c9d4 !important;
}
.worli .worli-odd-box[data-v-464ac711] {
    height: 50px;
    border: 1px solid #aaa;
}
.worli .worli-odd-box .worli-odd[data-v-464ac711] {
    font-size: 40px;
}
.worli .worli-left .worli-odd-box.back[data-v-464ac711] {
    margin: 0 !important;
}
.worli .worli-box-row[data-v-464ac711] {
    margin-bottom: 0;
}
.worli .worli-left .worli-odd-box[data-v-464ac711],
  .worli-full .worli-odd-box[data-v-464ac711] {
    width: calc(20% - 0px);
}
.worli .Worli_odd_box_mobile[data-v-464ac711] {
    height: 40px;
}
.worli .worli-odd-box.back.Worli_odd_box_mobile[data-v-464ac711] {
    margin: 0 !important;
}
.worli .worli-right .worli-odd-box[data-v-464ac711] {
    width: calc(25% - 0px);
}
.worli-left .worli-odd-box[data-v-464ac711],
  .worli-full .worli-odd-box[data-v-464ac711] {
    margin-right: 0;
}
.worli .worli-full[data-v-464ac711] {
    margin-top: 0;
}
.worli .Woril_mobile_bottom_button[data-v-464ac711] {
    background-color: #efefef;
    padding: 5px 10px;
    margin-top: 8px;
}
.worli .Woril_mobile_bottom_button p[data-v-464ac711] {
    color: #ffff;
    padding: 5px 10px;
    background: #dc3545;
    width: 50px;
    text-align: center;
    justify-content: end;
    display: inline-block;
}
.worli .worli-right .worli_spall[data-v-464ac711] {
    width: calc(100% - 0px);
}
.worli .sp .worli-right .worli-odd-box[data-v-464ac711],
  .dp .worli-right .worli-odd-box[data-v-464ac711],
  .chart56 .worli-right .worli-odd-box[data-v-464ac711],
  .chart64 .worli-right .worli-odd-box[data-v-464ac711],
  .colordp .worli-right .worli-odd-box[data-v-464ac711] {
    height: 100px;
    font-size: 14px;
    font-weight: 700;
}
.worli .worlibox[data-v-464ac711] {
    margin-top: 0;
}
.worli .worli_trio[data-v-464ac711] {
    border-radius: 0;
    background: #2b329b;
    padding: 10px 20px;
}
.worli .worli_plase_bet[data-v-464ac711] {
    width: 80px !important;
    background: #007bff !important;
}
.worli .worli-left .worli_abr[data-v-464ac711] {
    width: calc(25% - 0px);
}
.worli .worli_abr[data-v-464ac711] {
    width: 25%;
}
.worli .worli_colordp_all_left[data-v-464ac711] {
    width: 62%;
}
.worli .worli_colordp_all_right[data-v-464ac711] {
    width: 38%;
}
.worli .nav-tabs[data-v-464ac711] {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    flex-wrap: inherit;
}
.bet-nation-name .game-icons .game-icon img.icon_game_s[data-v-464ac711] {
    height: 14px;
}
.DTLresult .result-item[data-v-464ac711] {
    margin-top: 10px;
    box-shadow: 0 0 2px;
    padding: 3px 10px;
}
.Open_teenpatti_result .result-item[data-v-464ac711] {
    box-shadow: 0 0 2px;
    margin-bottom: 5px;
    padding: 6px 9px;
}
.Open_teenpatti_result .result-item .result-title[data-v-464ac711] {
    margin-bottom: 0;
}
.poker-20 .casino-video-cards img[data-v-464ac711] {
    width: 26px;
}
.one_day_pokerresult .result-item[data-v-464ac711] {
    box-shadow: 0 0 2px;
    margin-bottom: 5px;
    padding: 4px 10px;
}
.one_day_pokerresult hr[data-v-464ac711] {
    display: none;
}
.min-max-dt20 span[data-v-464ac711] {
    margin-left: 3px;
    font-family: roboto condensed, sans-serif !important;
    color: #000;
    font-weight: 500;
}
.min-max-dt20 b[data-v-464ac711] {
    font-weight: 500;
}
.min-max-dt20 span span[data-v-464ac711] {
    margin: 0;
}
.dt20 .casino-odds-dwn[data-v-464ac711] {
    line-height: 16px;
    color: #000;
    font-weight: 700;
}
.dt20 .casino-odds[data-v-464ac711] {
    line-height: 19px;
}
.dt20 .dt20odds[data-v-464ac711] {
    width: calc(50% - 7px);
}
.dt20 .dt20pair[data-v-464ac711] {
    width: 98%;
}
.dt20 .casino-table-full-box[data-v-464ac711],
  .dt20 .casino-table-left-box[data-v-464ac711],
  .dt20 .casino-table-right-box[data-v-464ac711] {
    justify-content: center;
    padding: 6px 10px;
}
.dt20 .min-max-dt20[data-v-464ac711] {
    padding-right: 4px;
}
.dt20 .dt20cards[data-v-464ac711] {
    padding: 0;
}
.suspended-box[data-v-464ac711]::before {
    background-size: 13px 13px;
}
.baccarat .baccarat-graph summary[data-v-464ac711]::marker {
    display: none;
}
app-pie-chart[data-v-464ac711] {
    width: 100%;
}
.modal-header[data-v-464ac711] {
    min-height: 41px;
}
}
@media only screen and (max-width: 576px) {
.clock .flip-card[data-v-464ac711] {
    height: 18px !important;
}
.clock .flip[data-v-464ac711] {
    position: relative;
    height: 36px;
    width: 30px;
}
.clock .content[data-v-464ac711] {
    height: 36px;
}
.clock .flip-card-front[data-v-464ac711],
  .clock .up[data-v-464ac711] {
    width: 24px;
    height: 18px;
}
.clock[data-v-464ac711] {
    right: 0 !important;
    bottom: -12px !important;
    overflow: hidden;
    width: 66px !important;
    height: 68px !important;
}
.clock .flip-card-back[data-v-464ac711],
  .clock .down[data-v-464ac711] {
    width: 24px;
    height: 18px;
}
.clock .down>div[data-v-464ac711],
  .clock .up>div[data-v-464ac711] {
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    font-family: Helvetica, sans-serif;
}
.clock .down>div>div[data-v-464ac711] {
    margin-top: -18px;
}
.clock .content[data-v-464ac711] {
    height: 50px;
    margin-left: 20px;
}
.clock .count-no[data-v-464ac711] {
    bottom: -6px;
}
.clock .count-no[data-v-464ac711]:first-child {
    margin-right: 6px !important;
}
.clock .count-no[data-v-464ac711]:last-child {
    margin-right: 32px !important;
}
.clock .content[data-v-464ac711] {
    height: 50px;
    width: 24px !important;
}
.cards32a.cards32b .flip-card[data-v-464ac711],
  .cards32a .flip-card[data-v-464ac711] {
    height: auto;
}
.header-top[data-v-464ac711] {
    padding: 5px 15px;
}
.home-page .bet-table-row[data-v-464ac711] {
    padding: 5px 15px;
}
.casino_list_head[data-v-464ac711] {
    padding: 5px;
}
.casino-list[data-v-464ac711] {
    padding: 0 10px;
}
.super-over .market-4 .market-odd-box[data-v-464ac711] {
    color: #000;
}
.super-over .casino-video-cards img[data-v-464ac711] {
    height: 23px;
}
.casino-detail .market-title[data-v-464ac711] {
    align-items: center;
}
.super-over .scorecard[data-v-464ac711] {
    margin-bottom: 4px;
}
.casino-last-results[data-v-464ac711] {
    margin-top: 0.25rem;
}
.casino-page .center-container[data-v-464ac711] {
    padding-bottom: 0.25rem;
}
.casino-last-results .result[data-v-464ac711] {
    font-weight: 400;
}
.super-over .game-market a.rules_btn[data-v-464ac711] {
    font-size: 15px;
}
.sidebar-box-rules .sidebar-title.super-over-title h4[data-v-464ac711] {
    font-size: 1rem;
    text-align: center;
}
.right-sidebar .sidebar-title[data-v-464ac711] {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.sidebar-box-rules .table th[data-v-464ac711],
  .sidebar-box-rules .table td[data-v-464ac711] {
    font-size: 14px;
    color: #000;
}
.sidebar-box[data-v-464ac711] {
    margin-bottom: 0;
}
.race20 .casino-detail .casino-odds-box.back[data-v-464ac711],
  .race20 .casino-detail .casino-odds-box.lay[data-v-464ac711] {
    background-color: transparent !important;
}
.cricket5-rules_table .text-center[data-v-464ac711] {
    font-size: 16px;
    margin-bottom: 5px;
}
.sidebar-box-rules .sidebar-title h4[data-v-464ac711] {
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
}
.sidebar-box-rules .table th[data-v-464ac711],
  .sidebar-box-rules .table td[data-v-464ac711] {
    border: none;
}
.sidebar-box-rules .table thead[data-v-464ac711] {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.dt20 .casino-odds-box .casino-odds[data-v-464ac711] {
    color: #fff;
    font-weight: 400;
}
.dt20 .casino-odds span.card-icon img[data-v-464ac711] {
    height: 12px;
    width: 12px;
}
.dt20 .casino-odds span.card-icon[data-v-464ac711] {
    margin: 0 7px !important;
}
.baccarat .baccarat-other-odd-box span[data-v-464ac711] {
    display: flex;
    flex-direction: column;
}
.baccarat .baccarat-other-odd-box span span[data-v-464ac711] {
    display: inline-block;
}
.baccarat .baccarat-other-odd-box[data-v-464ac711] {
    padding: 8px 9px;
}
.cricket2020 .team-score[data-v-464ac711] {
    font-size: 14px;
}
.lucky7a .casino-odds[data-v-464ac711] {
    font-size: 12px !important;
    margin-bottom: 3px;
}
.lucky7a .casino-odds-box .casino-odds[data-v-464ac711] {
    font-size: 14px !important;
    color: #fff;
    font-weight: 500;
}
.casino-war .min_max_collapse a.minmax-btn[data-v-464ac711] {
    font-size: 12px;
    color: var(--bg-secondary);
}
.casino-war .casino-table-row[data-v-464ac711]:first-child {
    border-top: 1px solid #aaa;
}
.casino-war .casino-nation-name[data-v-464ac711] {
    padding-left: 0;
}
.casino-war .casino-nation-name img[data-v-464ac711] {
    height: 32px;
    margin-left: -4px;
}
.casino-war span.card-icon img[data-v-464ac711] {
    height: 10px;
    margin-left: 1px;
}
.dtl20 .min_max_collapse a.minmax-btn[data-v-464ac711] {
    font-size: 12px;
    color: var(--bg-secondary);
}
.casino-video-cards h5[data-v-464ac711] {
    font-size: 10px;
    font-weight: 400;
}
.teenpatti20 .casino-table-header .casino_odds_row .casino-odds-box.back[data-v-464ac711] {
    font-size: 14px !important;
}
.poker1rules table.table[data-v-464ac711] {
    background-color: #f1f5f8;
}
.poker1rules table.table td[data-v-464ac711] {
    font-size: 12px !important;
}
.poker-20 .casino-table-header .casino-odds-box[data-v-464ac711] {
    border-color: #aaa;
}
.dt1day .dtpair .casino-odds-box span.casino-odds[data-v-464ac711] {
    color: #fff;
    font-weight: 500;
}
.dt1day .casino-table-header .casino-odds-box[data-v-464ac711] {
    border-top: 1px solid #aaa;
}
.bollywood .aaa-odd-box .casino-odds-box[data-v-464ac711] {
    padding: 6px 10px !important;
}
.bollywood .aaa-odd-box .casino-odds-box .casino-odds[data-v-464ac711] {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.bollywood .casino-nation-name[data-v-464ac711] {
    padding-left: 0;
}
.suspended-box[data-v-464ac711]::before {
    background-size: 13px 13px !important;
}
.bollywood .casino_odds_row .casino-odds-box[data-v-464ac711]:first-child {
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;
}
.bollywood .casino-table-right-box[data-v-464ac711] {
    background-color: #f1f5f8;
}
.bollywood .min_max_collapse a.minmax-btn[data-v-464ac711] {
    color: var(--bg-secondary);
}
.lucky7a .casino-video-cards img[data-v-464ac711] {
    height: auto;
    width: 23px;
}
.lucky7a .casino-table-full-box .lucky7 img[data-v-464ac711] {
    width: 56px;
}
.lucky7a .casino-table-left-box[data-v-464ac711],
  .lucky7a .casino-table-right-box[data-v-464ac711] {
    padding-right: 0;
}
.lucky7a .casino-table-left-box .min_max_odds[data-v-464ac711],
  .lucky7a .casino-table-right-box .min_max_odds[data-v-464ac711] {
    padding-right: 10px;
}
.report-page .chnagepassword .form-control[data-v-464ac711],
  .report-page .chnagepassword .form-select[data-v-464ac711] {
    height: 38px;
    font-size: 14px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--bg-secondary);
}
.user-menu-dropdown .dropdown-menu[data-v-464ac711] {
    right: -14px !important;
}
.fancy-monile-ddesign .market-nation-detail .market-nation-name span[data-v-464ac711] {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.daimo_Payment .nav-pills .nav-link[data-v-464ac711] {
    margin-bottom: 7px;
}
.payment_type_tabs li[data-v-464ac711] {
    width: 49%;
    display: inline-flex;
}
.daimo_Payment .nav-pills .nav-link[data-v-464ac711] {
    margin: 0;
    margin-bottom: 7px;
    width: 100%;
}
.daimo_Payment[data-v-464ac711] {
    padding: 0;
}
.payment_type_tabs[data-v-464ac711] {
    margin-top: 4px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.fancy-monile-ddesign .fancy-market[data-v-464ac711] {
    border-bottom: 1px solid #aaa;
}
.fancy-monile-ddesign .market-remark[data-v-464ac711] {
    width: 100%;
    text-align: right;
    border-top: 1px solid #aaa;
}
.news[data-v-464ac711] {
    bottom: 11px;
    display: block;
}
}